import ClearIcon from '@mui/icons-material/Clear';
import { Alert, AlertColor, IconButton, SxProps, Theme } from '@mui/material';

interface Props {
  sx?: SxProps<Theme>;
  message?: {
    severity: AlertColor;
    msg: string;
  };
  onClose?: () => void;
}

const Message = (props: Props) => {
  if (props.message == null) return <></>;

  return (
    <Alert
      severity={props.message.severity}
      sx={{ mb: 1, alignItems: 'center', ...props.sx }}
      data-automation={props.message.msg.toLowerCase().replaceAll(' ', '-')}
      action={
        <>
          {props.onClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                if (props.onClose) props.onClose();
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      }
    >
      {props.message.msg}
    </Alert>
  );
};

export default Message;
