import { FilterParamType } from '../../components/filter/FilterPanel';
import { UserAccess } from '../constants/access';
import { MatchType } from './exception-rule.dto';
import { ElementDurationTypeEnum } from './template.dto';

export enum OperatorV2 {
  isEmpty = 'isempty',
  isNotEmpty = 'isnotempty',
  equals = 'eq',
  notEquals = 'neq',

  // STRING
  strContains = 'contains',
  strNotContains = 'notcontain',
  strStartsWith = 'startswith',
  strEndsWith = 'endswith',
  strLengthMin = 'lengthMin',
  strLengthMax = 'lengthMax',
  strIsEmail = 'strIsEmail',

  // NUMERIC MATCHES
  numberGreaterThan = 'gt',
  numberGreaterThanOrEqualTo = 'gte',
  numberLessThan = 'lt',
  numberLessThanOrEqualTo = 'lte',
  numberMin = 'min',
  numberMax = 'max',
  numberDivisiblyBy = 'numberDivisiblyBy',

  // Options
  optionsContains = 'optionsIn',
  optionsNotContains = 'optionsNotIn',
  optionsEquals = 'optionsEquals',
  optionsNotEquals = 'optionsNotEquals',
  optionsStartsWith = 'optionsStartsWith',
  optionsEndsWith = 'optionsEndsWith',
  optionsLenMin = 'optionsLenMin',
  optionsLenMax = 'optionsLenMax',

  // Before x days
  datetimeIsCustomDaysBefore = 'datetimeIsCustomDaysBefore',
  datetimeIsCustomDaysBeforeOrEqual = 'datetimeIsCustomDaysBeforeOrEqual',
  datetimeIsCustomDaysAfter = 'datetimeIsCustomDaysAfter',
  datetimeIsCustomDaysAfterOrEqual = 'datetimeIsCustomDaysAfterOrEqual',

  // Before x hours
  // datetimeIsCustomHoursBefore = 'datetimeIsCustomHoursBefore',
  // datetimeIsCustomHoursBeforeOrEqual = 'datetimeIsCustomHoursBeforeOrEqual',
  // datetimeIsCustomHoursAfter = 'datetimeIsCustomHoursAfter',
  // datetimeIsCustomHoursAfterOrEqual = 'datetimeIsCustomHoursAfterOrEqual',

  // Before x mins
  // datetimeIsCustomMinsBefore = 'datetimeIsCustomMinsBefore',
  // datetimeIsCustomMinsBeforeOrEqual = 'datetimeIsCustomMinsBeforeOrEqual',
  // datetimeIsCustomMinsAfter = 'datetimeIsCustomMinsAfter',
  // datetimeIsCustomMinsAfterOrEqual = 'datetimeIsCustomMinsAfterOrEqual',

  // Date time
  dateTimeEquals = 'dateTimeEquals',
  dateTimeBefore = 'dateTimeBefore',
  dateTimeBeforeOrEqual = 'dateTimeBeforeOrEqual',
  dateTimeAfter = 'dateTimeAfter',
  dateTimeAfterOrEqual = 'dateTimeAfterOrEqual',

  // Date
  dateEquals = 'dateEquals',
  dateBefore = 'dateBefore',
  dateBeforeOrEqual = 'dateBeforeOrEqual',
  dateAfter = 'dateAfter',
  dateAfterOrEqual = 'dateAfterOrEqual',

  // time
  timeEquals = 'timeEquals',
  timeBefore = 'timeBefore',
  timeBeforeOrEqual = 'timeBeforeOrEqual',
  timeAfter = 'timeAfter',
  timeAfterOrEqual = 'timeAfterOrEqual',

  // issues
  issuesYesNo = 'issuesYesNo',
  issuesPassFail = 'issuesPassFail',

  optionsContainAndChildren = 'optionsInAndChildren',
}

enum FilterFieldType {
  STRING = 'string',
  NUMERIC = 'numeric',
  OPTION = 'options',
  OPTION_MULTI_SELECT = 'option_multi',
  ISSUE = 'issue',
  DATE = 'date',
  DATERANGE = 'daterange',
}

interface FilterFieldDateValue {
  from: string;
  to: string;
}

export type FilterFieldValueTypes = string | string[] | number | FilterFieldDateValue;
export enum FilterFieldDataType {
  string = 'string',
  number = 'number',
  options = 'options',
  issues = 'issues',
  datetime = 'datetime',
  date = 'date',
  time = 'time',
  hours = 'hours',
  checkbox = 'checkbox',
  dateRange = 'dateRange',
  timeRange = 'timeRange',
  customPicker = 'customPicker',
}
export interface FilterFieldDto {
  field: string;
  value?: FilterFieldValueTypes;
  operator?: OperatorV2;
  dataType: FilterFieldDataType;
  durationType?: ElementDurationTypeEnum;
}

// TODO replace with FilterFieldDto above
export interface FilterField {
  field: string;
  // TODO checkUpdate
  // value: FilterValue;
  value: FilterFieldValueTypes;
  from?: string;
  to?: string;
  constrain: OperatorV2;
  constrainTitle?: string;
  type: FilterFieldType;
}

export interface ConstrainV2 {
  id: OperatorV2;
  title: string;
  applicableDataTypes: FilterFieldDataType[];
}

export enum FilterSortDirection {
  asc = 'asc',
  desc = 'desc',
}

interface FilterSort {
  field: string;
  direction: FilterSortDirection;
}

export interface FilterPagination {
  page?: number;
  limit?: number;
  disablePagination: boolean;
}

export interface FilterDto {
  fields: FilterFieldDto[];
  userHavingAccess?: UserAccess;
  userHavingAccessLocationId?: string;
  pagination: FilterPagination;
  sort: FilterSort;
  showDeleted?: boolean;
  matchType: MatchType;
}

export interface ScheduleStatusFilterDto {
  from: string;
  to: string;
}

export interface FilterFields {
  field: string;
  data: FilterParamType;
}

export interface FilterViewDto {
  id?: string;
  name: string;
  type: FilterTypes;
  matchType: MatchType;
  filterFields: FilterFields[];
  templateId?: string;
}

export enum FilterTypes {
  User = 'user',
  Asset = 'asset',
  Authority = 'authority',
  Location = 'location',
  Template = 'template',
  Report = 'report',
  ExceptionRules = 'exception-rule',
  ExceptionReports = 'exception-report',
}
