import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Alert, Box, ButtonBase, Divider, IconButton, Popper, Stack, styled, Typography, useTheme } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment';
import { SetStateAction, useRef, useState } from 'react';

import { SimpleViewDto } from '../common/dto/common.dto';
import { ConstrainV2, FilterFieldDataType } from '../common/dto/filter.dto';
import { FlexRow } from '../components/styled/Flex';
import { StyledLink } from '../components/styled/Link';
import useIsLowDensity from '../hook/useIsLowDensity';

export type FilterDateRangeValue = {
  from: Date;
  to: Date;
};

export type FilterTimeRangeValue = {
  from: string;
  to: string;
};

// TODO should be undefined by ?, remove null
export type FilterValueTypes = SimpleViewDto | SimpleViewDto[] | string | number | FilterDateRangeValue | FilterTimeRangeValue | boolean | null;

export type WithFilterAddedProps = {
  handleClose: () => void;
  showValueSection: boolean;
  setShowValueSection: React.Dispatch<SetStateAction<boolean>>;
  setFilterValue: (val?: FilterValueTypes, constrain?: ConstrainV2) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
  currentConstrain?: ConstrainV2 | null;
  currentValue?: FilterValueTypes;
};

type WithFilterInputProps = {
  fieldDataType: FilterFieldDataType;
  field: string;
  label: string;
  constrain?: ConstrainV2;
  value?: FilterValueTypes;
  onFilterValueChanged: (
    fieldDataType: FilterFieldDataType,
    field: string,
    value?: FilterValueTypes,
    constrain?: ConstrainV2,
    fieldSuffixMeta?: Record<string, string>,
  ) => void;
  fieldSuffixMeta?: Record<string, string>;
};

const WithFilter = <T extends object, FT extends Omit<T & WithFilterInputProps, keyof WithFilterAddedProps>>(
  WrappedComponent: React.ComponentType<T>,
): React.ComponentType<FT> => {
  const ComponentWithFilter = ({ fieldDataType, field, label, constrain, value, fieldSuffixMeta, onFilterValueChanged, ...hocProps }: FT) => {
    const ldpi = useIsLowDensity();
    // state to keep track of selected constrain and value
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState<string | null>();
    const [selectedConstrain, setSelectedConstrain] = useState<ConstrainV2>();
    const [selectedValues, setSelectedValues] = useState<FilterValueTypes>();

    const [showValueSection, setShowValueSection] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const boxRef = useRef();
    const theme = useTheme();

    const clear = () => {
      onFilterValueChanged(fieldDataType, field, undefined, undefined);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      // setAnchorEl(event.currentTarget);
      if (boxRef.current) setAnchorEl(boxRef.current);
    };

    const handleClose = () => {
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);
      setShowError(false);
      setError(null);
      setShowValueSection(true);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-label' : undefined;

    const setFilterValue = (val?: FilterValueTypes, con?: ConstrainV2) => {
      setSelectedConstrain(con);
      setSelectedValues(val);
    };

    const submitFilterValue = () => {
      if (error) setShowError(true);
      else {
        onFilterValueChanged(fieldDataType, field, selectedValues, selectedConstrain, fieldSuffixMeta);
        handleClose();
      }
    };

    // console.log('WithFilter', hocProps, value, Array.isArray(value), typeof value);

    let valueText: string | undefined;
    if (value != null) {
      if (typeof value === 'string' || typeof value === 'number') {
        valueText = value.toString();
      } else if (Array.isArray(value)) {
        if (value.length === 1) valueText = `${value.length} value`;
        else valueText = `${value.length} values`;
      } else if (typeof value === 'object') {
        if ('from' in value && 'to' in value) {
          // is date range filter value
          if (fieldDataType === FilterFieldDataType.time) {
            valueText = `Between ${moment(value.from).format('hh:mm a')} to ${moment(value.to).format('hh:mm a')}`;
          } else {
            valueText = `Between ${moment(value.from).format('DD/MM/YY')} to ${moment(value.to).format('DD/MM/YY')}`;
          }
        } else if ('id' in value && 'name' in value) {
          // simple view dto and iits a single select
          valueText = value.name;
        } else {
          // throw new Error('Unable to determine valueText');
        }
      } else if (typeof value === 'boolean') {
        valueText = value ? 'Yes' : 'No';
      }
    }

    return (
      <>
        <StyledContainer ref={boxRef} highlight={valueText != null}>
          <FlexRow alignItems="stretch">
            <StyledFilterButton sx={{ flexGrow: 1 }} disableRipple aria-describedby={id} onClick={handleClick} ldpi={ldpi} data-automation={field}>
              <Stack direction="column" sx={{ width: '100%' }}>
                <Typography variant="body2" component="div">
                  {label}{' '}
                  {constrain && (
                    <Box display="inline" fontWeight="bold">
                      {constrain.title}
                      {valueText && ':'}
                    </Box>
                  )}
                </Typography>
                {valueText && <Typography variant="body2">{valueText}</Typography>}
              </Stack>
            </StyledFilterButton>
            {constrain || valueText ? (
              <Box>
                <IconButton onClick={clear} size="small" sx={{ '&:hover': { color: theme.palette.error.main } }}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ) : null}
          </FlexRow>
        </StyledContainer>
        <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
          <ClickAwayListener onClickAway={handleClose}>
            <Box>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                  padding: '8px 10px',
                  fontWeight: 600,
                }}
              >
                {label}
              </Box>

              <WrappedComponent
                {...(hocProps as T)}
                handleClose={handleClose}
                showValueSection={showValueSection}
                setShowValueSection={setShowValueSection}
                setFilterValue={setFilterValue}
                setError={setError}
                setShowError={setShowError}
                currentConstrain={constrain}
                currentValue={value}
              />

              {showValueSection && (
                <Box>
                  {showError && error ? (
                    <>
                      <Divider />
                      <Alert severity="error" icon={false} sx={{ m: 1, p: 0, borderRadius: 0, fontSize: '12px', justifyContent: 'center' }}>
                        {error}
                      </Alert>
                    </>
                  ) : null}
                  <Divider />
                  <FlexRow alignItems="center" justifyContent="center" gap={1}>
                    <StyledLink
                      sx={{ textAlign: 'left', px: 2, py: 2.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        submitFilterValue();
                      }}
                      data-automation="apply-filter-btn"
                    >
                      Apply
                    </StyledLink>
                    <StyledLink
                      sx={{ textAlign: 'left', px: 2, py: 2.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                      }}
                      data-automation="close-filter-btn"
                    >
                      Close
                    </StyledLink>
                  </FlexRow>
                </Box>
              )}
            </Box>
          </ClickAwayListener>
        </StyledPopper>
      </>
    );
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithFilter.displayName = `WithFilter(${displayName})`;

  return ComponentWithFilter;
};

export default WithFilter;

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  borderRadius: 8,
  minWidth: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledFilterButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'ldpi' })<{ ldpi: boolean }>(({ theme, ldpi }) => ({
  minHeight: ldpi ? 30 : 35,
  width: '100%',
  textAlign: 'left',
  // paddingBottom: 8,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(ldpi ? 2.5 : 1.5),
  paddingRight: theme.spacing(ldpi ? 2.5 : 1.5),
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

const StyledContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'highlight' })<{ highlight: boolean }>(({ theme, highlight }) => ({
  // width: 221,
  border: `solid 2px ${highlight ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: '10px',
  // padding: theme.spacing(1),
  '&:hover,&:focus': {
    color: theme.palette.primary,
    border: `solid 2px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.grey[100],
  },
}));
