import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Box, IconButton, Popover, Slider } from '@mui/material';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { selectThemeCustomization, updateThemeFontSize } from '../redux/slices/themeCustomization.slice';

const ThemeFontSizeControl = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={(e) => handleClick(e)}>
        <TextFieldsIcon fontSize="medium" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Controls />
      </Popover>
    </>
  );
};

export default ThemeFontSizeControl;

const marks = [
  {
    value: 1,
    label: 'Small',
  },
  {
    value: 2,
    label: 'Default',
  },
  {
    value: 3,
    label: 'Large',
  },
];

function valuetext(value: number) {
  return `${value}°C`;
}

const Controls = () => {
  const themeData = useAppSelector(selectThemeCustomization);
  const dispatch = useAppDispatch();
  const fontSizeChange = (val: number) => {
    if (val === 1) dispatch(updateThemeFontSize('small'));
    else if (val === 2) dispatch(updateThemeFontSize('medium'));
    else if (val === 3) dispatch(updateThemeFontSize('large'));
  };

  let defaultValue = 2;
  if (themeData.fontSize === 'small') defaultValue = 1;
  else if (themeData.fontSize === 'medium') defaultValue = 2;
  else if (themeData.fontSize === 'large') defaultValue = 3;

  return (
    <Box
      sx={{
        width: 350,
        py: 2,
        px: 5,
      }}
    >
      <Slider
        aria-label="Custom marks"
        value={defaultValue}
        getAriaValueText={valuetext}
        step={1}
        min={1}
        max={3}
        track={false}
        valueLabelDisplay="off"
        marks={marks}
        onChange={(e, num) => fontSizeChange(num as number)}
      />
    </Box>
  );
};
