import { SetStateAction, useEffect, useState } from 'react';

import NumberValueProvider from '../value-providers/NumberValueProvider';

type NumberFieldProps = {
  value?: number;
  onValueChange: (val: number) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
};
const NumberField = (props: NumberFieldProps) => {
  const [cValue, setCValue] = useState<number | undefined>(props.value);
  const { setError, setShowError } = props;

  useEffect(() => {
    if (cValue == null) {
      setError('Value cannot be empty');
    } else {
      setShowError(false);
      setError(null);
      props.onValueChange(cValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  return <NumberValueProvider value={cValue} onValueChange={(val) => setCValue(val)} />;
};

export default NumberField;
