/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { AssetViewDto } from '../common/dto/asset.dto';
import { AuthorityViewDto } from '../common/dto/authority.dto';
import { BaseViewDto } from '../common/dto/base.dto';
import { DataTablePaginationSortChange, PaginatedResponse } from '../common/dto/common.dto';
import { ExceptionViewDto } from '../common/dto/exception-reports.dto';
import { ExceptionRuleViewDto, MatchType } from '../common/dto/exception-rule.dto';
import { FilterDto, FilterFieldDataType, FilterFieldDto, FilterSortDirection, OperatorV2 } from '../common/dto/filter.dto';
import { FormDataViewDto } from '../common/dto/formdata.dto';
import { LocationViewDto } from '../common/dto/location.dto';
import { ScheduleViewDto } from '../common/dto/schedule.dto';
import { ElementDurationTypeEnum, TemplateViewDto } from '../common/dto/template.dto';
import { UserViewDto } from '../common/dto/user.dto';
import { AlertMessageType } from '../common/types';
import { useSearchAssetMutation } from '../services/asset.service';
import { useSearchAuthorityMutation } from '../services/authority.service';
import { useSearchExceptionReportsMutation } from '../services/exception-report.service';
import { useSearchExceptionRuleMutation } from '../services/exception-rules.service';
import { useSearchLocationMutation } from '../services/location.service';
import { useSearchReportsMutation } from '../services/report.service';
import { useGetSchedulesListMutation } from '../services/schedules-rules.service';
import { useSearchTemplatesMutation } from '../services/template.service';
import { useSearchUserMutation } from '../services/user.service';

type DataTablePaginationWithDisabledFlag = DataTablePaginationSortChange & {
  disablePagination?: boolean;
};
export interface SearchFilterProps {
  filterFields: FilterFieldDto[];
  pagination: DataTablePaginationWithDisabledFlag;
  matchType: MatchType;
}

const useSearch = <T extends BaseViewDto>(
  disablePagination = false,
  filter: SearchFilterProps = {
    filterFields: [],
    pagination: {
      disablePagination,
      page: 1,
      limit: 10,
      sortBy: 'createdAt',
      sortOrder: FilterSortDirection.desc,
    },
    matchType: MatchType.ALL,
  },
) => {
  const [searchFilter, setSearchFilter] = useState<SearchFilterProps>(filter);
  const [data, setData] = useState<PaginatedResponse<T>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<AlertMessageType | null>();

  return {
    isLoading,
    setIsLoading,
    data,
    setData,
    searchFilter,
    setSearchFilter,
    error,
    setError,
  };
};

export const useSearchAuthority = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<AuthorityViewDto>(false, preFilter);
  const [searchAuthority] = useSearchAuthorityMutation();

  // TODO covert to callback
  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: searchFilter.pagination.disablePagination ?? false,
      },
      sort: {
        field: searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchAuthority(filter)
      .unwrap()
      .then((resp: PaginatedResponse<AuthorityViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    getData();
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchUser = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<UserViewDto>(false, preFilter);
  const [searchUser] = useSearchUserMutation();

  const getData = useCallback(() => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: searchFilter.pagination.disablePagination ?? false,
      },
      sort: {
        field: searchFilter.pagination.sortBy === 'createdAt' ? 'user.createdAt' : searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchUser(filter)
      .unwrap()
      .then((resp: PaginatedResponse<UserViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  }, [
    searchFilter.filterFields,
    searchFilter.pagination.disablePagination,
    searchFilter.pagination.limit,
    searchFilter.pagination.page,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchUser,
    setData,
    setError,
    setIsLoading,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchLocation = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<LocationViewDto>(true, preFilter);
  const [searchLocation] = useSearchLocationMutation();

  // TODO covert to callback
  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: searchFilter.pagination.disablePagination ?? false,
      },
      sort: {
        field: 'createdAt',
        direction: FilterSortDirection.asc,
      },
    };
    setError(null);
    setIsLoading(true);
    searchLocation(filter)
      .unwrap()
      .then((resp: PaginatedResponse<LocationViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    getData();
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchTemplate = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<TemplateViewDto>(true, preFilter);
  const [searchTemplate] = useSearchTemplatesMutation();
  const [shouldRefetch, refetch] = useState({});
  const refresh = () => refetch({});

  useEffect(() => {
    let isCurrent = true;
    const getData = () => {
      const filter: FilterDto = {
        matchType: searchFilter.matchType,
        fields: searchFilter.filterFields,
        pagination: {
          page: searchFilter.pagination.page,
          limit: searchFilter.pagination.limit,
          disablePagination: searchFilter.pagination.disablePagination ?? false,
        },
        sort: {
          field: searchFilter.pagination.sortBy,
          direction: searchFilter.pagination.sortOrder,
        },
      };
      setError(null);
      setIsLoading(true);
      searchTemplate(filter)
        .unwrap()
        .then((resp: PaginatedResponse<TemplateViewDto>) => {
          if (isCurrent) {
            setData(resp);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status === 403 && e.data.message) {
            setError({ severity: 'error', msg: e.data.message });
          } else {
            setError({ severity: 'error', msg: `Unable to retrieve data from api` });
          }
        });
    };

    getData();
    return () => {
      isCurrent = false;
    };
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
    shouldRefetch,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    refresh,
  };
};

export const useSearchAsset = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<AssetViewDto>(false, preFilter);
  const [searchAsset] = useSearchAssetMutation();
  const [shouldRefetch, refetch] = useState({});
  const refresh = () => refetch({});

  useEffect(() => {
    let isCurrent = true;
    const getData = () => {
      const filter: FilterDto = {
        matchType: searchFilter.matchType,
        fields: searchFilter.filterFields,
        pagination: {
          page: searchFilter.pagination.page,
          limit: searchFilter.pagination.limit,
          disablePagination: searchFilter.pagination.disablePagination ?? false,
        },
        sort: {
          field: searchFilter.pagination.sortBy,
          direction: searchFilter.pagination.sortOrder,
        },
      };
      setError(null);
      setIsLoading(true);
      searchAsset(filter)
        .unwrap()
        .then((resp: PaginatedResponse<AssetViewDto>) => {
          if (isCurrent) {
            setData(resp);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status === 403 && e.data.message) {
            setError({ severity: 'error', msg: e.data.message });
          } else {
            setError({ severity: 'error', msg: `Unable to retrieve data from api` });
          }
        });
    };

    getData();
    return () => {
      isCurrent = false;
    };
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
    shouldRefetch,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    refresh,
  };
};

export const useSearchNormalReport = (preFilter: SearchFilterProps, templateId?: string) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<FormDataViewDto>(false, preFilter);
  const [searchNormalReport] = useSearchReportsMutation();
  const filterFields = searchFilter.filterFields.map((f) => {
    const filterField = { ...f };
    const [field, durationType] = filterField.field.split('.');
    filterField.field = field;
    filterField.durationType = durationType ? (durationType as ElementDurationTypeEnum) : undefined;
    return filterField;
  });
  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: [
        ...filterFields,
        {
          dataType: FilterFieldDataType.options,
          field: 'template',
          operator: OperatorV2.optionsContains,
          value: templateId,
        },
      ],
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: false,
      },
      sort: {
        field: searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchNormalReport(filter)
      .unwrap()
      .then((resp: PaginatedResponse<FormDataViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log('e', e);
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    if (templateId) getData();
  }, [
    templateId,
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchExceptionReport = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<ExceptionViewDto>(false, preFilter);
  const [searchExceptionReport] = useSearchExceptionReportsMutation();

  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: false,
      },
      sort: {
        field: searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchExceptionReport(filter)
      .unwrap()
      .then((resp: PaginatedResponse<ExceptionViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log('e', e);
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    getData();
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchExceptionRules = (preFilter: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<ExceptionRuleViewDto>(false, preFilter);
  const [searchExceptionRule] = useSearchExceptionRuleMutation();

  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: false,
      },
      sort: {
        field: searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchExceptionRule(filter)
      .unwrap()
      .then((resp: PaginatedResponse<ExceptionRuleViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log('e', e);
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    getData();
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    getData,
  };
};

export const useSearchSchedules = (disablePagination: boolean, preFilter?: SearchFilterProps) => {
  const { isLoading, setIsLoading, data, setData, searchFilter, setSearchFilter, error, setError } = useSearch<ScheduleViewDto>(disablePagination, preFilter);
  const [searchSchedule] = useGetSchedulesListMutation();
  const [shouldRefetch, refetch] = useState({});
  const refresh = () => refetch({});

  const getData = () => {
    const filter: FilterDto = {
      matchType: searchFilter.matchType,
      fields: searchFilter.filterFields,
      pagination: {
        page: searchFilter.pagination.page,
        limit: searchFilter.pagination.limit,
        disablePagination: disablePagination ?? false,
      },
      sort: {
        field: searchFilter.pagination.sortBy,
        direction: searchFilter.pagination.sortOrder,
      },
    };
    setError(null);
    setIsLoading(true);
    searchSchedule(filter)
      .unwrap()
      .then((resp: PaginatedResponse<ScheduleViewDto>) => {
        setData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  useEffect(() => {
    getData();
  }, [
    searchFilter.pagination.page,
    searchFilter.pagination.limit,
    searchFilter.pagination.sortBy,
    searchFilter.pagination.sortOrder,
    searchFilter.filterFields,
    shouldRefetch,
  ]);

  return {
    isLoading,
    data,
    error,
    searchFilter,
    setSearchFilter,
    refresh,
  };
};
