import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FlexBetween, FlexRow } from '../components/styled/Flex';
import { UserHelper } from '../helper/user.helper';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { changeImpersonateUserOrganisation, clearAdminState, selectAdminInfo } from '../redux/slices/admin.slice';
import NotificationMenu from './NotificationMenu';
import ThemeFontSizeControl from './ThemeFontSizeControl';
import UserMenu, { OrganisationSelect } from './UserMenu';

interface PageHeaderProps {
  searchPlaceHolder?: string;
  handleSearch: (value: string) => void;
  mobileShowMenu: () => void;
}

const SearchBarWithProfile = ({ searchPlaceHolder, handleSearch, mobileShowMenu }: PageHeaderProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });
  const { currentImpersonateUserOrganisation, impersonateUserId, organisations, firstname, lastname, login, administerOrganisation } =
    useAppSelector(selectAdminInfo);
  const name = UserHelper.getName(login, firstname, lastname);
  const onSelected = useCallback(
    (organisation: { id: string; name: string }) => {
      dispatch(changeImpersonateUserOrganisation({ organisation }));
      navigate(0);
    },
    [dispatch, navigate],
  );

  const removeAdmin = () => {
    dispatch(clearAdminState());
    if (pathname.includes('app/dashboard')) {
      navigate(0);
    } else {
      navigate('/app/dashboard');
    }
  };

  return (
    <Stack direction="column">
      <FlexBetween>
        <FlexRow gap={2}>
          {!lgUp && (
            <IconButton onClick={mobileShowMenu}>
              <MenuIcon />
            </IconButton>
          )}
          {/* {lgUp && <SearchBar />} */}
        </FlexRow>
        {administerOrganisation != null ? (
          <FlexRow gap={2} sx={{ height: 40 }}>
            <Box sx={{ backgroundColor: '#ffee58', color: theme.palette.text.secondary, borderRadius: 3, px: 2, textAlign: 'center' }}>
              <Typography component="span" sx={{ whiteSpace: 'nowrap' }} fontSize={10}>
                Administering
              </Typography>
              <br />
              {administerOrganisation != null && (
                <Typography component="span" sx={{ whiteSpace: 'nowrap' }} fontSize={12}>
                  ({administerOrganisation.name})
                </Typography>
              )}
            </Box>
            <NotificationMenu />
            <IconButton aria-label="close" color="inherit" size="large" onClick={removeAdmin} sx={{ width: 40 }}>
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </FlexRow>
        ) : impersonateUserId ? (
          <FlexRow gap={2} sx={{ height: 40 }}>
            <Box sx={{ backgroundColor: '#ffee58', color: theme.palette.text.secondary, borderRadius: 3, px: 2, textAlign: 'center' }}>
              <Typography component="span" sx={{ whiteSpace: 'nowrap' }} fontSize={10}>
                Impersonating
              </Typography>
              <br />
              <Typography component="span" sx={{ whiteSpace: 'nowrap' }} fontSize={12}>
                ({name})
              </Typography>
            </Box>
            <NotificationMenu />
            <OrganisationSelect onSelected={onSelected} organisations={organisations ?? []} currentOrganisation={currentImpersonateUserOrganisation} />
            <IconButton aria-label="close" color="inherit" size="large" onClick={removeAdmin} sx={{ width: 40 }}>
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </FlexRow>
        ) : (
          <FlexRow gap={2} alignItems="center" sx={{ height: 40 }}>
            <ThemeFontSizeControl />
            <NotificationMenu />
            <UserMenu />
          </FlexRow>
        )}
      </FlexBetween>
      <Divider sx={{ mt: lgUp ? 1.5 : 1, mb: 2 }} />
    </Stack>
  );
};

export default SearchBarWithProfile;
