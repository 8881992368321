import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { find } from 'lodash';
import { ReactNode, useState } from 'react';

import { SimpleViewDto } from '../../common/dto/common.dto';
import ConfirmDialog from '../elements/dialogs/ConfirmDialog';

interface FieldProps {
  field: string;
  value: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  handleChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  placeholder: string;
  isLoading?: boolean;
  options: SimpleViewDto[];
  hideEmptyOption?: boolean;
  fullWidth?: boolean;
  showDelete?: boolean;
  handleDelete?: (id: string) => void;
}

const FilterDropdown = (props: FieldProps) => {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [actionId, setActionId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const allOptions = props.options;

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    setIsOpen(false);
    setActionId(id);
    setConfirmDialogOpen(true);
  };

  const cancelDelete = () => {
    setActionId(null);
    setConfirmDialogOpen(false);
  };

  const confirmDelete = () => {
    setActionId(null);
    setConfirmDialogOpen(false);
    if (props.handleDelete) props.handleDelete(actionId as string);
  };

  const renderValue = (value: string) => {
    let valueText = props.placeholder;
    if (value) {
      const selectedFilter = find(allOptions, { id: value }) as SimpleViewDto;
      valueText = selectedFilter.name;
    }
    return (
      <Typography color={valueText === props.placeholder ? '#9e9e9e' : '#000'} variant="body1">
        {valueText}
      </Typography>
    );
  };

  const dropdownIcon = () => {
    return (
      <IconButton aria-label="edit" size="small" onClick={() => setIsOpen(!isOpen)} sx={{ mr: 0.5 }}>
        {isOpen === false ? <ArrowDropDownIcon fontSize="small" /> : <ArrowDropUpIcon fontSize="small" />}
      </IconButton>
    );
  };
  return (
    <>
      {/* {props.isLoading ? (
        <>
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ paddingY: 2 }}>
            <CircularProgress size={20} />
            <Typography variant="body1" sx={{ pl: 2 }}>
              Fetching filters
            </Typography>
          </Box>
        </>
      ) : null} */}
      {!props.isLoading ? (
        props.options.length ? (
          <FormControl fullWidth={props.fullWidth ?? false} sx={{ maxWidth: 200 }}>
            <Select
              sx={{
                '& .MuiSelect-select': {
                  paddingY: 1,
                  paddingx: 1.5,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '4px',
                },
              }}
              onClose={() => setIsOpen(false)}
              onOpen={() => setIsOpen(true)}
              open={isOpen}
              fullWidth
              renderValue={renderValue}
              size={props.size}
              id={props.field}
              name={props.field}
              value={props.value}
              placeholder={props.placeholder}
              IconComponent={dropdownIcon}
              onChange={(e, c) => {
                props.handleChange(e, c);
              }}
              disabled={props.disabled}
              MenuProps={{
                style: {
                  maxHeight: 300,
                },
              }}
              displayEmpty
            >
              {!props.hideEmptyOption && (
                <MenuItem value="">
                  <Typography variant="body1" color={(theme) => theme.palette.grey[500]}>
                    {props.placeholder}
                  </Typography>
                </MenuItem>
              )}
              {allOptions.map((item: SimpleViewDto) => (
                <MenuItem key={item.id} value={item.id} sx={[props.showDelete === true && { justifyContent: 'space-between' }]}>
                  <Typography variant="body1">{item.name}</Typography>
                  {props.showDelete === true && props.handleDelete !== undefined && (
                    <Tooltip title="Delete">
                      <IconButton onClick={(e) => onDelete(e, item.id)} sx={{ p: 0, background: '#fff' }} data-automation="delete-icon">
                        <DeleteOutlineOutlinedIcon fontSize="small" color="warning" />
                      </IconButton>
                    </Tooltip>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null
      ) : null}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={cancelDelete}
        onConfirm={confirmDelete}
        title="Delete Filter"
        message="Delete selected Filter"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </>
  );
};

export default FilterDropdown;
