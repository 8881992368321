import { Box } from '@mui/material';
import Select, { StylesConfig } from 'react-select';

import { SimpleViewDto } from '../../common/dto/common.dto';
import useIsLowDensity from '../../hook/useIsLowDensity';
import { LoaderHorizontal } from './Loader';

export interface DropdownOption extends SimpleViewDto {
  color?: string;
}

const selectStyles = (ldpi: boolean): StylesConfig<DropdownOption> => {
  return {
    control: (provided: any) => ({
      ...provided,
      minHeight: ldpi ? 30 : 30,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: ldpi ? 30 : 30,
      padding: '0 6px',
    }),

    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: ldpi ? 30 : 30,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.data.color,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
  };
};

interface Props<T> {
  options: T[];
  isLoading?: boolean;
  onSelected: (item: T) => void;
  value?: T | null | undefined;
  name: string;
  placeholder?: string;
  ignoreMinWidth?: boolean;
}

const DropdownPicker = <T extends SimpleViewDto>(props: Props<T>) => {
  const ldpi = useIsLowDensity();
  if (props.isLoading) return <LoaderHorizontal />;

  return (
    <Box style={{ minWidth: props.ignoreMinWidth ? 'unset' : 200, maxWidth: 300 }}>
      <Select
        id={props.name}
        classNamePrefix="react-select"
        name={props.name}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={(newValue) => {
          props.onSelected(newValue as T);
        }}
        menuPortalTarget={document.body}
        styles={{ ...selectStyles(ldpi) }}
        options={props.options}
        placeholder={props.placeholder ?? 'Search...'}
        tabSelectsValue={false}
        value={props.value}
      />
    </Box>
  );
};

export default DropdownPicker;
