import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MatchType } from '../../common/dto/exception-rule.dto';
import { FilterSortDirection } from '../../common/dto/filter.dto';
import { SimpleTemplateViewDto } from '../../common/dto/template.dto';
import { FilterParamType } from '../../components/filter/FilterPanel';
import { SearchFilterProps } from '../../hook/useSearch';

type TemplateProps = {
  template: SimpleTemplateViewDto | null;
};

type FilterData = SearchFilterProps & { filterParams: Record<string, FilterParamType> };

export enum FilterSettingsType {
  REPORT = 'report',
  USER = 'user',
  AUTHORITY = 'authority',
  LOCATION = 'location',
  TEMPLATE = 'template',
  ASSET = 'asset',
  SCHEDULE = 'schedule',
  EXCEPTION_RULES = 'exception_rules',
  EXCEPTION_REPORTS = 'exception_reports',
}

interface FilterSettingsState {
  [FilterSettingsType.REPORT]: {
    lastReportTemplate?: TemplateProps;
    filter: FilterData;
  };
  [FilterSettingsType.USER]: {
    filter: FilterData;
  };
  [FilterSettingsType.AUTHORITY]: {
    filter: FilterData;
  };
  [FilterSettingsType.LOCATION]: {
    filter: FilterData;
  };
  [FilterSettingsType.TEMPLATE]: {
    filter: FilterData;
  };
  [FilterSettingsType.ASSET]: {
    filter: FilterData;
  };
  [FilterSettingsType.SCHEDULE]: {
    filter: FilterData;
  };
  [FilterSettingsType.EXCEPTION_RULES]: {
    filter: FilterData;
  };
  [FilterSettingsType.EXCEPTION_REPORTS]: {
    filter: FilterData;
  };
}
const initFilterValue: FilterData = {
  filterFields: [],
  filterParams: {},
  pagination: {
    disablePagination: false,
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: FilterSortDirection.desc,
  },
  matchType: MatchType.ALL,
};

const initialState: FilterSettingsState = {
  report: {
    filter: initFilterValue,
  },
  user: {
    filter: initFilterValue,
  },
  authority: {
    filter: initFilterValue,
  },
  location: {
    filter: initFilterValue,
  },
  template: {
    filter: initFilterValue,
  },
  asset: {
    filter: initFilterValue,
  },
  schedule: {
    filter: initFilterValue,
  },
  exception_rules: {
    filter: initFilterValue,
  },
  exception_reports: {
    filter: initFilterValue,
  },
};

const filterSettings = createSlice({
  name: 'filterSettings',
  initialState,
  reducers: {
    updateLastReportTemplate: (state, action: PayloadAction<TemplateProps>) => {
      state.report.lastReportTemplate = action.payload;
    },
    updateFilter: (state, action: PayloadAction<{ type: FilterSettingsType; filter: FilterData }>) => {
      state[action.payload.type].filter = { ...action.payload.filter };
    },
    resetFilter: (state, action: PayloadAction<{ type: FilterSettingsType }>) => {
      state[action.payload.type].filter = initFilterValue;
    },
    clearFilters: (state) => {
      state = initialState;
    },
  },
});

export const { updateLastReportTemplate, updateFilter, resetFilter, clearFilters } = filterSettings.actions;

export default filterSettings.reducer;
