import { Grid } from '@mui/material';
import React, { useImperativeHandle } from 'react';

import { FilterViewDto } from '../../common/dto/filter.dto';
import { WithFormikAddedProps } from '../../hoc/WithFormik';
import { SubmitHandle, WithSideDrawerAddedProps } from '../../hoc/WithSideDrawer';
import { useAddFilterMutation, useUpdateFilterMutation } from '../../services/filter.service';
import { createLogger } from '../../utils/logger';
import CrudStringField from '../elements/crud-elements/simple/CrudStringField';

const logger = createLogger('FilterForm');

type FormProps = {
  filterId: string | null;
  complete: (reload: boolean, id?: string) => void;
};

type Props = FormProps & WithFormikAddedProps<FilterViewDto> & WithSideDrawerAddedProps;

const FilterForm: React.ForwardRefRenderFunction<SubmitHandle, Props> = (props, ref) => {
  const [addNewFilter] = useAddFilterMutation();
  const [updateFilter] = useUpdateFilterMutation();

  useImperativeHandle(ref, () => ({
    onSubmit() {
      submitToApi();
    },
  }));

  const submitToApi = () => {
    const values = { ...props.values };

    if (props.filterId) {
      updateFilter({ ...values, id: props.filterId as string })
        .unwrap()
        .then((res) => {
          props.setSubmitting(false);
          props.complete(true, res.id);
        })
        .catch((err) => {
          props.setSubmitting(false);
          props.handleApiErrors(err);
        });
    } else {
      addNewFilter({ ...values })
        .unwrap()
        .then((res) => {
          props.setSubmitting(false);
          props.complete(true, res.id);
        })
        .catch((err) => {
          props.setSubmitting(false);
          props.handleApiErrors(err);
        });
    }
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <CrudStringField
          field="name"
          label="Name"
          placeholder="Name"
          value={props.values.name}
          touched={props.touched.name}
          error={props.errors.name}
          handleChange={props.handleChange}
          handleBlur={props.handleBlur}
        />
      </Grid>
    </Grid>
  );
};

export default React.forwardRef(FilterForm);
