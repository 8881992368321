export class UserHelper {
  public static getName = (login: string, firstname?: string, lastname?: string): string => {
    if (firstname || lastname) {
      return [firstname, lastname].filter((i) => i && i.trim().length > 0).join(' ');
    }

    return login;
  };

  public static getNameWithLogin = (login: string, firstname?: string, lastname?: string): string => {
    let name = '';
    if (firstname || lastname) {
      name = [firstname, lastname].filter((i) => i && i.trim().length > 0).join(' ');
    }
    name += name ? ` (${login})` : login;
    return name;
  };
}
