import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface PreviewImageProps {
  imageUrls: string[];
  index?: number;
}

const initialState: PreviewImageProps = {
  imageUrls: [],
};

const imagePreviewSlice = createSlice({
  name: 'previewImage',
  initialState,
  reducers: {
    setPreviewImage: (state, { payload }: PayloadAction<PreviewImageProps>) => {
      state.imageUrls = payload.imageUrls;
      if (payload.index !== undefined) {
        state.index = payload.index;
      }
    },
    removePreviewImage: (state) => {
      state.imageUrls = [];
      state.index = undefined;
    },
  },
});

export const { setPreviewImage, removePreviewImage } = imagePreviewSlice.actions;
export const previewImageSelector = (state: RootState) => state.previewImage;
export default imagePreviewSlice.reducer;
