import { Box } from '@mui/material';

import { StyledFilterInput } from '../../styled/Input';

type Props = {
  value: string;
  onValueChange: (val: string) => void;
};

const StringValueProvider = (props: Props) => {
  return (
    <Box sx={{ px: 1, py: 1 }}>
      <StyledFilterInput
        name="stringInputFilter"
        autoFocus
        value={props.value ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          // setCurrentValue(e.target.value);
          props.onValueChange(e.target.value);
        }}
      />
    </Box>
  );
};

export default StringValueProvider;
