import { useCallback, useEffect, useState } from 'react';

import { versionAsNumber } from '../services/utils';
import { useLazyGetVersionQuery } from '../services/version.service';
import { createLogger } from '../utils/logger';

const logger = createLogger('new-version-check');

const useNewVersionAvailable = () => {
  const [getVersion] = useLazyGetVersionQuery();
  const [reload, setReload] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      getVersion()
        .unwrap()
        .then((res) => {
          const versionFromApi = res.webVersion;
          const versionFromApiParts = versionFromApi.split('.');
          const versionFromApiAsNumber = parseInt(
            `${versionFromApiParts[0].padStart(3, '0')}${versionFromApiParts[1].padStart(3, '0')}${versionFromApiParts[2].padStart(3, '0')}`,
            10,
          );
          logger.info(`checking ${versionFromApiAsNumber} against current ${versionAsNumber}`);
          if (versionFromApiAsNumber > versionAsNumber) {
            setReload(true);
          }
        });
    }
  }, [getVersion]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return reload;
};

export default useNewVersionAvailable;
