/* eslint-disable @typescript-eslint/ban-ts-comment */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { accountApi } from '../services/account.service';
import { adminApi } from '../services/admin.service';
import { assetApi } from '../services/asset.service';
import { authApi } from '../services/auth.service';
import { authorityApi } from '../services/authority.service';
import { dashboardApi } from '../services/dashboard.service';
import { downloadReportApi } from '../services/download-report.service';
import { exceptionReportApi } from '../services/exception-report.service';
import { exceptionReportUpdateApi } from '../services/exception-report-update.service';
import { exceptionRuleApi } from '../services/exception-rules.service';
import { featureApi } from '../services/feature.service';
import { fileUploadApi } from '../services/file-upload.service';
import { filterApi } from '../services/filter.service';
import { locationApi } from '../services/location.service';
import { notificationApi } from '../services/notification.service';
import { organisationApi } from '../services/organisation.service';
import { reportApi } from '../services/report.service';
import { reportUpdateApi } from '../services/report-update.service';
import { schedulesRulesApi } from '../services/schedules-rules.service';
import { templateApi } from '../services/template.service';
import { userApi } from '../services/user.service';
import { versionApi } from '../services/version.service';
import { widgetDataApi } from '../services/widget-data.service';
import accessTokenReducer from './slices/accessToken.slice';
import adminReducer from './slices/admin.slice';
import dataTableSettingsReducer from './slices/dataTableSettings.slice';
import filterSettingsReducer from './slices/filterSettings.slice';
import imagePreviewReducer from './slices/imagePreview.slice';
import notificationReducer from './slices/notification.slice';
import templateBuilderReducer from './slices/templateBuilder.slice';
import templateChangesReducer from './slices/templateChanges.slice';
import themeCustomizationReducer from './slices/themeCustomization.slice';
import userInfoReducer from './slices/userInfo.slice';
import userSettingsReducer from './slices/userSettings.slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      tokens: null,
    };

    for (const key of Object.keys(transformedState)) {
      if ((key as string).includes('Api')) {
        delete transformedState[key];
      }
    }

    return transformedState;
  },
});

const persistedReducerThemeCustomization = persistReducer(
  {
    key: 'themeCustomization',
    storage,
  },
  themeCustomizationReducer,
);
const persistedReducerTokens = persistReducer(
  {
    key: 'tokens',
    storage,
  },
  accessTokenReducer,
);
const persistedReducerUserInfo = persistReducer(
  {
    key: 'userInfo',
    storage,
  },
  userInfoReducer,
);

const persistedReducerUserSettings = persistReducer(
  {
    key: 'userSettings',
    storage,
  },
  userSettingsReducer,
);

const persistedReducerNotification = persistReducer(
  {
    key: 'notification',
    storage,
  },
  notificationReducer,
);

const persistedReducerPreviewImage = persistReducer(
  {
    key: 'previewImage',
    storage,
  },
  imagePreviewReducer,
);

const persistedReducerTemplateChanges = persistReducer(
  {
    key: 'templateChanges',
    storage,
  },
  templateChangesReducer,
);

const persistedReducerFilterSettingsSlice = persistReducer(
  {
    key: 'filterSettings',
    storage,
  },
  filterSettingsReducer,
);

const persistedReducerDataTableSettingsSlice = persistReducer(
  {
    key: 'dataTableSettings',
    storage,
  },
  dataTableSettingsReducer,
);

const persistedReducerAdminSlice = persistReducer(
  {
    key: 'admin',
    storage,
  },
  adminReducer,
);

export const store = configureStore({
  reducer: {
    themeCustomization: persistedReducerThemeCustomization,
    tokens: persistedReducerTokens,
    userInfo: persistedReducerUserInfo,
    userSettings: persistedReducerUserSettings,
    templateBuilder: templateBuilderReducer,
    notification: persistedReducerNotification,
    previewImage: persistedReducerPreviewImage,
    templateChanges: persistedReducerTemplateChanges,
    filterSettings: persistedReducerFilterSettingsSlice,
    dataTableSettings: persistedReducerDataTableSettingsSlice,
    admin: persistedReducerAdminSlice,
    [organisationApi.reducerPath]: organisationApi.reducer,
    [authorityApi.reducerPath]: authorityApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [featureApi.reducerPath]: featureApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [downloadReportApi.reducerPath]: downloadReportApi.reducer,
    [reportUpdateApi.reducerPath]: reportUpdateApi.reducer,
    [exceptionReportApi.reducerPath]: exceptionReportApi.reducer,
    [exceptionReportUpdateApi.reducerPath]: exceptionReportUpdateApi.reducer,
    [schedulesRulesApi.reducerPath]: schedulesRulesApi.reducer,
    [exceptionRuleApi.reducerPath]: exceptionRuleApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [widgetDataApi.reducerPath]: widgetDataApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [fileUploadApi.reducerPath]: fileUploadApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [filterApi.reducerPath]: filterApi.reducer,
    [versionApi.reducerPath]: versionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(organisationApi.middleware)
      .concat(authorityApi.middleware)
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(assetApi.middleware)
      .concat(adminApi.middleware)
      .concat(templateApi.middleware)
      .concat(locationApi.middleware)
      .concat(schedulesRulesApi.middleware)
      .concat(featureApi.middleware)
      .concat(reportApi.middleware)
      .concat(downloadReportApi.middleware)
      .concat(reportUpdateApi.middleware)
      .concat(exceptionReportApi.middleware)
      .concat(exceptionReportUpdateApi.middleware)
      .concat(exceptionRuleApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(widgetDataApi.middleware)
      .concat(accountApi.middleware)
      .concat(fileUploadApi.middleware)
      .concat(notificationApi.middleware)
      .concat(filterApi.middleware)
      .concat(versionApi.middleware)
      .concat(thunk),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

setupListeners(store.dispatch);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}
