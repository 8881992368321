import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TemplatePage } from '../../common/dto/template.dto';

interface TemplateChanges {
  [id: string]: {
    pages: TemplatePage[];
    updatedAt: string;
  };
}

interface TemplateChangesState {
  templateChanges: TemplateChanges;
}

const initialState: TemplateChangesState = {
  templateChanges: {},
};

const templateChangesSlice = createSlice({
  name: 'templateChanges',
  initialState,
  reducers: {
    updateTemplateChanges: (state, action: PayloadAction<{ templateId: string; pages: TemplatePage[]; updatedAt: string }>) => {
      state.templateChanges[action.payload.templateId] = {
        pages: action.payload.pages,
        updatedAt: action.payload.updatedAt,
      };
    },
    removeTemplateChanges: (state, action: PayloadAction<{ templateId: string }>) => {
      const { templateChanges } = state;
      if (templateChanges[action.payload.templateId]) {
        delete templateChanges[action.payload.templateId];
      }
      state.templateChanges = { ...templateChanges };
    },
    clearTemplateChanges: (state) => {
      state.templateChanges = {};
    },
  },
});

export const { updateTemplateChanges, removeTemplateChanges, clearTemplateChanges } = templateChangesSlice.actions;

export default templateChangesSlice.reducer;

// const stateSelf = (state: RootState) => state.templateChanges;

// export const getTemplateChanges = (templateId: string) =>
//   createSelector([stateSelf], (state) => {
//     return state.templateChanges[templateId];
//   });
