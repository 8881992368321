import { AlertColor } from '@mui/material';
import { RowSelectionState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { UserAccess } from '../../common/constants/access';
import { MatchType } from '../../common/dto/exception-rule.dto';
import { FilterFieldDataType, FilterSortDirection, FilterTypes } from '../../common/dto/filter.dto';
import { ElementDurationTypeEnum, FieldType, SimpleTemplateViewDto, TemplateViewDto } from '../../common/dto/template.dto';
import { AlertMessageType } from '../../common/types';
import Message from '../../components/common/Message';
import { FilterParamType, FilterRenderable } from '../../components/filter/FilterPanel';
import FilterAsset from '../../components/filter-elements/api-element/FilterAsset';
import FilterLocation from '../../components/filter-elements/api-element/FilterLocation';
import FilterUser from '../../components/filter-elements/api-element/FilterUser';
import FilterDateRange from '../../components/filter-elements/simple-element/FilterDateRange';
import FilterString from '../../components/filter-elements/simple-element/FilterString';
import FilterTemplateElement from '../../components/filter-elements/template-element/FilterTemplateElement';
import PageHeaderNew from '../../components/layout/PageHeaderNew';
import { useSearchNormalReport } from '../../hook/useSearch';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { FilterSettingsType, updateFilter } from '../../redux/slices/filterSettings.slice';
import { checkMultiLocAccess } from '../../redux/slices/userInfo.slice';
import { RootState } from '../../redux/store';
import { useLazyGetTemplateWithDeletedQuery } from '../../services/template.service';
import { createLogger } from '../../utils/logger';
import TemplatePicker from './components/TemplatePicker';
import DownloadButtonContainer from './containers/DownloadButtonContainer';
import NormalReportDataTable from './containers/normal-reports-datatable';

const logger = createLogger('Reports');

const filterSettingsBase: FilterRenderable[] = [
  {
    fieldDataType: FilterFieldDataType.options,
    field: 'location',
    label: 'Locations',
    FilterComponent: FilterLocation,
  },
  {
    fieldDataType: FilterFieldDataType.options,
    field: 'asset',
    label: 'Assets',
    FilterComponent: FilterAsset,
  },
  {
    fieldDataType: FilterFieldDataType.string,
    field: 'assetType',
    label: 'Assets Type',
    FilterComponent: FilterString,
  },
  {
    fieldDataType: FilterFieldDataType.options,
    field: 'createdBy',
    label: 'Submitted By',
    FilterComponent: FilterUser,
  },
  {
    fieldDataType: FilterFieldDataType.date,
    field: 'createdAt',
    label: 'Completed At',
    FilterComponent: FilterDateRange,
  },
];

const Reports = () => {
  logger.render();
  const dispatch = useAppDispatch();
  const reportFilter = useAppSelector((state: RootState) => state.filterSettings.report);

  const [filterParams, setFilterParams] = useState<Record<string, FilterParamType>>(structuredClone(reportFilter.filter.filterParams));
  const [message, setMessage] = useState<AlertMessageType>();
  const [currentTemplate, setCurrentTemplate] = useState<SimpleTemplateViewDto | null>(null);
  const [templateData, setTemplateData] = useState<TemplateViewDto | null>(null);
  const [filterSettings, setFilterSettings] = useState<FilterRenderable[]>([]);

  const locationIds = currentTemplate ? currentTemplate.locationIds : [];
  const hasViewAccess = useAppSelector(checkMultiLocAccess(UserAccess.REPORT_VIEW, locationIds));

  const [getTemplateWithDeleted] = useLazyGetTemplateWithDeletedQuery();
  const {
    data,
    error: reportError,
    isLoading,
    setSearchFilter,
    searchFilter,
    getData,
  } = useSearchNormalReport(structuredClone(reportFilter.filter), currentTemplate?.id);

  useEffect(() => {
    dispatch(updateFilter({ type: FilterSettingsType.REPORT, filter: { ...structuredClone(searchFilter), filterParams } }));
  }, [dispatch, filterParams, searchFilter]);

  useEffect(() => {
    if (reportError) {
      setMessage(reportError);
    } else {
      setMessage(undefined);
    }
  }, [reportError, data]);

  // #region download
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  useEffect(() => {
    const ids = Object.keys(rowSelection).map((i) => i as string);
    setSelectedIds(ids);
  }, [rowSelection]);

  useEffect(() => {
    setRowSelection({});
  }, [searchFilter.filterFields]);

  // #endregion

  const getTemplate = (template: SimpleTemplateViewDto) => {
    setMessage(undefined);
    setRowSelection({});
    if (!reportFilter.lastReportTemplate?.template || reportFilter.lastReportTemplate?.template.id !== template.id) {
      setFilterParams({});
      setSearchFilter((prev) => {
        return {
          ...prev,
          matchType: MatchType.ALL,
          filterFields: [],
          pagination: {
            ...prev.pagination,
            sortBy: 'createdAt',
            sortOrder: FilterSortDirection.desc,
            page: 1,
          },
        };
      });
    }

    setCurrentTemplate(template);
    getTemplateWithDeleted(template.id)
      .unwrap()
      .then((resp) => {
        setTemplateData(resp);
        const result: FilterRenderable[] = [...filterSettingsBase];
        resp.pages.forEach((page) => {
          page.elements.forEach((element) => {
            if (element.fieldType === FieldType.duration) {
              if (element.config.duration?.enableDay) {
                result.push({
                  fieldDataType: FilterFieldDataType.number,
                  field: `${element.elementId}.${ElementDurationTypeEnum.DAY}`,
                  label: `${element.title} Day`,
                  element,
                  FilterComponent: FilterTemplateElement,
                });
              }
              if (element.config.duration?.enableHour) {
                result.push({
                  fieldDataType: FilterFieldDataType.number,
                  field: `${element.elementId}.${ElementDurationTypeEnum.HOUR}`,
                  label: `${element.title} Hour`,
                  element,
                  FilterComponent: FilterTemplateElement,
                });
              }
              if (element.config.duration?.enableMinute) {
                result.push({
                  fieldDataType: FilterFieldDataType.number,
                  field: `${element.elementId}.${ElementDurationTypeEnum.MINUTE}`,
                  label: `${element.title} Minute`,
                  element,
                  FilterComponent: FilterTemplateElement,
                });
              }
              if (element.config.duration?.enableSecond) {
                result.push({
                  fieldDataType: FilterFieldDataType.number,
                  field: `${element.elementId}.${ElementDurationTypeEnum.SECOND}`,
                  label: `${element.title} Second`,
                  element,
                  FilterComponent: FilterTemplateElement,
                });
              }
            } else if ([FieldType.singleLine, FieldType.multiLine].includes(element.fieldType)) {
              result.push({
                fieldDataType: FilterFieldDataType.string,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            } else if ([FieldType.numeric, FieldType.oilLevel, FieldType.temperature, FieldType.fuel].includes(element.fieldType)) {
              result.push({
                fieldDataType: FilterFieldDataType.number,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            } else if ([FieldType.checkbox, FieldType.radio, FieldType.dropdown].includes(element.fieldType)) {
              result.push({
                fieldDataType: FilterFieldDataType.options,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            } else if ([FieldType.datetime].includes(element.fieldType)) {
              let dateFieldDataType: FilterFieldDataType;
              if (element.config.range) {
                dateFieldDataType = element.config.date ? FilterFieldDataType.dateRange : FilterFieldDataType.timeRange;
              } else {
                dateFieldDataType = element.config.date ? FilterFieldDataType.date : FilterFieldDataType.time;
              }
              result.push({
                fieldDataType: dateFieldDataType,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            } else if ([FieldType.issue].includes(element.fieldType)) {
              result.push({
                fieldDataType: FilterFieldDataType.issues,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            } else if (element.fieldType === FieldType.customPicker) {
              result.push({
                fieldDataType: FilterFieldDataType.string,
                field: `${element.elementId}`,
                label: element.title,
                element,
                FilterComponent: FilterTemplateElement,
              });
            }
          });
        });
        setFilterSettings(result);
      })
      .catch((e) => {
        let msg = 'Failed to load template';
        let severity: AlertColor = 'error';
        if (e.status === 404) {
          severity = 'warning';
        }
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setMessage({ severity, msg });
        setTemplateData(null);
        setFilterSettings([]);
      });
  };

  const handleDelete = async () => {
    setMessage({ severity: 'success', msg: 'Deleted reports successfully' });
    getData();
    setRowSelection({});
  };

  return (
    <>
      <PageHeaderNew
        title="Reports"
        filterType={FilterTypes.Report}
        templateId={templateData?.id}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        filterElements={filterSettings}
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
        hasViewAccess={hasViewAccess}
        left={<TemplatePicker getTemplate={getTemplate} showDeleted currentTemplate={currentTemplate} setMessage={setMessage} />}
        setMessage={setMessage}
      >
        {templateData ? (
          <DownloadButtonContainer template={templateData} setMessage={setMessage} selectedIds={selectedIds} handleDelete={handleDelete} />
        ) : null}
      </PageHeaderNew>
      <Message message={message} sx={{ my: 2.5 }} onClose={() => setMessage(undefined)} />
      {templateData && (
        <NormalReportDataTable
          isLoading={isLoading}
          data={data}
          setSearchFilter={setSearchFilter}
          template={templateData}
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
        />
      )}
    </>
  );
};

export default Reports;
