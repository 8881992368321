import { SetStateAction, useEffect, useState } from 'react';

import { ConstrainV2, OperatorV2 } from '../../../common/dto/filter.dto';
import StringValueProvider from '../value-providers/StringValueProvider';

type StringFieldProps = {
  value: string;
  constrain: ConstrainV2;
  onValueChange: (val?: string) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
};
const StringField = (props: StringFieldProps) => {
  const [cValue, setCValue] = useState<string>(props.value);
  const { setError, setShowError, onValueChange } = props;

  useEffect(() => {
    if (cValue.trim().length === 0 && ![OperatorV2.strIsEmail, OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(props.constrain.id)) {
      setError('Value cannot be empty');
    } else {
      setShowError(false);
      setError(null);
      onValueChange(cValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  const isValueNeeded = ![OperatorV2.strIsEmail, OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(props.constrain.id);

  useEffect(() => {
    if (!isValueNeeded) onValueChange(undefined);
  }, [isValueNeeded, onValueChange]);

  return isValueNeeded ? <StringValueProvider value={cValue} onValueChange={(val) => setCValue(val ?? '')} /> : null;
};

export default StringField;
