import { Box, styled } from '@mui/material';

export const FlexBetween = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const FlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const FlexColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));
