import { Box, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';

import { FlexRow } from '../../../styled/Flex';
import FormDataErrorField from '../FormDataErrorField';
import { CustomDateProps, getValue } from './CustomDateTimePicker';

const CustomDatePicker = (props: CustomDateProps) => {
  const theme = useTheme();

  return (
    <Box>
      <FlexRow columnGap={2}>
        {props.names.map((name, index) => (
          <Box key={name}>
            <DatePicker
              desktopModeMediaQuery={theme.breakpoints.up('md')}
              value={getValue(props.elementValue, name, props.type)}
              onChange={(date: Moment | null) => props.onChange(date, name)}
              format={props.format}
              // minDate={getMinDate(props.elementValue, props.names[0], props.type, props.range, index)} // TODO plugin not support min/max date for undefined value
              // maxDate={getMaxDate(props.elementValue, props.names[1], props.type, props.range, index)}
              disabled={props.disabled}
              timezone={props.timezone}
              slotProps={{
                textField: {
                  id: name,
                  name,
                  placeholder: props.placeholder,
                  onBeforeInput: (e) => {
                    if (e?.preventDefault) {
                      e?.preventDefault();
                      e?.stopPropagation();
                    }
                  },
                },
              }}
            />
          </Box>
        ))}
      </FlexRow>
      <FormDataErrorField error={props.error ?? undefined} touched={props.touched ?? undefined} />
    </Box>
  );
};

export default CustomDatePicker;
