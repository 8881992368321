import 'overlayscrollbars/overlayscrollbars.css';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Divider, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { ComponentType, useEffect, useState } from 'react';

import { ResponsiveHeading } from '../components/common/ResponsiveHeading';
import { IS_E2E_MODE } from '../config/mode';
import useIsLowDensity from '../hook/useIsLowDensity';

interface WithSideDrawerInputProps {
  title: string;
  isOpen: boolean;
  close: (reload?: boolean) => void;
  drawerWidth?: number;
  disableFooter?: boolean;
  submitLabel?: string;
}

export interface WithSideDrawerAddedProps {
  isSubmitting: boolean;
  setSubmitting: (flag: boolean) => void;
  setDisableSubmit?: (filelds: DisableFormSubmit) => void;
  setFooterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export type SubmitHandle = {
  onSubmit: () => void;
};

export interface DisableFormSubmit {
  [field: string]: boolean;
}

const withSideDrawer = <T extends object>(
  WrappedComponent: ComponentType<T>,
): React.ComponentType<Omit<T & WithSideDrawerInputProps, keyof WithSideDrawerAddedProps>> => {
  const ComponentUpdated = ({
    title,
    isOpen,
    close,
    submitLabel = 'Submit',
    drawerWidth = 700,
    disableFooter = false,
    ...hoc
  }: Omit<T & WithSideDrawerInputProps, keyof WithSideDrawerAddedProps>) => {
    const ldpi = useIsLowDensity();
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
      defaultMatches: true,
      noSsr: false,
    });

    const [footerDisabled, setFooterDisabled] = useState(disableFooter);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisableSubmit, setDisableSubmit] = useState<DisableFormSubmit>({});

    useEffect(() => {
      // reset submitting if drawer is closed
      if (!isOpen && isSubmitting) setSubmitting(false);
    }, [isOpen, isSubmitting]);

    // const childRef = useRef<SubmitHandle>();
    // const onSubmit = useCallback(() => {
    //   childRef.current?.onSubmit();
    // }, [childRef]);

    // console.log('withSideDrawer', hoc);
    const isDisable = Object.values(isDisableSubmit).length > 0 ? Object.values(isDisableSubmit).indexOf(true) >= 0 : false;

    return (
      <Drawer
        data-automation="sidedrawer"
        anchor="right"
        open={isOpen}
        sx={{ zIndex: 100 }}
        onClose={(e, reason) => {
          if (!IS_E2E_MODE && reason === 'escapeKeyDown') {
            close();
            setTimeout(() => {
              setFooterDisabled(disableFooter);
            }, 500);
          }
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: ldpi ? 20 : 20,
            borderBottomLeftRadius: ldpi ? 20 : 20,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            width: drawerWidth,
            [theme.breakpoints.down('md')]: {
              width: '100%',
              borderRadius: 0,
            },
          },
        }}
      >
        <Box display="flex" flex="1" flexDirection="column" sx={{ height: '100%' }}>
          <Box sx={{ px: lgUp ? 4 : 3, py: lgUp ? 3 : 2 }}>
            <Grid container alignItems="center">
              <Grid item xs zeroMinWidth data-automation="drawer-heading">
                <ResponsiveHeading>{title}</ResponsiveHeading>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    close();
                    // timeout to show btn off screen animation
                    setTimeout(() => {
                      setFooterDisabled(disableFooter);
                    }, 500);
                  }}
                  data-automation="drawer-close-icon"
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <OverlayScrollbarsComponent
            defer
            style={{ height: '100%' }}
            options={{
              overflow: {
                // x: 'scroll',
                y: 'scroll',
              },
            }}
          >
            <Box sx={{ px: lgUp ? 4 : 3, py: lgUp ? 3 : 2 }}>
              <WrappedComponent
                {...(hoc as T)}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
                setDisableSubmit={setDisableSubmit}
                setFooterDisabled={setFooterDisabled}
              />
            </Box>
          </OverlayScrollbarsComponent>
          {!footerDisabled && (
            <>
              <Divider />
              <Grid sx={{ px: lgUp ? 4 : 3, py: lgUp ? 3 : 2 }} container columnSpacing={2} justifyContent="center">
                <Grid item>
                  <LoadingButton
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    variant="contained"
                    disabled={isDisable}
                    onClick={() => {
                      setSubmitting(true);
                    }}
                    data-automation="drawer-submit-btn"
                  >
                    {submitLabel}
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={() => close()} className="btn">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Drawer>
    );
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentUpdated.displayName = `withSideDrawer(${displayName})`;

  return ComponentUpdated;
};

export default withSideDrawer;
