import { Alert, Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { find } from 'lodash';
import { ChangeEvent, ReactNode } from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';

export interface OptionViewDto extends SimpleViewDto {
  displayName?: string;
}

interface FieldProps {
  label: string;
  field: string;
  value: string;
  size?: 'small' | 'medium';
  touched?: boolean | null;
  error?: string | null;
  disabled?: boolean;
  handleChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  handleBlur: (e: string | ChangeEvent) => void;
  placeholder: string;
  isLoading?: boolean;
  options: OptionViewDto[];
  hideLabel?: boolean;
  hideEmptyOption?: boolean;
  emptyMessage?: string;
  valueChanged?: () => void;
  fixedOption?: SimpleViewDto;
}

const CrudDropdownFieldNew = (props: FieldProps) => {
  let allOptions = props.options;
  if (props.fixedOption) {
    allOptions = [...props.options, props.fixedOption];
  }

  const renderValue = (value: string) => {
    let valueText = props.placeholder;
    if (value) {
      const selectedFilter = find(allOptions, { id: value }) as OptionViewDto;
      if (selectedFilter) valueText = selectedFilter.name;
    }
    return (
      <Typography color={valueText === props.placeholder ? '#9e9e9e' : '#000'} variant="body1">
        {valueText}
      </Typography>
    );
  };

  return (
    <>
      {props.hideLabel !== true && <InputLabel sx={{ paddingBottom: 1 }}>{props.label}</InputLabel>}
      {props.isLoading ? (
        <>
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ paddingY: 2 }}>
            <CircularProgress size={20} />
            <Typography variant="body1" sx={{ pl: 2 }}>
              Fetching options
            </Typography>
          </Box>
        </>
      ) : null}

      {!props.isLoading ? (
        props.options.length ? (
          <FormControl fullWidth>
            <Select
              fullWidth
              size={props.size}
              sx={{
                height: '5rem',
              }}
              id={props.field}
              name={props.field}
              value={props.value}
              placeholder={props.placeholder}
              onChange={(e, c) => {
                props.handleChange(e, c);
                if (props.valueChanged) props.valueChanged();
              }}
              renderValue={renderValue}
              onBlur={props.handleBlur}
              disabled={props.disabled}
              data-automation={props.field}
              MenuProps={{
                style: {
                  maxHeight: 300,
                },
              }}
              displayEmpty
            >
              {!props.hideEmptyOption && (
                <MenuItem value="">
                  <Typography variant="body1" color={(theme) => theme.palette.grey[500]}>
                    {props.placeholder}
                  </Typography>
                </MenuItem>
              )}
              {allOptions.map((item: OptionViewDto) => (
                <MenuItem key={item.id} value={item.id}>
                  <Typography variant="body1">{item.displayName ?? item.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box>
            <Alert severity="warning" sx={{ alignItems: 'center' }}>
              {props.emptyMessage ?? 'No options to select'}
            </Alert>
          </Box>
        )
      ) : null}
      {props.touched && props.error && (
        <FormHelperText error data-automation={`error-${props.field}`} data-automation-field="error">
          {props.error}
        </FormHelperText>
      )}
    </>
  );
};

export default CrudDropdownFieldNew;
