import * as Yup from 'yup';

import { SelectOption } from '../dto/template.dto';

Yup.addMethod<Yup.ArraySchema<any>>(Yup.array, 'uniqueObjectArray', function (message, mapper) {
  return this.test('uniqueObjectArray', message, (list: SelectOption[] | undefined) => {
    if (list) {
      return list.length === new Set(list.map(mapper)).size;
    }
    return true;
  });
});

declare module 'yup' {
  interface ArraySchema<T> {
    uniqueObjectArray(message: string, isEqual: (value: SelectOption) => string): ArraySchema<T>;
  }
}

export default Yup;
