import { Box, Grid } from '@mui/material';

import { FilterTimeRangeValue } from '../../../hoc/WithFilter';
import CustomHoursPicker from '../../common/CustomHoursPicker';

type Props = {
  value?: FilterTimeRangeValue;
  onValueChange: (val: FilterTimeRangeValue) => void;
};

const DateRangeProvider = (props: Props) => {
  const onChange = (name: 'from' | 'to', date: string) => {
    let values = { ...props.value };
    if (values) {
      values[name] = date;
    } else {
      values = {
        from: '',
        to: '',
      };
      values[name] = date;
    }

    props.onValueChange(values as FilterTimeRangeValue);
  };

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomHoursPicker
            field="fromTimeFilter"
            placeholder="From"
            value={props.value?.from}
            // maxValue={props.value?.to}
            setValue={(date) => onChange('from', date)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomHoursPicker
            field="toTimeFilter"
            placeholder="To"
            value={props.value?.to}
            // minValue={props.value?.from}
            setValue={(date) => onChange('to', date)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DateRangeProvider;
