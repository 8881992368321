import { createTheme, PaletteColorOptions } from '@mui/material';

import { FontSizes } from './theme.context';

declare module '@mui/material/styles' {
  // interface TypographyVariants {}

  // interface TypographyVariantsOptions {}

  interface Palette {
    brandBlueBg: string;
    brandBlueLink: string;
    neutral?: PaletteColorOptions;
  }
  interface PaletteOptions {
    brandBlueBg: string;
    brandBlueLink: string;
    neutral?: PaletteColorOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h4: false;
    h5: false;
    h6: false;
    // subtitle1: false;
    // subtitle2: false;
    // caption: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    simple: true;
    filter: true;
  }
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const brandBlueBg = '#3186f3';
const brandBlueLink = '#0d6efd';
const borderColor = '#efefef'; // used for input borders and dividers

export const createCustomizedTheme = (fontSize: FontSizes, isLowDensityScreen: boolean) => {
  const originalBaseFontSize = isLowDensityScreen ? 9 : 9;
  const baseSpacing = isLowDensityScreen ? 4 : 6;
  let diff = 0;
  if (fontSize === 'small') diff -= 1;
  else if (fontSize === 'large') diff += 2;
  const baseFontSize = originalBaseFontSize + diff;

  return createTheme({
    spacing: baseSpacing,
    palette: {
      brandBlueBg,
      brandBlueLink,
      text: {
        primary: '#081e3f',
        secondary: '#646464',
      },
      primary: {
        // main: brandBlueLink,
        main: brandBlueBg,
      },
      neutral: {
        main: '#7a7a7a',
      },
      divider: borderColor,
      // success: {
      //   main: '#4caf50',
      // },
    },
    typography: {
      fontFamily: ['Poppins', 'san-serif'].join(','),
      htmlFontSize: baseFontSize,
      fontSize: baseFontSize,
      h1: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
      },
      caption: {
        fontSize: '1.2rem',
      },
      body1: {
        fontSize: '1.4rem',
      },
      body2: {
        fontSize: '1.2rem',
      },
      // h3: undefined,
      // h4: undefined,
      // h5: undefined,
      // h6: undefined,
      // dataBold: {
      //   fontWeight: 500,
      //   color: '#000000',
      //   display: 'block',
      // },

      // subtitle1: undefined,
      // subtitle2: undefined,
      // caption: undefined,
    },
    // shape: {
    //   borderRadius: 10,
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          html: {
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            fontSize: `${(baseFontSize / 16) * 100}%`,
          },
          body: {
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },
          a: {
            textDecoration: 'none',
            color: brandBlueLink,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: brandBlueLink,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': {
              backgroundColor: 'white',
              borderRadius: '10px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '10px',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'simple' },
            style: {
              padding: '2px 8px',
              minWidth: 0,
              lineHeight: 1,
              color: brandBlueBg,
              fontWeight: 'bold',
            },
          },
          {
            props: { variant: 'filter' },
            style: {
              minWidth: 0,
              lineHeight: 1,
              color: brandBlueBg,
              fontWeight: 'bold',
              background: 'white',
              border: 'solid 1px red',
              borderRadius: '10px',
            },
          },
        ],
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '&:not(.MuiButton-filter).MuiButton-sizeMedium': {
              // minWidth: 150,
              fontWeight: 'bold',
              borderRadius: 35,
              padding: '8px 25px',
              fontSize: '1.4rem',
            },
            '&:not(.MuiButton-simpleSizeSmall).MuiButton-sizeSmall': {
              fontSize: '1.4rem',
              fontWeight: 'bold',
              borderRadius: 35,
              padding: '6px 18px',
            },
            '&.MuiButton-simpleSizeSmall.MuiButton-sizeSmall': {
              fontSize: '1.3rem',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '1.2rem',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // borderColor,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '1.2rem',
            // height: '3.2rem',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '1.2rem',
            fontSize: '1.4rem',
            fontWeight: 'normal',
            backgroundColor: '#F5F7F9',
            borderBottom: '1px solid #DADEEB',

            '&.MuiTableCell-head': {
              padding: '1.4rem',
              backgroundColor: '#F1F2F5',
              fontWeight: 'bold',
            },
            '&:not(.no-padding).MuiTableCell-body': {
              paddingLeft: '1.2rem',
              paddingRight: '1.2rem',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            },
          },
        },
      },
    },
  });
};
