import { Stack, styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import PreviewImage from '../components/common/PreviewImage';
import SimpleConfirmDialog from '../components/elements/dialogs/SimpleConfirmDialog';
import useIsLowDensity from '../hook/useIsLowDensity';
import useNewVersionAvailable from '../hook/useNewVersionAvailable';
import { useAppSelector } from '../redux/hooks';
import { getUserActionableStepsPending, selectUserInfo, userCurrentOrganisationsSelector } from '../redux/slices/userInfo.slice';
import { sentrySetOrganisationContext } from '../utils/sentry';
import SearchBarWithProfile from './SearchBarWithProfile';
import SideMenuContainer from './SideMenuContainer';

const BoxBlue = styled('div')<{ showMenuName: boolean; ldpi: boolean }>(({ theme, showMenuName, ldpi }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  minHeight: '100%',
  padding: theme.spacing(3),
  background: theme.palette.brandBlueBg,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: showMenuName ? '220px' : '75px',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const BoxWhite = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  minHeight: '100%',
  background: 'white',
  borderRadius: '20px',
  padding: theme.spacing(3, 3.5),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 2.5),
  },
}));

const BaseLayout = () => {
  const ldpi = useIsLowDensity();
  const isReloadRequired = useNewVersionAvailable();

  const [showReloadConfirmation, setShowReloadConfirmation] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const userInfo = useAppSelector(selectUserInfo);
  const stepsPending = useAppSelector(getUserActionableStepsPending);
  const userCurrentOrganisation = useAppSelector(userCurrentOrganisationsSelector);
  const [showMenuName, setShowMenuName] = useState(true);

  useEffect(() => {
    if (isReloadRequired) setShowReloadConfirmation(true);
  }, [isReloadRequired]);

  useEffect(() => {
    // TODO move to login/logout/refresh token slice thunks and use sentrySetUserContext and sentrySetOrganisationContext
    Sentry.setUser({
      id: userInfo.id,
      login: userInfo.login,
      name: `${userInfo.firstname} ${userInfo.lastname}`,
    } as Sentry.User);
  }, [userInfo]);

  useEffect(() => {
    if (userCurrentOrganisation) sentrySetOrganisationContext(userCurrentOrganisation);
  }, [userCurrentOrganisation]);

  if (!userInfo.id) return <Navigate to="/auth/login" />;
  if (stepsPending.includes('mfa-setup')) {
    return <Navigate to="/auth/mfa-setup" />;
  }
  if (stepsPending.includes('mfa-verify')) {
    return <Navigate to="/auth/mfa-verify" />;
  }
  if (stepsPending.includes('profile-setup')) {
    return <Navigate to="/auth/complete-profile" />;
  }
  if (stepsPending.includes('accept-invite-to-organisation')) {
    return <Navigate to="/auth/accept-invite" />;
  }

  return (
    <>
      <BoxBlue showMenuName={showMenuName} ldpi={ldpi}>
        <BoxWhite
          data-div="BoxWhite"
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            // minHeight: '850px',
            // height: 'calc(100vh - 48px)',
          }}
        >
          <Stack direction="column">
            <SearchBarWithProfile
              searchPlaceHolder="Search users"
              handleSearch={() => {}}
              mobileShowMenu={() => {
                setSidebarOpen(true);
              }}
            />
            <Outlet />
          </Stack>
        </BoxWhite>
      </BoxBlue>
      <SideMenuContainer
        showMenuName={showMenuName}
        setShowMenuName={setShowMenuName}
        onClose={() => {
          setSidebarOpen(false);
        }}
        open={isSidebarOpen}
      />
      <PreviewImage />
      <SimpleConfirmDialog
        hideCancel
        isOpen={showReloadConfirmation}
        onClose={() => {
          window.location.reload();
        }}
        onConfirm={() => {
          window.location.reload();
        }}
        title="New version"
        message="A new version of the app is available. Your browser tab will refresh with the new version."
        confirmButtonText="Confirm"
      />
    </>
  );
};

export default BaseLayout;
