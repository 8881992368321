import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, FilterFieldDataType, OperatorV2 } from '../../../common/dto/filter.dto';
import { FilterValueTypes } from '../../../hoc/WithFilter';
import { useGetAllLocation } from '../../../hook/useDataForSelect';
import FilterOptionsMultiple from '../simple-element/FilterOptionsMultiple';

type Props = {
  fieldDataType: FilterFieldDataType;
  field: string;
  label: string;
  value: SimpleViewDto[];
  constrain?: ConstrainV2;
  fieldSuffixMeta?: Record<string, string>;
  onFilterValueChanged: (
    fieldDataType: FilterFieldDataType,
    field: string,
    value?: FilterValueTypes,
    constrain?: ConstrainV2,
    fieldSuffixMeta?: Record<string, string>,
  ) => void;
};

const optionsContainAndChildren: ConstrainV2 = {
  id: OperatorV2.optionsContainAndChildren,
  title: 'Contains',
  applicableDataTypes: [FilterFieldDataType.options],
};
const FilterLocation = (props: Props) => {
  const { data: dataLocation, isLoading: isLoadingLocation, error: errorLocation } = useGetAllLocation();
  return (
    <FilterOptionsMultiple
      fieldDataType={props.fieldDataType}
      field={props.field}
      label={props.label}
      isLoading={isLoadingLocation}
      options={dataLocation ?? []}
      value={props.value}
      constrain={props.constrain}
      fieldSuffixMeta={props.fieldSuffixMeta}
      onFilterValueChanged={props.onFilterValueChanged}
      fixedConstrain={optionsContainAndChildren}
      hideConstrains
    />
  );
};

export default FilterLocation;
