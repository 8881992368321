import { FallbackProps } from 'react-error-boundary';

const RootErrorBoundary = ({ error, resetErrorBoundary }: FallbackProps) => {
  const showStackTrace = import.meta.env.VITE_APP_ENVIRONMENT !== 'prod';

  return (
    <div role="alert">
      <p>Unexpected:</p>
      {showStackTrace ? <pre>{error.message}</pre> : <p>An Unexpected error occurred which has been report to our team. We should resolve this issue soon.</p>}
      <div>
        <button onClick={resetErrorBoundary}>Try reload</button>
      </div>
      <p>If reload doesnt work, please go to home with the link below:</p>
      <div>
        <button
          onClick={() => {
            window.location.href = '/app';
          }}
        >
          Go to home
        </button>
      </div>
    </div>
  );
};

export default RootErrorBoundary;
