import { Link, styled } from '@mui/material';

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[700],
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    background: 'rgba(0, 0, 0, 0.04)',
    // textDecoration: 'underline',
  },
  '&.active': {
    color: theme.palette.primary.main,
    background: 'rgba(0, 0, 0, 0.04)',
    // textDecoration: 'underline',
  },
}));
