import { Box, InputBase, Slider, styled } from '@mui/material';

import { FlexRow } from '../../styled/Flex';

function valuetext(value: number) {
  return `${value}`;
}
type Props = {
  min: number;
  max: number;
  value?: number | null;
  onValueChange: (val: number) => void;
};
const NumberRangeProvider = (props: Props) => {
  // const split = Math.ceil((props.max - props.min) * 0.1);
  // const marks = useMemo(() => {
  //   return Array.from({ length: Math.ceil((props.max - props.min + 1) / split) }, (_, i) => {
  //     return {
  //       value: i + props.min,
  //       label: i + props.min,
  //     };
  //   });
  // }, [props.min, props.max]);

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <FlexRow alignItems="center">
        <Slider
          sx={{ flexGrow: 1 }}
          aria-label="Always visible"
          step={1}
          marks
          value={props.value ?? undefined}
          min={props.min}
          max={props.max}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          data-automation="slider-input-filter"
          name="sliderInputFilter"
          onChange={(e, val) => {
            // setCurrentValue(val as number);
            props.onValueChange(val as number);
          }}
        />
        <StyledNumberRangeInput
          type="number"
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // setCurrentValue(Number(e.target.value));
            props.onValueChange(Number(e.target.value));
          }}
        />
      </FlexRow>
    </Box>
  );
};

export default NumberRangeProvider;

const StyledNumberRangeInput = styled(InputBase)(({ theme }) => ({
  width: '100px',
  height: '100%',
  padding: 0,
  marginLeft: '15px',
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'}`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));
