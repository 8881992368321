import { BaseViewDto } from './base.dto';
import { AssetMin, CommonMin, FileCreateAndUpdateDto, FileViewDto, SimpleViewWithDeletedDto, UserMin } from './common.dto';
import { ExceptionViewDto } from './exception-reports.dto';

export enum ExceptionStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
  PENDING = 'Pending',
  ARCHIVED = 'Archived',
}

interface AuditDto {
  startTime: Date;
  endTime: Date;
}

export interface CoordinatesDto {
  lat: number;
  long: number;
}

export interface DateRange {
  from: string;
  to: string;
}

export enum FormDataTypeEnum {
  SCHEDULED = 'scheduled',
  REQUEST = 'request',
  AUTOMATED = 'automated',
  MANUAL = 'manual',
}

export interface DurationValue {
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
}

export type FormValueDto = string | number | string[] | number[] | DateRange | DurationValue | FileViewDto[];

export enum IssueAnswerTypeEnum {
  GREEN = 'green',
  RED = 'red',
}

export interface FormDataValueDto {
  value?: FormValueDto;
  issues: string[];
  issueAnswerType?: IssueAnswerTypeEnum;
  issueOther?: string;
  issueOtherChecked?: boolean;
  pictures: FileViewDto[];
  comment?: string;
  scoreInNumber?: number;
  scoreInNumberTotal?: number;
}

export interface FormDataDto extends FormDataValueDto {
  elementId: string;
  // value: FormDataValueDto;
  // value: FormValueDto;
  // issues: string[];
  // issueAnswerType?: IssueAnswerTypeEnum;
  // other?: string;
  // pictures: FileViewDto[];
  // comment?: string;
}

export type FormValueCreateDto = string | number | string[] | number[] | DateRange | DurationValue | FileCreateAndUpdateDto[];

// export interface FormDataValueCreateDto {
//   value: FormValueCreateDto;
//   issues: string[];
//   pictures: FileCreateAndUpdateDto[];
//   comment?: string;
//   other?: string;
// }

export interface FormCreateDataDto {
  elementId: string;
  value: FormValueCreateDto;
  issues: string[];
  issueOther?: string;
  issueOtherChecked?: boolean;
  pictures: FileCreateAndUpdateDto[];
  comment?: string;
}

interface PageInfo {
  scoreInNumber?: number;
  scoreInNumberTotal?: number;
}

export interface FormDataViewDto extends BaseViewDto {
  type: FormDataTypeEnum;
  reportNo: number;
  template: SimpleViewWithDeletedDto;
  organisation: CommonMin;
  templateVersion: string;
  asset: AssetMin;
  location: SimpleViewWithDeletedDto;
  uniqueCode: string;
  data: FormDataDto[];
  audit: AuditDto;
  coordinates?: CoordinatesDto;
  offlineDate: Date;
  offlineCode: string;
  sendEmailReportTo: string[];
  isDemo: boolean;
  exceptions: ExceptionViewDto[];
  scoreInNumber: number;
  scoreInNumberTotal: number;
  scoreInPercentage: number;
  createdBy: UserMin;
  createdAt: string;
  updatedAt: Date;
  isDeleted: boolean;
  pageMeta: Record<string, PageInfo>;
}

export interface FormDataCreateDto {
  type: FormDataTypeEnum;
  templateId: string;
  assetId: string;
  locationId: string;
  audit: AuditDto;
  coordinates?: CoordinatesDto;
  offlineDate?: Date;
  offlineCode?: string;
  sendEmailReportTo?: string[];
  data: FormCreateDataDto[];
  timeToCompleteInSeconds: number;
  templateVersion: string;
  scheduleId?: string;
  scheduleExpectedAt?: string;
}

export interface FormDataUpdateDto extends FormDataCreateDto {
  id: string;
}
