import { Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';

import NumberRangeProvider from '../value-providers/NumberRangeProvider';

type NumberRangeFieldProps = {
  value?: number;
  min: number;
  max: number;
  onValueChange: (val: number) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
};
const NumberRangeField = (props: NumberRangeFieldProps) => {
  const [cValue, setCValue] = useState<number>(props.value ?? 1);
  const { setError, setShowError } = props;
  useEffect(() => {
    if (props.min && props.max) {
      if (cValue < props.min || cValue > props.max) {
        setError(`Value must be between ${props.min} and ${props.max}`);
      } else {
        setShowError(false);
        setError(null);
        props.onValueChange(cValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  if (!props.max || !props.min) return <Typography>No min or max value</Typography>;

  return <NumberRangeProvider min={props.min} max={props.max} value={cValue} onValueChange={(val) => setCValue(val)} />;
};

export default NumberRangeField;
