import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { ButtonBase, Divider, styled, Typography } from '@mui/material';
import React, { SetStateAction } from 'react';

import { ConstrainV2 } from '../../common/dto/filter.dto';
import { FlexColumn } from '../styled/Flex';
import { StyledLink } from '../styled/Link';

const StyledConditionButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  display: 'flex',
  width: '100%',
  textAlign: 'left',
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    background: 'rgba(0, 0, 0, 0.04)',
  },
  '& span': {
    flexGrow: 1,
  },
  // '& svg': {
  //   width: 16,
  //   height: 16,
  // },
}));

type Props = {
  options: ConstrainV2[];
  constrain: ConstrainV2;
  setConstrain: React.Dispatch<SetStateAction<ConstrainV2>> | React.Dispatch<SetStateAction<ConstrainV2 | undefined>>;
  setShowValueSection: React.Dispatch<SetStateAction<boolean>>;
  onChangeConstrain: () => void;
};
const ConstrainSelection = (props: Props) => {
  const [showOptions, setShowOptions] = React.useState(false);

  const showConstrains = () => {
    setShowOptions(true);
    props.setShowValueSection(false);
  };

  const onConstrainSelect = (item: ConstrainV2) => {
    props.setConstrain(item);
    setShowOptions(false);
    props.setShowValueSection(true);
    props.onChangeConstrain();
  };

  if (!props.constrain) return <></>;

  return (
    <>
      {!showOptions ? (
        <StyledConditionButton onClick={showConstrains} sx={{ px: 1.5, py: 1.2, flexGrow: 1 }} data-automation="select-constrain">
          <span>
            {props.constrain.title}:{props.constrain.id}
          </span>
          <ExpandCircleDownIcon sx={{ color: (theme) => theme.palette.grey[400] }} fontSize="small" />
        </StyledConditionButton>
      ) : (
        <FlexColumn alignItems="stretch" data-automation="constrain-list">
          {props.options.map((option, optionIndex) => (
            <StyledLink
              key={option.id + optionIndex}
              variant="body2"
              sx={{ textAlign: 'left', px: 1.5, py: 1.2 }}
              onClick={(e) => {
                e.stopPropagation();
                onConstrainSelect(option);
              }}
              className={option.id === props.constrain.id ? 'active' : ''}
            >
              {option.title}
            </StyledLink>
          ))}
          <Divider />
          <Typography variant="caption" sx={{ px: 1.5, py: 1.5 }}>
            Select a constraint
          </Typography>
        </FlexColumn>
      )}
    </>
  );
};

export default ConstrainSelection;
