export const stripSensitive = (args?: Record<string, any>) => {
  if (args) {
    // from interceptor
    if (args.params?.password) {
      args.params.password = '**stripped**';
    }
    if (args.params?.refresh_token) {
      args.params.refresh_token = '**stripped**-';
    }
    if (args.base64Data) {
      args.base64Data = '**stripped**-';
    }
    if (args.body?.base64Data) {
      args.body.base64Data = '**stripped**-';
    }
  }
};
