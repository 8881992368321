import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import PivotTableChartOutlinedIcon from '@mui/icons-material/PivotTableChartOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { LoadingButton } from '@mui/lab';
import { Button, ListItemIcon, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserAccess } from '../../../common/constants/access';
import { DownloadFileType, StatusDto } from '../../../common/dto/common.dto';
import { TemplateViewDto } from '../../../common/dto/template.dto';
import { CommonHelperService } from '../../../common/services/helper.service';
import { AlertMessageType } from '../../../common/types';
import ConfirmDialog, { DeleteActionType } from '../../../components/elements/dialogs/ConfirmDialog';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { manageNotification } from '../../../redux/slices/notification.slice';
import { checkMultiLocAccess } from '../../../redux/slices/userInfo.slice';
import { useDownloadNormalReportMutation } from '../../../services/download-report.service';

enum DownloadType {
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
}

type Props = {
  template: TemplateViewDto;
  setMessage: React.Dispatch<SetStateAction<AlertMessageType | undefined>>;
  selectedIds: string[];
  handleDelete: () => void;
};
const DownloadButtonContainer = (props: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('xl'), {
    defaultMatches: true,
    noSsr: false,
  });

  const navigate = useNavigate();
  const locationIds = props.template.locations.map((l) => l.id);
  const hasViewAccess = useAppSelector(checkMultiLocAccess(UserAccess.REPORT_VIEW, locationIds));
  const hasManageAccess = useAppSelector(checkMultiLocAccess(UserAccess.REPORT_MANAGE, locationIds));
  const hasDeletAccess = useAppSelector(checkMultiLocAccess(UserAccess.REPORT_DELETE, locationIds));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const downloadOpen = Boolean(anchorEl);
  const [isDownloading, setDownload] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const [downloadReport] = useDownloadNormalReportMutation();

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (type: DownloadType) => {
    props.setMessage(undefined);
    switch (type) {
      case DownloadType.PDF:
        downloadPdfReports();
        handleDownloadClose();
        break;
      case DownloadType.EXCEL:
        downloadExcelCSVReports(DownloadFileType.EXCEL);
        handleDownloadClose();
        break;
      case DownloadType.CSV:
        downloadExcelCSVReports(DownloadFileType.CSV);
        handleDownloadClose();
        break;
      default:
    }
  };

  const downloadPdfReports = () => {
    if (isDownloading) return;
    setDownload(true);
    downloadReport({ selectedIds: props.selectedIds, downloadFileType: DownloadFileType.PDF })
      .unwrap()
      .then((res: StatusDto) => {
        setDownload(false);
        if (res && res.status) {
          dispatch(manageNotification(true));
        } else {
          props.setMessage({ msg: 'Please try again later', severity: 'warning' });
        }
      })
      .catch((e) => {
        let msg = 'Unable to download PDF';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        } else if (e.data) {
          const apiError = CommonHelperService.formatApiErrorMessages(e.data?.message);
          if (apiError && Array.isArray(apiError.selectedIds)) {
            msg = apiError.selectedIds.join(', ');
          }
        }
        props.setMessage({ msg, severity: 'warning' });
        setDownload(false);
      });
  };

  const downloadExcelCSVReports = (downloadFileType: DownloadFileType) => {
    if (isDownloading) return;
    setDownload(true);

    downloadReport({ selectedIds: props.selectedIds, downloadFileType })
      .unwrap()
      .then((res: StatusDto) => {
        setDownload(false);
        if (res && res.status) {
          dispatch(manageNotification(true));
        } else {
          props.setMessage({ msg: 'Please try again later', severity: 'warning' });
        }
      })
      .catch((e) => {
        let msg = `Unable to download ${downloadFileType}`;
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        } else if (e.data) {
          const apiError = CommonHelperService.formatApiErrorMessages(e.data?.message);
          if (apiError && Array.isArray(apiError.selectedIds)) {
            msg = apiError.selectedIds.join(', ');
          }
        }
        props.setMessage({ msg, severity: 'warning' });
        setDownload(false);
      });
  };

  const onConfirmation = () => {
    setIsConfirmDialogOpen(false);
    setDeleting(false);
    props.handleDelete();
  };

  return (
    <>
      {hasManageAccess && props.template.isDeleted !== true && props.template.currentVersion && (
        <Button
          startIcon={<PostAddIcon />}
          variant="contained"
          onClick={() => navigate(`/app/reports/create/${props.template.id}`)}
          size={mediumScreen ? 'small' : 'medium'}
          data-automation="btn-add-new-report"
        >
          Submit Report
        </Button>
      )}
      {hasViewAccess && (
        <LoadingButton
          loading={isDownloading}
          loadingPosition="end"
          endIcon={<ExpandMoreOutlinedIcon />}
          variant="contained"
          id="download-button"
          aria-controls={downloadOpen ? 'download-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={downloadOpen ? 'true' : undefined}
          onClick={handleDownloadClick}
          sx={{ ml: 1, minWidth: 150, px: '12px !important' }}
          disabled={props.selectedIds.length ? false : true}
          size={mediumScreen ? 'small' : 'medium'}
          data-automation="download-button"
        >
          Download {props.selectedIds.length > 0 ? `(${props.selectedIds.length})` : ''}
        </LoadingButton>
      )}
      {hasViewAccess && (
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={downloadOpen}
          onClose={handleDownloadClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleDownload(DownloadType.PDF)} sx={{ minWidth: 180 }} data-automation="download-pdf">
            <ListItemIcon>
              <PictureAsPdfOutlinedIcon />
            </ListItemIcon>
            PDF
          </MenuItem>
          <MenuItem onClick={() => handleDownload(DownloadType.EXCEL)} data-automation="download-excel">
            <ListItemIcon>
              <PivotTableChartOutlinedIcon />
            </ListItemIcon>
            Excel
          </MenuItem>
          <MenuItem onClick={() => handleDownload(DownloadType.CSV)} data-automation="download-csv">
            <ListItemIcon>
              <ArticleOutlinedIcon />
            </ListItemIcon>
            CSV
          </MenuItem>
        </Menu>
      )}
      {hasDeletAccess && (
        <LoadingButton
          loading={isDeleting}
          loadingPosition="start"
          startIcon={<DeleteOutlineOutlinedIcon />}
          variant="contained"
          onClick={() => {
            setIsConfirmDialogOpen(true);
            setDeleting(true);
          }}
          sx={{ ml: 1 }}
          disabled={props.selectedIds.length ? false : true}
          size={mediumScreen ? 'small' : 'medium'}
          color="error"
        >
          Delete Reports
        </LoadingButton>
      )}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => {
          setIsConfirmDialogOpen(false);
          setDeleting(false);
        }}
        onConfirm={onConfirmation}
        title="Delete Reports"
        message="Delete selected Reports"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        actionType={DeleteActionType.Report}
        actionId={props.selectedIds}
      />
    </>
  );
};

export default DownloadButtonContainer;
