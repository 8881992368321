import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StepsPendingType } from '../../common/dto/auth.dto';
import { CommonMin } from '../../common/dto/common.dto';
import { SessionPublicUserAccess, UserAccesByOrganisation } from '../../common/types/user-store.types';
import { RootState } from '../store';
import { selectAdminInfo } from './admin.slice';

interface UserInfoState {
  id?: string;
  currentOrganisation?: CommonMin;
  organisations: CommonMin[];
  firstname?: string;
  lastname?: string;
  login: string;
  timezone: string;
  stepsPending: StepsPendingType[];
  access: UserAccesByOrganisation;
}

const initialState: UserInfoState = {
  id: undefined,
  currentOrganisation: undefined,
  organisations: [],
  firstname: undefined,
  lastname: undefined,
  login: '',
  timezone: 'Europe/London',
  stepsPending: [],
  access: {},
};

type UserInfoType = Pick<UserInfoState, 'id' | 'organisations' | 'firstname' | 'lastname' | 'login' | 'timezone' | 'stepsPending' | 'access'>;

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInfoType>) => {
      state.id = action.payload.id;
      state.organisations = action.payload.organisations;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.login = action.payload.login;
      state.timezone = action.payload.timezone;
      state.stepsPending = action.payload.stepsPending;
      state.currentOrganisation = action.payload.organisations.length > 0 ? action.payload.organisations[0] : undefined;
      state.access = action.payload.access;
    },
    updateProfileDetails: (state, action: PayloadAction<{ firstname?: string; lastname?: string; timezone: string }>) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.timezone = action.payload.timezone;
    },
    updateUserName: (state, action: PayloadAction<{ firstname: string; lastname: string }>) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
    },
    refreshOrganisationsAndAccess: (state, action: PayloadAction<{ organisations: CommonMin[]; allOrgAccess: UserAccesByOrganisation }>) => {
      state.organisations = action.payload.organisations;
      state.access = action.payload.allOrgAccess;
      if (state.currentOrganisation == null) {
        // if current org is null, assign first available org
        state.currentOrganisation = action.payload.organisations.length > 0 ? action.payload.organisations[0] : undefined;
      } else if (!action.payload.organisations.find((i) => i.id === state.currentOrganisation?.id)) {
        // if current org is present, but what if it was removed as part of access change, remove and assign first available org
        state.currentOrganisation = action.payload.organisations.length > 0 ? action.payload.organisations[0] : undefined;
      }
    },
    addOrganisationToSelection: (state, action: PayloadAction<{ organisation: CommonMin; access?: SessionPublicUserAccess }>) => {
      state.organisations.push(action.payload.organisation);
      if (!state.currentOrganisation) {
        state.currentOrganisation = action.payload.organisation;
      }
      if (action.payload.access) {
        state.access[action.payload.organisation.id] = action.payload.access;
      }
    },
    changeOrganisation: (state, action: PayloadAction<{ organisation: CommonMin }>) => {
      state.currentOrganisation = action.payload.organisation;
    },
    removeStepsPending: (state, action: PayloadAction<{ step: StepsPendingType }>) => {
      state.stepsPending = state.stepsPending.filter((i) => i !== action.payload.step);
    },
    updateStepsPending: (state, action: PayloadAction<StepsPendingType[]>) => {
      state.stepsPending = action.payload;
    },
    clearUserInfo: () => initialState,
    // TODO check with karan
    // clearUserInfo: (state) => {
    //   // Object.assign(state, initialState);
    // },
  },
});

export const {
  updateUserInfo,
  updateProfileDetails,
  updateUserName,
  changeOrganisation,
  removeStepsPending,
  updateStepsPending,
  clearUserInfo,
  addOrganisationToSelection,
  refreshOrganisationsAndAccess,
} = userInfoSlice.actions;

export const selectUserInfo = (state: RootState) => state.userInfo;
export const userCurrentOrganisationsSelector = createSelector(selectUserInfo, (state) => state.currentOrganisation);
// export const userOrganisationsSelector = createSelector(selectUserInfo, (state) => state.organisations.sort((o1, o2) => (o1.name > o2.name ? 1 : -1)));
export const userOrganisationsSelector = createSelector(selectUserInfo, (state) => state.organisations);
// export const getUserFullname = createSelector(selectUserInfo, (state) => {
//   return [state.firstname, state.lastname].filter((i) => i && i.trim().length > 0).join(' ');
// });

type Return = (state: RootState) => boolean;

export const checkOrgAccess = (access: string): Return =>
  createSelector([selectUserInfo, selectAdminInfo], (userState, adminState) => {
    if (adminState.administerOrganisation?.id) {
      return true;
    }
    let allAccess;
    let currentOrganisationId;
    if (adminState.impersonateUserId) {
      allAccess = adminState.access;
      currentOrganisationId = adminState.currentImpersonateUserOrganisation?.id;
    } else {
      allAccess = userState.access;
      currentOrganisationId = userState.currentOrganisation?.id;
    }
    if (currentOrganisationId && allAccess[currentOrganisationId] && allAccess[currentOrganisationId].organisationAccess.includes(access)) {
      return true;
    }
    return false;
  });

export const checkLocAccessAnywhere = (access: string): Return =>
  createSelector([selectUserInfo, selectAdminInfo], (userState, adminState) => {
    if (adminState.administerOrganisation?.id) {
      return true;
    }
    let allAccess;
    let currentOrganisationId;
    if (adminState.impersonateUserId) {
      allAccess = adminState.access;
      currentOrganisationId = adminState.currentImpersonateUserOrganisation?.id;
    } else {
      allAccess = userState.access;
      currentOrganisationId = userState.currentOrganisation?.id;
    }
    if (currentOrganisationId && allAccess[currentOrganisationId] && allAccess[currentOrganisationId].locationAccess) {
      let present = false;
      for (const locId of Object.keys(allAccess[currentOrganisationId].locationAccess)) {
        if (allAccess[currentOrganisationId].locationAccess[locId].includes(access)) {
          present = true;
        }
      }
      return present;
    }
    return false;
  });

export const checkLocAccess = (access: string, locId?: string): Return =>
  createSelector([selectUserInfo, selectAdminInfo], (userState, adminState) => {
    if (adminState.administerOrganisation?.id) {
      return true;
    }
    if (!locId) return false;
    let allAccess;
    let currentOrganisationId;
    if (adminState.impersonateUserId) {
      allAccess = adminState.access;
      currentOrganisationId = adminState.currentImpersonateUserOrganisation?.id;
    } else {
      allAccess = userState.access;
      currentOrganisationId = userState.currentOrganisation?.id;
    }

    if (currentOrganisationId && allAccess[currentOrganisationId] && allAccess[currentOrganisationId].locationAccess) {
      if (allAccess[currentOrganisationId].locationAccess[locId]) {
        return allAccess[currentOrganisationId].locationAccess[locId].includes(access);
      }
    }
    return false;
  });

export const checkMultiLocAccess = (access: string, locIds: string[]): Return =>
  createSelector([selectUserInfo, selectAdminInfo], (userState, adminState) => {
    if (adminState.administerOrganisation?.id) {
      return true;
    }
    let allAccess;
    let currentOrganisationId;
    if (adminState.impersonateUserId) {
      allAccess = adminState.access;
      currentOrganisationId = adminState.currentImpersonateUserOrganisation?.id;
    } else {
      allAccess = userState.access;
      currentOrganisationId = userState.currentOrganisation?.id;
    }
    if (currentOrganisationId && allAccess[currentOrganisationId] && allAccess[currentOrganisationId].locationAccess) {
      for (const locId of locIds) {
        if (allAccess[currentOrganisationId].locationAccess[locId]) {
          return allAccess[currentOrganisationId].locationAccess[locId].includes(access);
        }
      }
    }
    return false;
  });

export const getUserActionableStepsPending = createSelector(selectUserInfo, (state) => state.stepsPending ?? []);

export default userInfoSlice.reducer;
