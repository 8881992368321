import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FontSizes } from '../../theme/theme.context';
import { RootState } from '../store';

interface ThemeCustomizationState {
  fontSize: FontSizes;
}

const initialState: ThemeCustomizationState = {
  fontSize: 'medium',
};

const themeCustomizationSlice = createSlice({
  name: 'themeCustomizationSlice',
  initialState,
  reducers: {
    updateThemeFontSize: (state, action: PayloadAction<FontSizes>) => {
      state.fontSize = action.payload;
    },
  },
});

export const { updateThemeFontSize } = themeCustomizationSlice.actions;

export const selectThemeCustomization = (state: RootState) => state.themeCustomization;

export default themeCustomizationSlice.reducer;
