import { useMediaQuery } from '@mui/material';

const useIsLowDensity = () => {
  const matches1 = useMediaQuery('(-webkit-min-device-pixel-ratio: 1)');
  const matches2 = useMediaQuery('(-webkit-min-device-pixel-ratio: 2)');
  const matches3 = useMediaQuery('(-webkit-min-device-pixel-ratio: 3)');
  return matches2 === false;
};

export default useIsLowDensity;
