import { Box } from '@mui/material';
import { find, orderBy } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { SimpleTemplateViewDto } from '../../../common/dto/template.dto';
import { AlertMessageType } from '../../../common/types';
import DropdownPicker, { DropdownOption } from '../../../components/common/DropdownPicker';
import { LoaderHorizontal } from '../../../components/common/Loader';
import { useGetAllTemplate } from '../../../hook/useDataForSelect';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateLastReportTemplate } from '../../../redux/slices/filterSettings.slice';
import { RootState } from '../../../redux/store';

interface Props {
  getTemplate: (template: SimpleTemplateViewDto) => void;
  currentTemplate: SimpleTemplateViewDto | null;
  setMessage: Dispatch<SetStateAction<AlertMessageType | undefined>>;
  showDeleted?: boolean;
}

const TemplatePicker = (props: Props) => {
  const dispatch = useAppDispatch();
  const lastSelectedTemplate = useAppSelector((state: RootState) => state.filterSettings.report.lastReportTemplate);
  const { data: dataTemplate, isLoading: loadingTemplate, error: errorTemplate } = useGetAllTemplate({ showDeleted: props.showDeleted });
  const { setMessage, currentTemplate, getTemplate } = props;

  useEffect(() => {
    if (errorTemplate) {
      setMessage(errorTemplate);
    }
  }, [errorTemplate, setMessage]);

  useEffect(() => {
    if (!loadingTemplate && !dataTemplate?.length) {
      setMessage({ severity: 'warning', msg: 'No results' });
    }
  }, [loadingTemplate, dataTemplate, setMessage]);

  const selectTemplate = (item: DropdownOption) => {
    const template = find(dataTemplate, { id: item.id });
    if (template) {
      getTemplate(template);
      dispatch(updateLastReportTemplate({ template }));
    }
  };

  useEffect(() => {
    if (currentTemplate == null && dataTemplate && lastSelectedTemplate && lastSelectedTemplate.template) {
      const template = dataTemplate.find((t) => t.id === lastSelectedTemplate.template?.id);
      if (template) {
        getTemplate(template);
      }
    }
  }, [currentTemplate, lastSelectedTemplate, dataTemplate, getTemplate]);

  let templates: DropdownOption[] = [];
  if (dataTemplate) {
    const allTemplates = orderBy(dataTemplate, ['isDeleted', 'id'], ['asc']);
    templates = allTemplates?.map((o) => {
      const opt = {
        id: o.id,
        name: o.name,
        color: o.isDeleted ? '#ed6c02' : '#081e3f',
      };
      return opt;
    });
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" gap={1}>
      {loadingTemplate ? (
        <LoaderHorizontal />
      ) : dataTemplate != null && dataTemplate.length > 0 ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: 1 }}>
            <DropdownPicker placeholder="Select Template" name="template" options={templates} onSelected={selectTemplate} value={currentTemplate} />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default TemplatePicker;
