import { Box } from '@mui/material';

import { StyledFilterInput } from '../../styled/Input';

type Props = {
  value?: number | undefined;
  onValueChange: (val: number | undefined) => void;
};

const NumberValueProvider = (props: Props) => {
  return (
    <Box sx={{ px: 1, py: 1 }}>
      <StyledFilterInput
        autoFocus
        type="number"
        name="numericInputFilter"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        value={props.value ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          // setCurrentValue(e.target.value);
          props.onValueChange(e.target.value ? Number(e.target.value) : undefined);
        }}
      />
    </Box>
  );
};

export default NumberValueProvider;
