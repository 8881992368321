import { Typography } from '@mui/material';
import React, { useState } from 'react';

interface ShowMoreTextProps {
  text: string;
  textLimit?: number;
}

const ShowMoreText: React.FC<ShowMoreTextProps> = (props) => {
  const [showMore, manageText] = useState(false);
  const textLimit = props.textLimit ?? 100;
  const text = props.text ?? '';
  const hideContent = text.length > textLimit ? true : false;
  const value = showMore && hideContent ? text : `${text.substring(0, textLimit)}`;

  return (
    <Typography variant="body1" sx={{ p: 1, pb: 0 }}>
      <Typography variant="body1" component="span" sx={{ whiteSpace: 'pre-line' }}>
        {value}
      </Typography>
      {hideContent === true && (
        <Typography variant="caption" component="span" onClick={() => manageText(!showMore)} sx={{ cursor: 'pointer' }} color="primary">
          {showMore ? ' show less' : ' ...show more'}
        </Typography>
      )}
    </Typography>
  );
};

export default ShowMoreText;
