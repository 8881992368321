import { BaseViewDto } from './base.dto';
import { FileCreateAndUpdateDto, FileViewDto, SimpleViewDto } from './common.dto';

export interface AssetViewDto extends BaseViewDto {
  organisationId: string;
  name: string;
  type: string;
  codeType: string;
  locations: SimpleViewDto[];
  templates: SimpleViewDto[];
  uniqueCode: string;
  status: AssetStatusEnum;
  headerImages: FileViewDto[];
  currentExceptionCount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface SimpleAssetViewDto extends SimpleViewDto {
  locations: SimpleViewDto[];
  status: AssetStatusEnum;
}

export interface AssetCreateDto {
  name: string;
  type: string;
  codeType: string;
  locationIds: string[];
  status: string;
  templateIds: string[];
  uniqueCode?: string;
  headerImages: FileCreateAndUpdateDto[];
}

export interface AssetUpdateDto extends AssetCreateDto {
  id: string;
  confirmUpdate?: boolean;
}

export enum AssetStatusEnum {
  active = 'Active',
  inactive = 'Inactive',
}
