import { Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, OperatorV2 } from '../../../common/dto/filter.dto';
import { FieldType, IssueAnswerEnum, TemplateElement } from '../../../common/dto/template.dto';
import SearchableAutocompleteProvider from '../value-providers/SearchableAutocompleteProvider';

type StaticSearchFieldProps = {
  constrain?: ConstrainV2; // we need this to differentiate yes/no
  values?: SimpleViewDto[];
  element: TemplateElement;
  onValueChange: (vals: SimpleViewDto[]) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
};
const StaticSearchField = (props: StaticSearchFieldProps) => {
  const [cValue, setCValue] = useState<SimpleViewDto[]>(props.values ?? []);
  const { setError, setShowError } = props;
  useEffect(() => {
    if (cValue.length === 0) {
      setError(`Select atleast 1 option`);
    } else {
      setShowError(false);
      setError(null);
      props.onValueChange(cValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  let options: SimpleViewDto[] | undefined;
  if (props.element.fieldType === FieldType.issue) {
    if (props.constrain && props.constrain.id === OperatorV2.issuesYesNo) {
      options = [
        { id: IssueAnswerEnum.YES, name: 'Yes' },
        { id: IssueAnswerEnum.NO, name: 'No' },
      ];
    } else if (props.constrain && props.constrain.id === OperatorV2.issuesPassFail) {
      options = [
        { id: IssueAnswerEnum.PASS, name: 'Pass' },
        { id: IssueAnswerEnum.FAIL, name: 'Fail' },
      ];
    } else {
      options = props.element.config.issues?.map((i) => {
        return { id: i, name: i };
      });
    }
  } else {
    options = props.element.config.options?.map((o) => {
      return { id: o.value, name: o.title };
    });
  }

  if (!options) return <Typography>No options to list</Typography>;

  return (
    <SearchableAutocompleteProvider
      loading={false}
      values={cValue}
      options={options}
      multiple
      onValueChange={(vals) => setCValue(vals as SimpleViewDto[])}
      handleClose={props.handleClose}
    />
  );
};

export default StaticSearchField;
