import moment from 'moment';
import { useEffect, useState } from 'react';

import WithFilter, { FilterDateRangeValue, WithFilterAddedProps } from '../../../hoc/WithFilter';
import DateRangeProvider from '../value-providers/DateRangeProvider';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  tempFutureProp?: boolean;
};
const FilterDateRange = (props: Props & WithFilterAddedProps) => {
  const [value, setValue] = useState<FilterDateRangeValue>(
    props.currentValue
      ? {
          from: moment((props.currentValue as FilterDateRangeValue).from).toDate(),
          to: moment((props.currentValue as FilterDateRangeValue).to).toDate(),
        }
      : {
          from: moment().startOf('day').toDate(),
          to: moment().endOf('day').toDate(),
        },
  );

  const { setError, setFilterValue, setShowError } = props;

  useEffect(() => {
    if (value && value.from && value.to && moment(value.from).isValid() && moment(value.to).isValid()) {
      setShowError(false);
      setError(null);
      setFilterValue(value, undefined);
    } else {
      setError('Dates are invalid');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setError, setShowError]);

  return (
    <>
      {/* <ConstrainOptionSelection constrain={constrain} setConstrain={setConstrain} options={constrainOptions} setShowValueSection={props.setShowValueSection} />
      <Divider /> */}
      {props.showValueSection && (
        <DateRangeProvider
          value={value}
          onValueChange={(val) => {
            setValue(val);
          }}
        />
      )}
    </>
  );
};

export default WithFilter(FilterDateRange);
