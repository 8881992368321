import { Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { useGetAllUser } from '../../../hook/useDataForSelect';
import CrudDropdownFieldNew from '../crud-elements/CrudDropdownFieldNew';

interface Props {
  actionId?: string;
  userId?: string;
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}
const ReassignUser = (props: Props) => {
  const { data: dataUser, isLoading: isLoadingUser, error: errorUser } = useGetAllUser();
  return (
    <Grid item xs={12} sx={{ mb: 1 }}>
      <CrudDropdownFieldNew
        field="userId"
        label="User"
        placeholder="Select User"
        value={props.userId ?? ''}
        handleChange={(e) => props.setUserId(e.target.value)}
        isLoading={isLoadingUser}
        options={dataUser ? dataUser.filter((v) => v.id !== props.actionId) : []}
        handleBlur={() => {}}
      />
    </Grid>
  );
};

export default ReassignUser;
