import { Box, Grid } from '@mui/material';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { MatchType } from '../../common/dto/exception-rule.dto';
import { FilterFieldDto, FilterTypes, FilterViewDto } from '../../common/dto/filter.dto';
import { AlertMessageType } from '../../common/types';
import { FilterHelper } from '../../helper/filter.helper';
import { SearchFilterProps } from '../../hook/useSearch';
import { useDeleteFilterMutation, useGetFiltersMutation } from '../../services/filter.service';
import { createLogger } from '../../utils/logger';
import { ResponsiveHeading } from '../common/ResponsiveHeading';
import { FilterParamType, FilterRenderable } from '../filter/FilterPanel';
import FilterBarNew from './FilterBarNew';
import FilterDropdown from './FilterDropdown';

const logger = createLogger('PageHeader');

interface Props {
  title: string;
  filterType?: FilterTypes;
  templateId?: string;
  filterTitle?: string;
  filterElements?: FilterRenderable[];
  setSearchFilter?: (value: React.SetStateAction<SearchFilterProps>) => void;
  searchFilter?: SearchFilterProps;
  left?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[] | null;
  hasViewAccess: boolean;
  filterParams: Record<string, FilterParamType>;
  setFilterParams: (value: React.SetStateAction<Record<string, FilterParamType>>) => void;
  setMessage?: (value: React.SetStateAction<AlertMessageType | undefined>) => void;
}

// TODO remove New suffix
const PageHeaderNew = (props: Props) => {
  const [filter, setFilter] = useState<FilterViewDto>();
  const [filters, setFilters] = useState<FilterViewDto[]>([]);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const [getFilters] = useGetFiltersMutation();
  const [deleteFilter] = useDeleteFilterMutation();

  const handleChange = useCallback(
    (value: string, allFilters: FilterViewDto[]) => {
      let searchFilterFields: FilterFieldDto[] = [];
      let filterFields: Record<string, FilterParamType> = {};
      let selectedFilter: FilterViewDto | undefined;
      let matchType: MatchType = MatchType.ALL;
      if (value) {
        const findFilter = find(allFilters, { id: value }) as FilterViewDto;
        if (findFilter) {
          selectedFilter = findFilter;
          filterFields = FilterHelper.filterArrayToObject(findFilter.filterFields);
          searchFilterFields = FilterHelper.filterToSearchParam(filterFields);
          matchType = findFilter.matchType;
        }
      }
      setFilter(selectedFilter);
      props.setFilterParams(filterFields);
      if (props.setSearchFilter) {
        props.setSearchFilter((prev) => {
          return {
            ...prev,
            matchType,
            filterFields: searchFilterFields,
            pagination: {
              ...prev.pagination,
              page: 1,
            },
          };
        });
      }
    },
    [props],
  );

  const getData = useCallback(
    (id?: string) => {
      if (props.filterType) {
        setFilter(undefined);
        setFilterLoading(true);
        getFilters({ type: props.filterType, templateId: props.templateId })
          .unwrap()
          .then((res: FilterViewDto[]) => {
            setFilters(res);
            setFilterLoading(false);
            if (id) handleChange(id, res);
            else if (filter && !props.templateId) handleChange(filter.id as string, res);
          })
          .catch((err) => {
            setFilterLoading(false);
            if (err.status === 403 && err.data.message) {
              if (props.setMessage) props.setMessage({ severity: 'error', msg: err.data.message });
            } else if (props.setMessage) props.setMessage({ severity: 'error', msg: `Unable to retrieve data from api` });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.filterType, getFilters, filter, handleChange, props.templateId],
  );

  useEffect(() => {
    if ((props.filterType && props.filterType !== FilterTypes.Report) || (props.filterType === FilterTypes.Report && props.templateId)) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.templateId]);

  const handleDelete = async (id: string) => {
    props.setMessage?.(undefined);
    try {
      await deleteFilter(id);
      if (props.setMessage) {
        props.setMessage({ severity: 'success', msg: 'Deleted filter successfully' });
        if (props.filterType && ![FilterTypes.Report, FilterTypes.ExceptionReports].includes(props.filterType)) {
          setTimeout(() => {
            props.setMessage?.(undefined);
          }, 3000);
        }
      }
      getData();
    } catch (e) {
      if (props.setMessage) props.setMessage({ severity: 'error', msg: 'Error deleting filter' });
    }
  };

  const updateFilter = (id: string) => {
    getData(id);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container justifyContent="flex-start" alignItems="center" direction="row" flexWrap="wrap" gap={1}>
        <Grid item flexGrow={1} display="flex" alignItems="center" gap={2} flexWrap="wrap" data-automation="page-heading">
          <ResponsiveHeading>{props.title}</ResponsiveHeading>
          {props.left}
          {props.hasViewAccess && props.filterElements && props.setSearchFilter && (
            <FilterDropdown
              fullWidth
              field="filter"
              placeholder="Select Filter"
              value={filter ? (filter.id as string) : ''}
              handleChange={(e) => handleChange(e.target.value, filters)}
              isLoading={filterLoading}
              options={
                filters
                  ? filters.map((item: FilterViewDto) => {
                      return { id: item.id as string, name: item.name };
                    })
                  : []
              }
              showDelete
              handleDelete={handleDelete}
            />
          )}
        </Grid>
        <Grid item>{props.children ?? null}</Grid>
        {props.hasViewAccess && props.filterElements && props.setSearchFilter ? (
          <Grid item>
            <FilterBarNew
              filter={filter ?? null}
              templateId={props.templateId}
              updateFilter={updateFilter}
              filterType={props.filterType as FilterTypes}
              filterTitle={props.filterTitle}
              filterParams={props.filterParams}
              setFilterParams={props.setFilterParams}
              filterElements={props.filterElements}
              setSearchFilter={props.setSearchFilter}
              searchFilter={props.searchFilter as SearchFilterProps}
              resetFilter={setFilter}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default PageHeaderNew;
