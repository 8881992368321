import { Box, List, ListItem, ListItemIcon, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SimpleConfirmDialogProps {
  title: string;
  message: string | string[];
  description?: string;
  isOpen: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isSuccess?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  hideCancel?: boolean;
}

const SimpleConfirmDialog = (props: SimpleConfirmDialogProps) => {
  const theme = useTheme();
  return (
    <div>
      <Dialog
        data-automation={`confirm-dialog-${props.title.toLowerCase().replaceAll(' ', '-')}`}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            [theme.breakpoints.up('md')]: {
              minWidth: '400px',
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        }}
      >
        <DialogTitle data-automation="dialog-title">{props.title}</DialogTitle>
        <DialogContent sx={{ px: 3, py: 1 }} data-automation="confirm-dialog-exp-rule-warnings">
          {typeof props.message === 'string' ? (
            <DialogContentText id="alert-dialog-slide-message">{props.message}</DialogContentText>
          ) : (
            <List>
              {props.description && <DialogContentText id="alert-dialog-slide-description">{props.description}</DialogContentText>}
              {props.message.map((msg, index) => (
                <ListItem key={index}>
                  <ListItemIcon sx={{ minWidth: 20 }}>
                    <Box sx={{ borderRadius: 5, width: 8, height: 8, backgroundColor: '#3186f3' }} />
                  </ListItemIcon>
                  <Typography variant="body2">{msg}</Typography>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={props.onConfirm} variant="contained" size="small" color={props.isSuccess ? 'primary' : 'error'} data-automation="dialog-delete-btn">
            {props.confirmButtonText ? props.confirmButtonText : 'Confirm'}
          </Button>
          {!props.hideCancel && (
            <Button onClick={props.onClose} variant="outlined" size="small">
              {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SimpleConfirmDialog;
