import { Box, Typography } from '@mui/material';

import { DurationValue } from '../../../common/dto/formdata.dto';
import { DurationElementDisplayFormatEnum, ElementDurationTypeEnum } from '../../../common/dto/template.dto';
import { ViewElementProps } from '../../../pages/reports/components/FormElements';

export const formatDurationValue = (value: DurationValue, format?: DurationElementDisplayFormatEnum): string => {
  let formattedValue = '';
  if (value) {
    const dTypes = Object.values(ElementDurationTypeEnum);
    switch (format) {
      case DurationElementDisplayFormatEnum.FORMAT_1:
        formattedValue = '';
        for (const key of dTypes) {
          if (value[key] != null) {
            formattedValue += `${value[key]} ${key}, `;
          }
        }
        formattedValue = formattedValue.replace(/,\s*$/, '');
        break;
      case DurationElementDisplayFormatEnum.FORMAT_2:
        formattedValue = '';
        for (const key of dTypes) {
          if (value[key] != null) {
            formattedValue += `${value[key]}${key.charAt(0).toLowerCase()}, `;
          }
        }
        formattedValue = formattedValue.replace(/,\s*$/, '');
        break;
      case DurationElementDisplayFormatEnum.FORMAT_3:
        formattedValue = '';
        for (const key of dTypes) {
          if (value[key] != null) {
            formattedValue += `${value[key]}:`;
          }
        }
        formattedValue = formattedValue.replace(/:\s*$/, '');
        break;
      case DurationElementDisplayFormatEnum.FORMAT_4:
        formattedValue = '';
        for (const key of dTypes) {
          if (value[key] != null) {
            formattedValue += `${value[key]}-`;
          }
        }
        formattedValue = formattedValue.replace(/-\s*$/, '');
        break;
      case DurationElementDisplayFormatEnum.FORMAT_5:
        formattedValue = '';
        for (const key of dTypes) {
          if (value[key] != null) {
            formattedValue += `${value[key]}${key === 'day' ? '-' : ':'}`;
          }
        }
        formattedValue = formattedValue.replace(/:\s*$/, '');
        formattedValue = formattedValue.replace(/-\s*$/, '');
        break;
      default:
        break;
    }
  }
  return formattedValue;
};

const ViewDurationElement = (props: ViewElementProps) => {
  const value = (props.elementValue.value ?? undefined) as DurationValue | undefined;
  const formattedValue = value && props.element.config.duration?.displayFormat ? formatDurationValue(value, props.element.config.duration?.displayFormat) : '';
  return (
    <Box>
      {formattedValue != null && formattedValue !== '' && props.element.config.duration?.displayFormat != null ? (
        <>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Response:
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {formattedValue}
          </Typography>
        </>
      ) : (
        <Typography variant="body2" color="#6d768e">
          No Response
        </Typography>
      )}
    </Box>
  );
};
export default ViewDurationElement;
