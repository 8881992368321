import loadable from '@loadable/component';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Navigate, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import RootErrorBoundary from './components/error-boundaries/RootErrorBoundary';
import useIsLowDensity from './hook/useIsLowDensity';
import AdminLayout from './layouts/AdminLayout';
import BaseLayout from './layouts/AppLayout';
import AuthLayout from './layouts/AuthLayout';
import NotFound from './pages/NotFound';
import Reports from './pages/reports';
import { useAppSelector } from './redux/hooks';
import { selectThemeCustomization } from './redux/slices/themeCustomization.slice';
import { persistor, store } from './redux/store';
import { createCustomizedTheme } from './theme/theme';

const Login = loadable(() => import('./pages/auth/Login'));
const Logout = loadable(() => import('./pages/auth/Logout'));
const ForgotPassword = loadable(() => import('./pages/auth/ForgotPassword'));
const ResetPassword = loadable(() => import('./pages/auth/ResetPassword'));
const CompleteEmailAccountSetup = loadable(() => import('./pages/auth/CompleteEmailAccountSetup'));
const CompleteProfile = loadable(() => import('./pages/auth/CompleteProfile'));
const AcceptInvite = loadable(() => import('./pages/auth/AcceptInvite'));
const MfaSetup = loadable(() => import('./pages/auth/MfaSetup'));
const MfaVerify = loadable(() => import('./pages/auth/MfaVerify'));

const Dashboard = loadable(() => import('./pages/dashboard'));
const Users = loadable(() => import('./pages/users'));
const Authorities = loadable(() => import('./pages/authorities'));
const Location = loadable(() => import('./pages/locations'));
const Assets = loadable(() => import('./pages/assets'));
const ReportEdit = loadable(() => import('./pages/reports/edit'));
const ReportCreate = loadable(() => import('./pages/reports/create'));
const ReportView = loadable(() => import('./pages/reports/view'));
const ExceptionReports = loadable(() => import('./pages/exception-reports'));
const Schedules = loadable(() => import('./pages/schedules'));
const ExceptionRules = loadable(() => import('./pages/exception-rules'));
const Admin = loadable(() => import('./pages/admin'));
const Templates = loadable(() => import('./pages/templates'));
const AddEditTemplate = loadable(() => import('./pages/templates/AddEditTemplate'));
const TemplateBuilder = loadable(() => import('./pages/template-builder'));
const MyAccount = loadable(() => import('./pages/my-account'));
const AllComponents = loadable(() => import('./pages/ui-dev/AllComponents'));

const ImpersonateUser = loadable(() => import('./pages/impersonate-user'));
const OrganisationAdminister = loadable(() => import('./pages/organisation-administer'));

Sentry.init({
  release: import.meta.env.VITE_REACT_APP_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: false,
  enabled: ['dev', 'uat', 'prod'].includes(import.meta.env.VITE_APP_ENVIRONMENT),
  // enabled: true,
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  normalizeDepth: 100, // primary due to userInfo.currentOrganisations
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations:
    import.meta.env.VITE_APP_ENVIRONMENT === 'local'
      ? []
      : [
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
          new Sentry.Integrations.Breadcrumbs({
            console: false,
            fetch: true,
          }),
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
          }),
        ],
  // tracesSampleRate: 1.0,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  return (
    <SentryRoutes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:key" element={<ResetPassword />} />
        <Route path="complete-account-setup/:key" element={<CompleteEmailAccountSetup />} />
        <Route path="complete-profile" element={<CompleteProfile />} />
        <Route path="accept-invite" element={<AcceptInvite />} />
        <Route path="mfa-setup" element={<MfaSetup />} />
        <Route path="mfa-verify" element={<MfaVerify />} />
        {import.meta.env.VITE_APP_ENVIRONMENT !== 'prod' && <Route path="ui" element={<AllComponents />} />}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/app" element={<BaseLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="authorities" element={<Authorities />} />
        <Route path="locations" element={<Location />} />
        <Route path="assets" element={<Assets />} />
        <Route path="reports" element={<Reports />} />
        <Route path="reports/edit/:id" element={<ReportEdit />} />
        <Route path="reports/view/:id" element={<ReportView />} />
        <Route path="reports/create/:templateId" element={<ReportCreate />} />
        <Route path="exception-reports" element={<ExceptionReports />} />
        <Route path="schedules" element={<Schedules />} />
        <Route path="exception-rules" element={<ExceptionRules />} />
        <Route path="admin" element={<Admin />} />
        <Route path="templates" element={<Templates />} />
        <Route path="templates/:id" element={<AddEditTemplate />} />
        <Route path="templates/template-builder/:id" element={<TemplateBuilder />} />
        <Route path="my-account" element={<MyAccount />} />
        {import.meta.env.VITE_APP_ENVIRONMENT !== 'prod' && <Route path="ui" element={<AllComponents />} />}
        <Route path="" element={<Navigate to="/app/dashboard" />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="" element={<Navigate to="/app/dashboard" />} />

      <Route path="/admin" element={<AdminLayout />}>
        <Route path="impersonate-user/:userId" element={<ImpersonateUser />} />
        <Route path="organisation-administer/:organisationId" element={<OrganisationAdminister />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  );
};

const WrappedApp = () => {
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    Sentry.captureException(error, { extra: { componentStack: info.componentStack } });
  };

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={RootErrorBoundary} onError={myErrorHandler}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemedApp />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default WrappedApp;

const ThemedApp = () => {
  const themeData = useAppSelector(selectThemeCustomization);

  const ldpi = useIsLowDensity();

  return (
    <ThemeProvider theme={createCustomizedTheme(themeData.fontSize, ldpi)}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};
