import moment from 'moment-timezone';
import React from 'react';

import { useAppSelector } from '../../redux/hooks';

interface Props {
  date: Date | string;
  format?: string;
}

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm a';

const FormatDate: React.FC<Props> = ({ date, format = DATE_TIME_FORMAT }: Props) => {
  const timezone = useAppSelector((state) => state.userInfo.timezone);
  const momentdate = moment(date);
  if (date && momentdate.isValid()) {
    return <>{momentdate.tz(timezone).format(format)}</>;
  }
  return <>{date ?? ''}</>;
};

export default FormatDate;
