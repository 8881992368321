import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface TemplateBuilderState {
  activeElementId?: string;
  activeElementIndex?: number;
  activePageIndex?: number;

  expandedPages: boolean[];
  totalPages: number; // needed for expand collapse fn
}

const initialState: TemplateBuilderState = {
  expandedPages: [],
  totalPages: 0,
};

const templateBuilderSlice = createSlice({
  name: 'templateBuilder',
  initialState,
  reducers: {
    setTotalPagesAction: (state, action: PayloadAction<{ total: number }>) => {
      state.totalPages = action.payload.total;
    },
    togglePageExpandedAction: (state, action: PayloadAction<{ pageIndex: number }>) => {
      if (state.expandedPages[action.payload.pageIndex] === undefined) {
        state.expandedPages[action.payload.pageIndex] = false;
      } else if (!state.expandedPages[action.payload.pageIndex]) {
        state.expandedPages[action.payload.pageIndex] = true;
      } else {
        state.expandedPages[action.payload.pageIndex] = false;
      }
    },
    toggleAllPageExpandedAction: (state, action: PayloadAction<{ open: boolean }>) => {
      for (let i = 0; i < state.totalPages; i++) {
        state.expandedPages[i] = action.payload.open;
      }
    },
    setActiveElementWithIndexesAction: (state, action: PayloadAction<{ elementId: string; elementIndex?: number; pageIndex?: number }>) => {
      state.activeElementId = action.payload.elementId;
      state.activeElementIndex = action.payload.elementIndex;
      state.activePageIndex = action.payload.pageIndex;
    },
    checkAndResetActiveElementOnDeleteAction: (state, action: PayloadAction<{ pageIndex: number; elementIndex?: number }>) => {
      if (
        action.payload.elementIndex !== undefined &&
        state.activePageIndex === action.payload.pageIndex &&
        state.activeElementIndex === action.payload.elementIndex
      ) {
        state.activeElementId = undefined;
        state.activePageIndex = undefined;
        state.activeElementIndex = undefined;
      } else if (state.activePageIndex === action.payload.pageIndex) {
        state.activeElementId = undefined;
        state.activePageIndex = undefined;
        state.activeElementIndex = undefined;
      }
    },
    resetActiveElementAction: (state) => {
      state.activeElementId = undefined;
      state.activeElementIndex = undefined;
      state.activePageIndex = undefined;
    },
    clearTemplateBuilderStateAction: () => initialState,
  },
});

export const {
  setTotalPagesAction,
  setActiveElementWithIndexesAction,
  resetActiveElementAction,
  checkAndResetActiveElementOnDeleteAction,
  togglePageExpandedAction,
  toggleAllPageExpandedAction,
  clearTemplateBuilderStateAction,
} = templateBuilderSlice.actions;

export default templateBuilderSlice.reducer;

const stateSelf = (state: RootState) => state.templateBuilder;
export const activeElementIdSelector = createSelector(stateSelf, (state) => state.activeElementId);

export const isPageExpandedSelector = (pageIndex: number) =>
  createSelector([stateSelf], (state) => {
    return state.expandedPages[pageIndex] ?? true;
  });
// export const isActiveElementSelector = (elementId: string) =>
//   createSelector([stateSelf], (state) => {
//     return state.activeElementId === elementId;
//   });
