import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { FlexRow } from '../styled/Flex';

interface Props {
  msg?: string;
}

export const LoaderVertical = (props: Props) => {
  let loadingMsg = 'Loading, please wait';
  if (props.msg) loadingMsg = props.msg;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ padding: 2 }} data-automation="vertical-loader">
      <CircularProgress />

      <Typography variant="body1" sx={{ paddingTop: 2 }}>
        {loadingMsg}
      </Typography>
    </Box>
  );
};

export const LoaderHorizontal = (props: Props) => {
  let loadingMsg = 'Loading, please wait';
  if (props.msg) loadingMsg = props.msg;

  return (
    <FlexRow alignItems="center" justifyItems="center" gap={1} data-automation="horizontal-loader">
      <CircularProgress size={20} />

      <Typography variant="body1">{loadingMsg}</Typography>
    </FlexRow>
  );
};
