import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { UserMin } from '../../common/dto/common.dto';
import { FlexRow } from '../styled/Flex';

interface Props {
  user: UserMin;
}

export const UserAvatar = (props: Props) => {
  const [avatarChar, setAvatarChar] = useState<string | null>(null);
  const [display, setDisplay] = useState<string | null>(null);

  const { firstname, lastname, login } = props.user;

  useEffect(() => {
    if (firstname && lastname) {
      const parts = [];
      if (firstname && firstname.trim().length) parts.push(firstname);
      if (lastname && lastname.trim().length) parts.push(lastname);
      const chars = parts.map((i) => i.charAt(0).toUpperCase()).join('');
      setAvatarChar(chars);
      setDisplay(`${firstname} ${lastname}`);
    } else {
      setAvatarChar(login.charAt(0));
      setDisplay(login);
    }
  }, [firstname, lastname, login]);

  return (
    <FlexRow gap={0.8} alignItems="center">
      <Box>
        <Avatar
          sx={{
            bgcolor: 'white',
            color: '#3186f3',
            width: 32,
            height: 32,
            fontWeight: 'bold',
            border: (theme) => `solid 1px ${theme.palette.grey[400]}`,
          }}
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          {avatarChar}
        </Avatar>
      </Box>
      <FlexRow>
        <Typography variant="body1">{display}</Typography>
      </FlexRow>
    </FlexRow>
  );
};
