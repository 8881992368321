import moment from 'moment';

import { FilterFieldDataType, FilterFieldDto, FilterFields, FilterFieldValueTypes } from '../common/dto/filter.dto';
import { FilterParamType } from '../components/filter/FilterPanel';
import { FilterDateRangeValue, FilterTimeRangeValue } from '../hoc/WithFilter';

export class FilterHelper {
  public static filterToSearchParam = (vals: Record<string, FilterParamType>): FilterFieldDto[] => {
    const filterFields: FilterFieldDto[] = [];

    for (const key of Object.keys(vals)) {
      const fieldParams = vals[key];
      if (fieldParams.constrain || fieldParams.value) {
        let value: FilterFieldValueTypes | undefined;
        if (fieldParams.fieldDataType === FilterFieldDataType.string) {
          value = fieldParams.value as string;
        } else if (fieldParams.fieldDataType === FilterFieldDataType.number) {
          value = fieldParams.value as number;
        } else if ([FilterFieldDataType.date, FilterFieldDataType.dateRange].includes(fieldParams.fieldDataType)) {
          value = {
            from: moment((fieldParams.value as FilterDateRangeValue).from).toISOString(),
            to: moment((fieldParams.value as FilterDateRangeValue).to).toISOString(),
          };
        } else if ([FilterFieldDataType.time, FilterFieldDataType.timeRange].includes(fieldParams.fieldDataType)) {
          value = {
            from: moment((fieldParams.value as FilterTimeRangeValue).from).toISOString(),
            to: moment((fieldParams.value as FilterTimeRangeValue).to).toISOString(),
          };
        } else if (fieldParams.fieldDataType === FilterFieldDataType.options || fieldParams.fieldDataType === FilterFieldDataType.issues) {
          if (Array.isArray(fieldParams.value)) {
            value = fieldParams.value?.map((i) => i.id);
          } else if (typeof fieldParams.value === 'string' || typeof fieldParams.value === 'number') {
            value = fieldParams.value;
          } else if (typeof fieldParams.value === 'object' && fieldParams.value && 'id' in fieldParams.value) {
            value = fieldParams.value.id;
          }
        }

        let fieldName = key;
        if (fieldParams.fieldSuffixMeta && fieldParams.constrain && fieldParams.fieldSuffixMeta[fieldParams.constrain.id]) {
          fieldName = `${fieldParams.fieldSuffixMeta[fieldParams.constrain.id]}`;
        }

        filterFields.push({
          field: fieldName,
          dataType: fieldParams.fieldDataType,
          operator: fieldParams.constrain?.id,
          value,
        });
      }
    }

    return filterFields;
  };
  public static filterArrayToObject = (vals: FilterFields[]): Record<string, FilterParamType> => {
    const filterFields: Record<string, FilterParamType> = {};
    vals.forEach((field) => {
      filterFields[field.field] = {
        fieldDataType: field.data.fieldDataType,
        constrain: field.data.constrain,
        value: field.data.value,
        fieldSuffixMeta: field.data.fieldSuffixMeta,
      };
    });
    return filterFields;
  };
}
