import { ConstrainV2, FilterFieldDataType, OperatorV2 } from '../dto/filter.dto';

export const findConstrainById = (constrain: OperatorV2): ConstrainV2 => {
  switch (constrain) {
    case OperatorV2.issuesYesNo:
      return { id: OperatorV2.issuesYesNo, title: 'Yes / No', applicableDataTypes: [FilterFieldDataType.issues] };
    case OperatorV2.issuesPassFail:
      return { id: OperatorV2.issuesPassFail, title: 'Pass / Fail', applicableDataTypes: [FilterFieldDataType.issues] };
    default:
      return ConstrainListV2.find((c) => c.id === constrain) as ConstrainV2;
  }
};

export const ConstrainListV2: ConstrainV2[] = [
  {
    id: OperatorV2.strIsEmail,
    title: 'Is Email',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.equals,
    title: 'Equals',
    applicableDataTypes: [FilterFieldDataType.string, FilterFieldDataType.number, FilterFieldDataType.customPicker],
  },
  {
    id: OperatorV2.notEquals,
    title: 'Not Equals',
    applicableDataTypes: [FilterFieldDataType.string, FilterFieldDataType.number, FilterFieldDataType.customPicker],
  },
  {
    id: OperatorV2.isEmpty,
    title: 'Is Empty',
    applicableDataTypes: [FilterFieldDataType.string, FilterFieldDataType.number, FilterFieldDataType.customPicker],
  },
  {
    id: OperatorV2.isNotEmpty,
    title: 'Is Not Empty',
    applicableDataTypes: [FilterFieldDataType.string, FilterFieldDataType.number, FilterFieldDataType.customPicker],
  },
  {
    id: OperatorV2.strContains,
    title: 'Contains',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.strNotContains,
    title: 'Not Contains',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.strStartsWith,
    title: 'Starts with',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.strEndsWith,
    title: 'Ends with',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.strLengthMin,
    title: 'Min Length',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.strLengthMax,
    title: 'Max Length',
    applicableDataTypes: [FilterFieldDataType.string],
  },
  {
    id: OperatorV2.numberGreaterThan,
    title: 'Greater than',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberGreaterThanOrEqualTo,
    title: 'Greater than or Equal',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberLessThan,
    title: 'Less than',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberLessThanOrEqualTo,
    title: 'Less than or Equal',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberMin,
    title: 'Min',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberMax,
    title: 'Max',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.numberDivisiblyBy,
    title: 'Divisible by',
    applicableDataTypes: [FilterFieldDataType.number],
  },
  {
    id: OperatorV2.optionsContains,
    title: 'Contains',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsNotContains,
    title: 'Not Contains',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsEquals,
    title: 'Equals',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsNotEquals,
    title: 'Not Equals',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsStartsWith,
    title: 'Starts with',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsEndsWith,
    title: 'Ends with',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsLenMin,
    title: 'Min',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.optionsLenMax,
    title: 'Max',
    applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
  },
  {
    id: OperatorV2.datetimeIsCustomDaysBefore,
    title: 'More than X days before',
    applicableDataTypes: [FilterFieldDataType.datetime, FilterFieldDataType.date],
  },
  // {
  //   id: OperatorV2.datetimeIsCustomDaysBeforeOrEqual,
  //   title: 'X days before or Equals',
  //   applicableDataTypes: [FilterFieldDataType.datetime, FilterFieldDataType.date],
  // },
  {
    id: OperatorV2.datetimeIsCustomDaysAfter,
    title: 'More than X days after',
    applicableDataTypes: [FilterFieldDataType.datetime, FilterFieldDataType.date],
  },
  // {
  //   id: OperatorV2.datetimeIsCustomDaysAfterOrEqual,
  //   title: 'X days after or Equals',
  //   applicableDataTypes: [FilterFieldDataType.datetime, FilterFieldDataType.date],
  // },
  // {
  //   id: OperatorV2.dateTimeEquals,
  //   title: 'Date Time Equals',
  //   applicableDataTypes: [FilterFieldDataType.datetime],
  // },
  {
    id: OperatorV2.dateTimeBefore,
    title: 'Date Time Before',
    applicableDataTypes: [FilterFieldDataType.datetime],
  },
  {
    id: OperatorV2.dateTimeBeforeOrEqual,
    title: 'Date Time Before or Equals',
    applicableDataTypes: [FilterFieldDataType.datetime],
  },
  {
    id: OperatorV2.dateTimeAfter,
    title: 'Date Time After',
    applicableDataTypes: [FilterFieldDataType.datetime],
  },
  {
    id: OperatorV2.dateTimeAfterOrEqual,
    title: 'Date Time After or Equals',
    applicableDataTypes: [FilterFieldDataType.datetime],
  },
  {
    id: OperatorV2.dateEquals,
    title: 'Date Equals',
    applicableDataTypes: [FilterFieldDataType.date],
  },
  {
    id: OperatorV2.dateBefore,
    title: 'Date Before',
    applicableDataTypes: [FilterFieldDataType.date],
  },
  {
    id: OperatorV2.dateBeforeOrEqual,
    title: 'Date Before or Equals',
    applicableDataTypes: [FilterFieldDataType.date],
  },
  {
    id: OperatorV2.dateAfter,
    title: 'Date After',
    applicableDataTypes: [FilterFieldDataType.date],
  },
  {
    id: OperatorV2.dateAfterOrEqual,
    title: 'Date After or Equals',
    applicableDataTypes: [FilterFieldDataType.date],
  },
  {
    id: OperatorV2.timeEquals,
    title: 'Time Equals',
    applicableDataTypes: [FilterFieldDataType.time],
  },
  {
    id: OperatorV2.timeBefore,
    title: 'Time Before',
    applicableDataTypes: [FilterFieldDataType.time],
  },
  {
    id: OperatorV2.timeBeforeOrEqual,
    title: 'Time Before or Equals',
    applicableDataTypes: [FilterFieldDataType.time],
  },
  {
    id: OperatorV2.timeAfter,
    title: 'Time After',
    applicableDataTypes: [FilterFieldDataType.time],
  },
  {
    id: OperatorV2.timeAfterOrEqual,
    title: 'Time After or Equals',
    applicableDataTypes: [FilterFieldDataType.time],
  },
];
