import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, CircularProgress, InputAdornment, styled, useTheme } from '@mui/material';
import Autocomplete, { autocompleteClasses, AutocompleteCloseReason } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import React from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';

type Props = {
  values?: SimpleViewDto[] | SimpleViewDto;
  options: SimpleViewDto[];
  onValueChange: (vals?: SimpleViewDto[] | SimpleViewDto | null) => void;
  handleClose: () => void;
  // selectedOptions: SimpleViewDto[];
  // setSelectedOptions
  loading: boolean;
  multiple?: boolean;
};

const SearchableAutocompleteProvider = (props: Props) => {
  const [valueForSorting, setValue] = React.useState<SimpleViewDto[] | SimpleViewDto | undefined>(props.values ?? undefined);
  // const [pendingValue, setPendingValue] = React.useState<SimpleViewDto[]>([]);
  const theme = useTheme();

  const [searchText, setSearchText] = React.useState('');

  const onClose = () => {
    // setValue(pendingValue);
    setSearchText('');
    // props.onValueChange(pendingValue);
    props.handleClose();
  };

  let currentValue: SimpleViewDto[] | SimpleViewDto | null;
  if (props.multiple) currentValue = (props.values as SimpleViewDto[]) ?? [];
  else if (props.values) currentValue = props.values as SimpleViewDto;
  else currentValue = null;

  return (
    <Autocomplete
      open
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: 0,
        },
      }}
      //   ref={autoCompleteRef}
      multiple={props.multiple}
      onClose={(event: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
        if (reason === 'escape') {
          onClose();
        }
      }}
      value={currentValue}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onChange={(event, newValue, reason) => {
        if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Backspace' && reason === 'removeOption') {
          return;
        }
        // setPendingValue(newValue);
        props.onValueChange(newValue);
      }}
      disableCloseOnSelect
      PopperComponent={PopperComponent}
      loading={props.loading}
      renderTags={() => null}
      noOptionsText="No options"
      inputValue={searchText}
      data-automation={props.multiple ? 'multiSelect' : 'singleSelect'}
      id="filterOptions"
      renderOption={(cprops, option, { selected }) => (
        <li {...cprops}>
          <Box
            component={DoneIcon}
            sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
            style={{
              visibility: selected ? 'visible' : 'hidden',
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              '& span': {
                color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
              },
            }}
          >
            {option.name}
            {/* <br />
            <span>additional value</span> */}
          </Box>
          <Box
            component={CloseIcon}
            sx={{ opacity: 0.6, width: 18, height: 18 }}
            style={{
              visibility: selected ? 'visible' : 'hidden',
            }}
          />
        </li>
      )}
      options={
        props.multiple
          ? [...props.options].sort((a, b) => {
              // Display the selected labels first.
              let ai = (valueForSorting as SimpleViewDto[]).map((i) => i.id).indexOf(a.id);
              ai = ai === -1 ? (valueForSorting as SimpleViewDto[]).length + props.options.map((i) => i.id).indexOf(a.id) : ai;
              let bi = (valueForSorting as SimpleViewDto[]).map((i) => i.id).indexOf(b.id);
              bi = bi === -1 ? (valueForSorting as SimpleViewDto[]).length + props.options.map((i) => i.id).indexOf(b.id) : bi;
              return ai - bi;
            })
          : [...props.options]
      }
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <StyledInputContainer>
          <StyledInput
            autoFocus
            ref={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
            }}
            endAdornment={
              props.loading ? (
                <InputAdornment position="end">
                  <CircularProgress color="inherit" size={20} />
                </InputAdornment>
              ) : null
            }
            placeholder="Search options"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </StyledInputContainer>
      )}
    />
  );
};

export default SearchableAutocompleteProvider;

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    top: '28px',
    right: '20px',
  },
  //   display: 'flex',
  //   flexDirection: 'column',
  //   width: '100%',
  //   borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  //   borderRadius: 4,
  //   backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
  //   padding: 8,
  //   transition: theme.transitions.create(['border-color', 'box-shadow']),
  //   border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  //   fontSize: 14,
  //   flex-direction: column,
  //   box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.22),
  //   border: 1px solid ${(props) => (props.error ? props.theme.palette.error.dark : '#d8e0f0')},
  //   border-radius: 14px,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '&.MuiInputBase-root': {
    borderRadius: 0,
  },
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: '8px 32px 8px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'}`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));
