export enum NotificationStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
  REQUESTED = 'requested',
  IN_PROGRESS = 'inprogress',
}

export enum NotificationReadEnum {
  UNREAD = 'unread',
  READ = 'read',
}

export enum NotificationTypeEnum {
  EXCEPTION = 'Exception',
  REMINDER = 'Reminder',
  DOWNLOAD = 'Download',
}

export interface NotificationViewDto {
  id: string;
  msg: string;
  type: NotificationTypeEnum;
  read: NotificationReadEnum;
  status: NotificationStatusEnum;
  actionId: string;
  signedUrl?: string;
  error?: string;
  createdAt: string;
  updatedAt: string;
}
