import { SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  noWrap?: boolean;
}

export const ResponsiveHeading = ({ sx, children, noWrap }: Props) => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  return (
    <Typography variant={lgUp ? 'h1' : 'h2'} sx={sx} noWrap={noWrap} data-automation="heading">
      {children}
    </Typography>
  );
};
