import { Box } from '@mui/material';

import useIsLowDensity from '../../hook/useIsLowDensity';

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const FormSectionBox = (props: Props) => {
  const ldpi = useIsLowDensity();

  return (
    <Box
      sx={{
        p: ldpi ? 3 : 2,
        border: 'solid 1px #e0e0e0',
        backgroundColor: '#f5f7f9',
        borderRadius: 2,
      }}
    >
      {props.children}
    </Box>
  );
};

export default FormSectionBox;
