import { Typography } from '@mui/material';
import { SetStateAction } from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, OperatorV2 } from '../../../common/dto/filter.dto';
import { FieldType, TemplateElement, ValueDataType } from '../../../common/dto/template.dto';
import { FilterDateRangeValue, FilterTimeRangeValue, FilterValueTypes } from '../../../hoc/WithFilter';
import DateRangeField from './DateRange';
import NumberField from './NumberField';
import NumberRangeField from './NumberRangeField';
import StaticSearchField from './StaticSearchField';
import StringField from './StringField';
import TimeRangeField from './TimeRange';
import YesNoSelect from './YesNoSelect';

type FieldToShowType = 'numberRange' | 'dateRange' | 'searchableAutocomplete' | 'string' | 'number' | 'timeRange' | 'yesNoSelect';

type ValueSelectionProps = {
  constrain?: ConstrainV2;
  value?: FilterValueTypes;
  setValue: React.Dispatch<SetStateAction<FilterValueTypes>>;
  element: TemplateElement;

  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  // options: SimpleViewDto[];
  // selectedConstrain: Constrain;
  // selectedOptions: SimpleViewDto[];
  // setSelectedOptions
  // loading: boolean;
  // isMultiple?: boolean;
};

const FilterTemplateValueSelection = (props: ValueSelectionProps) => {
  let fieldToShow: FieldToShowType | undefined;

  if (
    [ValueDataType.string, ValueDataType.numeric].includes(props.element.valueDataType) &&
    props.constrain &&
    [OperatorV2.strIsEmail, OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(props.constrain.id)
  ) {
    fieldToShow = 'yesNoSelect';
  } else if ([FieldType.singleLine, FieldType.multiLine, FieldType.customPicker].includes(props.element.fieldType)) {
    if (props.constrain && [OperatorV2.strLengthMin, OperatorV2.strLengthMax].includes(props.constrain.id)) {
      fieldToShow = 'number';
    } else {
      fieldToShow = 'string';
    }
  } else if ([FieldType.numeric, FieldType.duration].includes(props.element.fieldType)) {
    fieldToShow = 'number';
  } else if ([FieldType.datetime].includes(props.element.fieldType)) {
    if (!props.element.config.date && props.element.config.time) {
      fieldToShow = 'timeRange';
    } else {
      fieldToShow = 'dateRange';
    }
  } else if ([FieldType.oilLevel, FieldType.temperature, FieldType.fuel].includes(props.element.fieldType)) {
    fieldToShow = 'numberRange';
  } else if ([FieldType.checkbox, FieldType.radio, FieldType.dropdown].includes(props.element.fieldType)) {
    if (props.constrain && [OperatorV2.optionsLenMin, OperatorV2.optionsLenMax].includes(props.constrain.id)) {
      fieldToShow = 'numberRange';
    } else if (props.constrain && [OperatorV2.optionsEquals, OperatorV2.optionsNotEquals].includes(props.constrain.id)) {
      fieldToShow = 'searchableAutocomplete';
    } else {
      fieldToShow = 'string';
    }
    // } else if (ELEMENTS_HAVING_OPTIONS_TO_LIST.includes(props.element.fieldType)) {
    //   fieldToShow = 'searchableAutocomplete';
  } else if (props.element.fieldType === FieldType.issue) {
    if (props.constrain) {
      if ([OperatorV2.optionsLenMin, OperatorV2.optionsLenMax].includes(props.constrain.id)) {
        fieldToShow = 'numberRange';
      } else if ([OperatorV2.optionsEquals, OperatorV2.optionsNotEquals, OperatorV2.issuesYesNo, OperatorV2.issuesPassFail].includes(props.constrain.id)) {
        fieldToShow = 'searchableAutocomplete';
      } else if (
        [OperatorV2.optionsStartsWith, OperatorV2.optionsEndsWith, OperatorV2.optionsContains, OperatorV2.optionsNotContains].includes(props.constrain.id)
      ) {
        fieldToShow = 'string';
      }
    } else throw new Error('Value selection logic for Issue element failed');
  } else {
    console.log('No matches to show value control, hiding');
  }

  let min = 1;
  let max = 999;
  if (fieldToShow === 'numberRange') {
    if (
      [FieldType.oilLevel, FieldType.fuel, FieldType.temperature].includes(props.element.fieldType) &&
      props.element.config.startValue &&
      props.element.config.endValue
    ) {
      min = props.element.config.startValue;
      max = props.element.config.endValue;
    } else if ([FieldType.checkbox, FieldType.radio, FieldType.dropdown].includes(props.element.fieldType) && props.element.config.options) {
      min = 1;
      max = props.element.config.options?.length;
    } else if (props.element.fieldType === FieldType.issue && props.element.config.issues) {
      min = 1;
      max = props.element.config.issues?.length;
    }
  }

  if (!fieldToShow) return <></>;

  return (
    <>
      {fieldToShow === 'yesNoSelect' && (
        <YesNoSelect
          value={props.value}
          onValueChange={(vals) => props.setValue(vals as boolean)}
          setError={props.setError}
          setShowError={props.setShowError}
          handleClose={props.handleClose}
        />
      )}
      {fieldToShow === 'searchableAutocomplete' && (
        <StaticSearchField
          constrain={props.constrain}
          values={props.value && Array.isArray(props.value) ? (props.value as SimpleViewDto[]) : []}
          element={props.element}
          onValueChange={(val) => props.setValue(val as FilterValueTypes)}
          setError={props.setError}
          setShowError={props.setShowError}
          handleClose={props.handleClose}
        />
      )}
      {fieldToShow === 'dateRange' && (
        <DateRangeField
          value={props.value && typeof props.value === 'object' ? (props.value as FilterDateRangeValue) : undefined}
          onValueChange={(val) => props.setValue(val as FilterValueTypes)}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
      {fieldToShow === 'numberRange' && (
        <NumberRangeField
          value={props.value && typeof props.value === 'number' ? Number(props.value) : undefined}
          min={min}
          max={max}
          onValueChange={(val) => props.setValue(val as FilterValueTypes)}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
      {fieldToShow === 'string' ? (
        props.constrain ? (
          <StringField
            value={props.value && typeof props.value === 'string' ? (props.value as string) : ''}
            constrain={props.constrain}
            onValueChange={(val) => props.setValue(val as FilterValueTypes)}
            setError={props.setError}
            setShowError={props.setShowError}
          />
        ) : (
          <Typography>Constrain is required</Typography>
        )
      ) : null}
      {fieldToShow === 'number' && (
        <NumberField
          value={props.value && typeof props.value === 'number' ? (props.value as number) : undefined}
          onValueChange={(val) => props.setValue(val as FilterValueTypes)}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
      {fieldToShow === 'timeRange' && (
        <TimeRangeField
          value={props.value && typeof props.value === 'object' ? (props.value as FilterTimeRangeValue) : undefined}
          onValueChange={(val) => props.setValue(val as FilterTimeRangeValue)}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
    </>
  );
};

export default FilterTemplateValueSelection;
