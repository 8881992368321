import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';

import { ConstrainListV2 } from '../../../common/constants/filter';
import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, FilterFieldDataType, OperatorV2 } from '../../../common/dto/filter.dto';
import WithFilter, { FilterValueTypes, WithFilterAddedProps } from '../../../hoc/WithFilter';
import ConstrainOptionSelection from '../ConstrainSelection';
import FilterOptionsValueSelection from './FilterOptionsValueSelection';

const constrainOptions = ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.options));

export const optionsContain: ConstrainV2 = {
  id: OperatorV2.optionsContains,
  title: 'Contains',
  applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
};

type Props = {
  // constrain?: Constrain;
  // values?: SimpleViewDto[];
  options: SimpleViewDto[];
  isLoading?: boolean;
  hideConstrains?: boolean;
  fixedConstrain?: ConstrainV2;
};
const FilterOptionsMultiple = (props: Props & WithFilterAddedProps) => {
  const filterConstrainOptions: ConstrainV2[] = [...constrainOptions];

  const { setError, setFilterValue, setShowError, hideConstrains, fixedConstrain, currentConstrain, currentValue } = props;
  // const [cValue, setCValue] = useState<SimpleViewDto[]>(props.currentValue ? (props.currentValue as SimpleViewDto[]) : []);
  const [cValue, setCValue] = useState<FilterValueTypes>(currentValue ?? null);
  const [constrain, setConstrain] = useState<ConstrainV2>(fixedConstrain ? fixedConstrain : currentConstrain ? currentConstrain : optionsContain);

  useEffect(() => {
    if (!cValue || (cValue as SimpleViewDto[]).length === 0) {
      setError(`Select atleast 1 option`);
    } else {
      setShowError(false);
      setError(null);
      setFilterValue(cValue, constrain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue, constrain, setError, setShowError]);

  const onChangeConstrain = () => {
    setCValue(null);
  };

  return (
    <>
      {hideConstrains !== true && (
        <ConstrainOptionSelection
          constrain={constrain}
          setConstrain={setConstrain}
          options={filterConstrainOptions}
          setShowValueSection={props.setShowValueSection}
          onChangeConstrain={onChangeConstrain}
        />
      )}
      <Divider />
      {props.showValueSection && (
        // <SearchableAutocompleteProvider
        //   loading={props.isLoading ?? false}
        //   multiple
        //   values={cValue}
        //   options={props.options}
        //   onValueChange={(vals) => setCValue(vals as SimpleViewDto[])}
        //   handleClose={props.handleClose}
        // />
        <FilterOptionsValueSelection
          isLoading={props.isLoading ?? false}
          multiple
          value={cValue}
          constrain={constrain}
          options={props.options}
          setValue={setCValue}
          handleClose={props.handleClose}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
    </>
  );
};

export default WithFilter(FilterOptionsMultiple);
