import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface TokenState {
  tId?: string;
  accessToken?: string;
  refreshToken?: string;
}

const initialState: TokenState = {
  tId: undefined,
  accessToken: undefined,
  refreshToken: undefined,
};

const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState,
  reducers: {
    updateTokens: (state, action: PayloadAction<TokenState>) => {
      state.tId = action.payload.tId;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    clearTokens: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { updateTokens, clearTokens } = accessTokenSlice.actions;

const selectAccessToken = (state: RootState) => state.tokens;

export const selectCurrentSession = createSelector(selectAccessToken, (state) => state.tId);

export default accessTokenSlice.reducer;
