export enum UserAccess {
  ORGANISATION_MANAGE = 'ORGANISATION_MANAGE',

  LOCATION_MANAGE = 'LOCATION_MANAGE',
  LOCATION_DELETE = 'LOCATION_DELETE',
  LOCATION_VIEW = 'LOCATION_VIEW',

  AUTHORITY_MANAGE = 'AUTHORITY_MANAGE',
  AUTHORITY_DELETE = 'AUTHORITY_DELETE',
  AUTHORITY_VIEW = 'AUTHORITY_VIEW',

  USER_MANAGE = 'USER_MANAGE',
  USER_DELETE = 'USER_DELETE',
  USER_VIEW = 'USER_VIEW',

  TEMPLATE_MANAGE = 'TEMPLATE_MANAGE',
  TEMPLATE_DELETE = 'TEMPLATE_DELETE',
  TEMPLATE_VIEW = 'TEMPLATE_VIEW',

  REPORT_MANAGE = 'REPORT_MANAGE',
  REPORT_DELETE = 'REPORT_DELETE',
  REPORT_VIEW = 'REPORT_VIEW',

  EXCEPTION_REPORT_MANAGE = 'EXCEPTION_REPORT_MANAGE',
  EXCEPTION_REPORT_DELETE = 'EXCEPTION_REPORT_DELETE',
  EXCEPTION_REPORT_VIEW = 'EXCEPTION_REPORT_VIEW',

  ASSET_MANAGE = 'ASSET_MANAGE',
  ASSET_DELETE = 'ASSET_DELETE',
  ASSET_VIEW = 'ASSET_VIEW',

  EXCEPTION_RULE_MANAGE = 'EXCEPTION_RULE_MANAGE',
  EXCEPTION_RULE_DELETE = 'EXCEPTION_RULE_DELETE',
  EXCEPTION_RULE_VIEW = 'EXCEPTION_RULE_VIEW',

  SCHEDULE_MANAGE = 'SCHEDULE_MANAGE',
  SCHEDULE_DELETE = 'SCHEDULE_DELETE',
  SCHEDULE_VIEW = 'SCHEDULE_VIEW',

  DASHBOARD_MANAGE = 'DASHBOARD_MANAGE',
  DASHBOARD_DELETE = 'DASHBOARD_DELETE',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
}
