import { Typography } from '@mui/material';
import { SetStateAction } from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, OperatorV2 } from '../../../common/dto/filter.dto';
import { FilterValueTypes } from '../../../hoc/WithFilter';
import NumberRangeField from '../template-element/NumberRangeField';
import StringField from '../template-element/StringField';
import SearchableAutocompleteProvider from '../value-providers/SearchableAutocompleteProvider';

type FieldToShowType = 'string' | 'numberRange' | 'singleSelect' | 'multiSelect';

type ValueSelectionProps = {
  constrain: ConstrainV2;
  value?: FilterValueTypes;
  options: SimpleViewDto[];
  setValue: React.Dispatch<SetStateAction<FilterValueTypes>>;

  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;

  isLoading?: boolean;
  multiple?: boolean;
};

const FilterOptionsValueSelection = (props: ValueSelectionProps) => {
  let min = 1;
  let max = 999;

  let fieldToShow: FieldToShowType;
  if ([OperatorV2.optionsStartsWith, OperatorV2.optionsEndsWith].includes(props.constrain.id)) {
    fieldToShow = 'string';
  } else if ([OperatorV2.optionsLenMin, OperatorV2.optionsLenMax].includes(props.constrain.id)) {
    fieldToShow = 'numberRange';
    min = 1;
    max = props.options.length;
  } else if (props.multiple) {
    fieldToShow = 'multiSelect';
  } else {
    fieldToShow = 'singleSelect';
  }

  return (
    <>
      {fieldToShow === 'singleSelect' && (
        <SearchableAutocompleteProvider
          loading={false}
          values={props.value ? (props.value as SimpleViewDto) : undefined}
          options={props.options}
          onValueChange={(vals) => props.setValue(vals as SimpleViewDto)}
          handleClose={props.handleClose}
        />
      )}
      {fieldToShow === 'multiSelect' && (
        <SearchableAutocompleteProvider
          loading={props.isLoading ?? false}
          multiple
          values={props.value ? (props.value as SimpleViewDto[]) : []}
          options={props.options}
          onValueChange={(vals) => props.setValue(vals as SimpleViewDto[])}
          handleClose={props.handleClose}
        />
      )}

      {fieldToShow === 'numberRange' && (
        <NumberRangeField
          value={props.value && typeof props.value === 'number' ? Number(props.value) : undefined}
          min={min}
          max={max}
          onValueChange={(val) => props.setValue(val as number)}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
      {fieldToShow === 'string' ? (
        props.constrain ? (
          <StringField
            value={props.value && typeof props.value === 'string' ? (props.value as string) : ''}
            constrain={props.constrain}
            onValueChange={(val) => props.setValue(val as string)}
            setError={props.setError}
            setShowError={props.setShowError}
          />
        ) : (
          <Typography>Constrain is required</Typography>
        )
      ) : null}
    </>
  );
};

export default FilterOptionsValueSelection;
