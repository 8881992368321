import { Alert, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import SimpleConfirmDialog from '../components/elements/dialogs/SimpleConfirmDialog';
import useIsLowDensity from '../hook/useIsLowDensity';
import useNewVersionAvailable from '../hook/useNewVersionAvailable';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setCookiePolicy } from '../redux/slices/userSettings.slice';
import { RootState } from '../redux/store';

export const BeforeAuthLayoutView = () => {
  const ldpi = useIsLowDensity();

  const acceptCookiePolicy = useAppSelector((state: RootState) => state.userSettings.acceptCookiePolicy);

  const dispatch = useAppDispatch();
  const [showCookieAlert, setCookieAlert] = useState(false);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  const isReloadRequired = useNewVersionAvailable();
  const [showReloadConfirmation, setShowReloadConfirmation] = useState(false);

  useEffect(() => {
    if (isReloadRequired) setShowReloadConfirmation(true);
  }, [isReloadRequired]);

  useEffect(() => {
    if (!acceptCookiePolicy) setCookieAlert(true);
    return () => {
      if (!acceptCookiePolicy) {
        dispatch(setCookiePolicy(true));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptCookiePolicy]);

  const hideCookieAlert = () => {
    dispatch(setCookiePolicy(true));
    setCookieAlert(false);
  };

  return (
    <Box display="flex" flexDirection="row" justifyItems="stretch" sx={{ height: '100%' }}>
      <Box flex={1} display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Stack direction="column" alignItems="center" sx={{ height: '100%', width: '100%' }}>
          <Stack direction="column" justifyContent="center" flexGrow={1} sx={{ maxWidth: ldpi ? 400 : 500, width: '100%' }} p={4}>
            {!lgUp && (
              <Box textAlign="center" mb={5}>
                <Stack direction="column" alignItems="center" spacing={1}>
                  <img src="/assets/media/logos/logo-full-blue-small.png" alt="Startsafe" width={220} />
                  <Typography variant="body1" color={theme.palette.primary.main} fontWeight="bold">
                    Starting your business safely, Every day!
                  </Typography>
                </Stack>
              </Box>
            )}
            <Outlet />
          </Stack>
          {showCookieAlert === true && (
            <Box sx={{ px: lgUp ? 10 : 4, my: 4 }}>
              <Alert icon={false} severity="info" sx={{ alignItems: 'center', fontSize: theme.typography.body1.fontSize }} onClose={hideCookieAlert}>
                To make StartSafe work, we log user data. By using StartSafe, you agree to our{' '}
                <a href="https://www.startsafe.tech/privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                &{' '}
                <a href="https://www.startsafe.tech/tnc/" target="_blank" rel="noreferrer">
                  Terms and Conditions
                </a>
                . including cookie policy.
              </Alert>
            </Box>
          )}
        </Stack>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: lgUp ? '700px' : '100%',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Stack direction="column" alignItems="center" spacing={4}>
            <img src="/assets/media/logos/login_logo.png" alt="Startsafe" width={300} />
            <Typography variant="body1" color="white">
              Starting your business safely, Every day!
            </Typography>
          </Stack>
        </Box>
      </Box>
      <SimpleConfirmDialog
        hideCancel
        isOpen={showReloadConfirmation}
        onClose={() => {
          window.location.reload();
        }}
        onConfirm={() => {
          window.location.reload();
        }}
        title="New version"
        message="A new version of the app is available. Your browser tab will refresh with the new version."
        confirmButtonText="Confirm"
      />
    </Box>
  );
};
