import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';

import { ConstrainListV2 } from '../../../common/constants/filter';
import { ConstrainV2, FilterFieldDataType, OperatorV2 } from '../../../common/dto/filter.dto';
import { FieldType, IssueTypeEnum, TemplateElement } from '../../../common/dto/template.dto';
import WithFilter, { FilterValueTypes, WithFilterAddedProps } from '../../../hoc/WithFilter';
import ConstrainSelection from '../ConstrainSelection';
import FilterTemplateValueSelection from './FilterTemplateValueSelection';

const getConstrainOptions = (element: TemplateElement) => {
  const additionalConstrains: ConstrainV2[] = [];
  switch (element.fieldType) {
    case FieldType.singleLine:
    case FieldType.multiLine:
      return ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.string) && c.id !== OperatorV2.isNotEmpty);
    case FieldType.numeric:
      return ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.number) && c.id !== OperatorV2.isNotEmpty);
    case FieldType.checkbox:
    case FieldType.radio:
    case FieldType.dropdown:
      return ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.options));
    case FieldType.issue:
      if (element.config?.issueElementType) {
        if (element.config.issueElementType === IssueTypeEnum.yesNo) {
          additionalConstrains.push({
            id: OperatorV2.issuesYesNo,
            title: 'Yes / No',
            applicableDataTypes: [FilterFieldDataType.issues],
          });
        } else if (element.config.issueElementType === IssueTypeEnum.passFail) {
          additionalConstrains.push({
            id: OperatorV2.issuesPassFail,
            title: 'Pass / Fail',
            applicableDataTypes: [FilterFieldDataType.issues],
          });
        }
      }
      return [...ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.issues)), ...additionalConstrains];
    case FieldType.oilLevel:
    case FieldType.fuel:
    case FieldType.temperature:
    case FieldType.duration:
      return ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.number));
    case FieldType.customPicker:
      return ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.customPicker));
    default:
      throw new Error('Unexpected fieldType in getConstrainOptions');
  }
};
const getDefaultConstraint = (fieldType: FieldType): ConstrainV2 | undefined => {
  switch (fieldType) {
    case FieldType.singleLine:
    case FieldType.multiLine:
      return {
        id: OperatorV2.strContains,
        title: 'Contains',
        applicableDataTypes: [FilterFieldDataType.string],
      };
    case FieldType.numeric:
    case FieldType.oilLevel:
    case FieldType.fuel:
    case FieldType.temperature:
    case FieldType.duration:
      return {
        id: OperatorV2.equals,
        title: 'Equals',
        applicableDataTypes: [FilterFieldDataType.string, FilterFieldDataType.number],
      };
    case FieldType.checkbox:
    case FieldType.radio:
    case FieldType.dropdown:
      return {
        id: OperatorV2.optionsContains,
        title: 'Contains',
        applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
      };
    case FieldType.issue:
      return {
        id: OperatorV2.optionsContains,
        title: 'Contains',
        applicableDataTypes: [FilterFieldDataType.options, FilterFieldDataType.issues],
      };
    case FieldType.datetime:
      return undefined;
    case FieldType.customPicker:
      return {
        id: OperatorV2.equals,
        title: 'Equals',
        applicableDataTypes: [FilterFieldDataType.string],
      };
    default:
      throw new Error('Unexpected fieldType in getDefaultConstraint');
  }
};

type Props = {
  // constrain?: Constrain;
  // value?: FilterValueTypes;
  element: TemplateElement;
};

const FilterTemplateElement = (props: Props & WithFilterAddedProps) => {
  const [value, setValue] = useState<FilterValueTypes>(props.currentValue ?? null);
  const [constrain, setConstrain] = useState<ConstrainV2 | undefined>(props.currentConstrain ?? getDefaultConstraint(props.element.fieldType));
  // when constain changes, reset value to null
  // useEffect(() => {
  //   console.log('resetting value due to constrain change', constrain);
  //   setValue(null);
  // }, [constrain]);

  const { setFilterValue } = props;
  useEffect(() => {
    setFilterValue(value, constrain);
  }, [value, constrain, setFilterValue]);

  const onChangeConstrain = () => {
    setValue(null);
  };

  return (
    <>
      {constrain && (
        <>
          <ConstrainSelection
            constrain={constrain}
            setConstrain={setConstrain}
            options={getConstrainOptions(props.element)}
            setShowValueSection={props.setShowValueSection}
            onChangeConstrain={onChangeConstrain}
          />
          <Divider />
        </>
      )}
      {props.showValueSection && (
        <FilterTemplateValueSelection
          value={value}
          constrain={constrain}
          element={props.element}
          setValue={setValue}
          handleClose={props.handleClose}
          setError={props.setError}
          setShowError={props.setShowError}
        />
      )}
    </>
  );
};

export default WithFilter(FilterTemplateElement);
