import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';

import { FilterTimeRangeValue } from '../../../hoc/WithFilter';
import TimeRangeProvider from '../value-providers/TimeRangeProvider';

type DateRangeFieldProps = {
  value?: FilterTimeRangeValue;
  onValueChange: (val: FilterTimeRangeValue) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
};

const TimeRangeField = (props: DateRangeFieldProps) => {
  const [cValue, setCValue] = useState<FilterTimeRangeValue>(
    props.value ?? {
      from: '',
      to: '',
    },
  );
  const { setError, setShowError } = props;

  useEffect(() => {
    if (cValue && cValue.from && cValue.to && moment(cValue.from).isValid() && moment(cValue.to).isValid()) {
      setShowError(false);
      setError(null);
      props.onValueChange(cValue);
    } else {
      setError('Value is required');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  return (
    <TimeRangeProvider
      value={cValue}
      onValueChange={(val) => {
        setCValue({ ...val });
      }}
    />
  );
};

export default TimeRangeField;
