import { set } from 'lodash';

import { ValidationErrorResponse } from '../validation/validation.dto';

export type ApiValidationErrorMessage = Record<string, string[]>;

const CORE_API_URI = import.meta.env.VITE_CORE_SERVICE;

export class CommonHelperService {
  static getImageUrlById = (id: string, requestId: string, thumbnail = false, key?: string) => {
    if (thumbnail && key) {
      thumbnail = key.includes('temp/') ? false : true;
    }
    return `${CORE_API_URI}/media/${id}/${requestId}${thumbnail ? '/small' : ''}`;
  };

  static formatApiErrorMessagesWithOverrides = (messages: any[], overrideFields?: Record<string, string>): ApiValidationErrorMessage => {
    const errors = this.formatApiErrorMessages(messages, []);
    if (overrideFields) {
      for (const fieldKey of Object.keys(errors)) {
        if (overrideFields && overrideFields[fieldKey]) {
          errors[overrideFields[fieldKey]] = errors[fieldKey];
          delete errors[fieldKey];
        }
      }
    }
    return errors;
  };

  static formatApiErrorMessages = (messages: any[], path: string[] = []): ApiValidationErrorMessage => {
    const errors: ApiValidationErrorMessage = {};
    if (messages != null) {
      for (const message of messages) {
        if (message.constraints != null) {
          for (const key of Object.keys(message.constraints)) {
            const property: string = [...path, message.property].join('.');
            if (!errors[property]) errors[property] = [];
            errors[property].push(message.constraints[key]);
          }
        } else {
          const nestedErrors = this.formatApiErrorMessages(message.children, [...path, message.property]);
          for (const nKey of Object.keys(nestedErrors)) {
            if (!errors[nKey]) errors[nKey] = [];
            errors[nKey].push(...nestedErrors[nKey]);
          }
        }
      }
    }

    return errors;
  };

  static formatTemplateApiErrorMessages = (message: any[]): any => {
    let errors: any = {};
    errors = this.formatTemplateElementErrorMessages(message, errors, []);
    return errors;
  };

  static formatTemplateElementErrorMessages = (messages: any[], errors: any, path: string[] = []): any => {
    let apiErrors: any = errors;
    let property;
    if (messages) {
      for (const message of messages) {
        property = message.property;
        if (message.constraints != null) {
          const keys = [...path, property];
          const constraintValue = [];
          for (const key of Object.keys(message.constraints)) {
            constraintValue.push(message.constraints[key]);
          }
          apiErrors = set(apiErrors, keys, constraintValue);
        }
        if (message.children) {
          this.formatTemplateElementErrorMessages(message.children, errors, [...path, property]);
        }
      }
    }
    return apiErrors;
  };

  static formatReportApiErrorMessages = (messages: any[]): ValidationErrorResponse => {
    let errors: ValidationErrorResponse = {};
    errors = this.formatReportElementError(messages, errors, []);
    return errors;
  };

  static formatReportElementError = (messages: any[], errors: ValidationErrorResponse, path: string[] = []): ValidationErrorResponse => {
    const apiErrors: ValidationErrorResponse = errors;
    if (messages != null) {
      if (messages && Array.isArray(messages)) {
        for (const message of messages) {
          if (message.constraints != null) {
            for (const key of Object.keys(message.constraints)) {
              const property: string = [...path, message.property].join('.');
              if (!apiErrors[property]) apiErrors[property] = [];
              apiErrors[property].push(message.constraints[key]);
            }
          } else {
            const nestedErrors = this.formatReportElementError(message.children, apiErrors, [...path, message.property]);
            for (const nKey of Object.keys(nestedErrors)) {
              if (!apiErrors[nKey]) apiErrors[nKey] = [];
              apiErrors[nKey].push(...nestedErrors[nKey]);
            }
          }
        }
      } else {
        for (const [elementId, fieldErrors] of Object.entries(messages)) {
          if (!apiErrors[elementId]) apiErrors[elementId] = [];
          apiErrors[elementId as string] = fieldErrors as string[];
        }
      }
    }
    return apiErrors;
  };
}
