import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Button, Chip, Divider, IconButton, LinearProgress, List, ListItem, ListSubheader, Popover, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React, { createRef, useCallback, useEffect, useState } from 'react';

import { NotificationReadEnum, NotificationStatusEnum, NotificationTypeEnum, NotificationViewDto } from '../common/dto/notification.dto';
import { LoaderVertical } from '../components/common/Loader';
import { FlexBetween, FlexColumn, FlexRow } from '../components/styled/Flex';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { manageNotification, notificationsSelector, setNotifications } from '../redux/slices/notification.slice';
import { useClearAllNotificationMutation, useLazyGetNotificationListQuery, useMarkReadMutation } from '../services/notification.service';
import { createLogger } from '../utils/logger';

const logger = createLogger('NotificationMenu');

const NotificationMenu = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [notifications, setNotifications] = useState<NotificationViewDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getNotifications] = useLazyGetNotificationListQuery();
  const [clearAllNotification] = useClearAllNotificationMutation();
  const notificationState = useAppSelector((state) => state.notification);
  const allNotifications = useAppSelector(notificationsSelector);

  const iconRef = createRef<HTMLButtonElement>();

  const getData = useCallback(() => {
    getNotifications()
      .unwrap()
      .then((res) => {
        setLoading(false);
        dispatch(setNotifications(res));
      });
  }, [getNotifications, dispatch]);

  const handleClick = useCallback(() => {
    setAnchorEl(iconRef.current);
    setLoading(true);
    getData();
  }, [getData, iconRef]);

  // show notification popper if state changes from another page
  useEffect(() => {
    if (notificationState.showNotification) {
      dispatch(manageNotification(false));
      handleClick();
    }
  }, [dispatch, handleClick, notificationState.showNotification]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearAll = () => {
    setNotifications([]);
    clearAllNotification()
      .unwrap()
      .then((res) => {
        if (res) {
          getData();
        }
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={() => handleClick()} ref={iconRef}>
        <NotificationsIcon fontSize="medium" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            [theme.breakpoints.down('md')]: {
              // minWidth: '100%',
              // maxWidth: '100%',
            },
          },
        }}
      >
        {isLoading ? (
          <LoaderVertical />
        ) : (
          <FlexColumn>
            <FlexBetween sx={{ px: 3, py: 3 }} alignItems="center">
              <Typography variant="body1">Notifications</Typography>
              {allNotifications.length > 0 && (
                <Button variant="simple" size="small" onClick={clearAll}>
                  Clear all
                </Button>
              )}
            </FlexBetween>
            <Divider sx={{ mb: 1.5 }} />
            <NotificationList data={allNotifications} getData={getData} isLoading={isLoading} />
          </FlexColumn>
        )}
      </Popover>
    </>
  );
};
export default NotificationMenu;

interface DateGroup {
  [date: string]: {
    date: string;
    notifications: NotificationViewDto[];
  };
}

const NotificationList = ({ data, getData, isLoading }: { data: NotificationViewDto[]; getData: () => void; isLoading: boolean }) => {
  const [datesGroup, setDatesGroup] = useState<DateGroup>({});
  const theme = useTheme();

  // const websocketChangeStatus = useCallback(
  //   (response: WebSocketResponse) => {
  //     if (response.payload) {
  //       if (response.payload.downloadUrl) {
  //         getData();
  //       }
  //     }
  //   },
  //   [getData],
  // );

  useEffect(() => {
    if (data && data.length > 0) {
      setDatesGroup(() => {
        const group: DateGroup = {};
        let groupIndex;
        data.forEach((item) => {
          if (item) {
            groupIndex = moment(item.createdAt).format('DD-MM-YYYY');
            if (!group[groupIndex]) {
              group[groupIndex] = {
                date: moment(item.createdAt).format('DD MMM YYYY'),
                notifications: [],
              };
            }
            group[groupIndex].notifications.push({ ...item, createdAt: moment(item.createdAt).format('DD MMM YYYY') });

            // enable websockets if present
            // if (item.websocketUrl && item.websocketStatus === WebsocketStatusEnum.IN_PROGRESS) {
            //   const myWebSocket: WebSocketSubject<WebSocketResponse> = webSocket(item.websocketUrl);
            //   myWebSocket.asObservable().subscribe((wsRes: WebSocketResponse) => {
            //     websocketChangeStatus(wsRes);
            //   });
            // }
          }
        });
        return group;
      });
    } else {
      setDatesGroup({});
    }
  }, [data]);

  if (isLoading) return <LoaderVertical />;

  if (data.length === 0)
    return (
      <Box sx={{ minWidth: 450, px: 3, pb: 2 }}>
        <Typography variant="body2">No new notifications</Typography>
      </Box>
    );

  return (
    <List
      sx={{
        width: '100%',
        minWidth: 450,
        maxWidth: 550,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        '& ul': { padding: 0 },

        [theme.breakpoints.down('md')]: {
          minWidth: '100%',
          maxWidth: '100%',
        },
      }}
      subheader={<li />}
    >
      {Object.keys(datesGroup).map((key: string) => {
        return (
          <li key={`section-${key}`}>
            <ul>
              <ListSubheader sx={{ px: 3, py: 2, background: '#f5f5f5', lineHeight: '1rem' }}>
                <Typography variant="body2">{datesGroup[key].date}</Typography>
              </ListSubheader>
              {datesGroup[key].notifications.map((item, index) => (
                <Box key={`item-${item.id}}`}>
                  <ListItem sx={{ p: 0 }}>
                    <NotificationItem
                      notificationId={item.id}
                      type={item.type}
                      read={item.read}
                      status={item.status}
                      createdAt={item.createdAt as unknown as string}
                      msg={item.msg}
                      signedUrl={item.signedUrl}
                      error={item.error}
                      getData={getData}
                    />
                  </ListItem>
                  {index < datesGroup[key].notifications.length - 1 && <Divider sx={{ mx: 3 }} />}
                </Box>
              ))}
            </ul>
          </li>
        );
      })}
    </List>
  );
};

const NotificationItem = ({
  notificationId,
  type,
  read,
  status,
  createdAt,
  msg,
  signedUrl,
  error,
  getData,
}: {
  notificationId: string;
  type: NotificationTypeEnum;
  read: NotificationReadEnum;
  status: NotificationStatusEnum;
  createdAt: string;
  msg: string;
  signedUrl?: string;
  error?: string;
  getData: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [markRead] = useMarkReadMutation();
  const downloadFile = async (url: string, id: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    markRead(id)
      .unwrap()
      .then(() => {
        getData();
      });
  };

  useEffect(() => {
    let timeoutId: null | ReturnType<typeof setTimeout> = null;
    if (status === NotificationStatusEnum.IN_PROGRESS || status === NotificationStatusEnum.REQUESTED) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getData();
      }, 3000); // retry every few seconds
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  return (
    <Box sx={{ width: '100%', px: 2, py: 1, '&:hover': { cursor: 'pointer', background: '#f5f5f5' } }}>
      <FlexColumn gap={0.5}>
        <FlexBetween>
          <FlexRow alignItems="center" columnGap={2}>
            <Typography variant="body2">{type}</Typography>
            {read === NotificationReadEnum.UNREAD && <Chip label="New" variant="outlined" color="primary" size="small" />}
          </FlexRow>

          <Typography variant="subtitle1">{createdAt}</Typography>
        </FlexBetween>
        <Typography variant="body1">{msg}</Typography>
        {type === NotificationTypeEnum.DOWNLOAD && signedUrl ? (
          <Button variant="contained" size="small" sx={{ width: 100, height: 28 }} onClick={() => downloadFile(signedUrl, notificationId)}>
            Open
          </Button>
        ) : error ? (
          <Typography color="warning">{error}</Typography>
        ) : (
          <LinearProgress color="secondary" />
        )}
      </FlexColumn>
    </Box>
  );
};
