import { Box, useTheme } from '@mui/material';
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { useAppSelector } from '../../redux/hooks';

const stringToDate = (value: string | undefined): Moment | undefined => {
  const date: Moment | undefined = value ? moment(value) : undefined;
  return date;
};

interface Props {
  value?: string;
  placeholder?: string;
  field?: string;
  // minValue?: string;
  // maxValue?: string;
  setValue: (date: string) => void;
}
const CustomHoursPicker = (props: Props) => {
  const theme = useTheme();
  const userTimezone = useAppSelector((state) => state.userInfo.timezone);
  const updateValue = (date: Moment | null) => {
    let dateValue = '';
    if (date) {
      dateValue = date?.toISOString();
    }
    props.setValue(dateValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        <TimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          desktopModeMediaQuery={theme.breakpoints.up('md')}
          value={stringToDate(props.value)}
          onChange={(date: Moment | null) => updateValue(date)}
          // minTime={stringToDate(props.minValue)} // TODO plugin not support min/max time for undefined value
          // maxTime={stringToDate(props.maxValue)}
          timezone={userTimezone}
          slotProps={{
            textField: {
              id: props.field ?? 'timePicker',
              name: props.field ?? 'timePicker',
              placeholder: props.placeholder,
              onBeforeInput: (e) => {
                if (e?.preventDefault) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              },
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomHoursPicker;
