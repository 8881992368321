import { Button, Grid, Switch, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { SimpleViewDto } from '../../common/dto/common.dto';
import { MatchType } from '../../common/dto/exception-rule.dto';
import { ConstrainV2, FilterFieldDataType, FilterFieldDto } from '../../common/dto/filter.dto';
import { TemplateElementView } from '../../common/dto/template.dto';
import { FilterHelper } from '../../helper/filter.helper';
import { FilterValueTypes } from '../../hoc/WithFilter';
import { WithSideDrawerAddedProps } from '../../hoc/WithSideDrawer';
import { SearchFilterProps } from '../../hook/useSearch';

export type FilterParamType = {
  fieldDataType: FilterFieldDataType;
  constrain?: ConstrainV2;
  value?: FilterValueTypes;
  fieldSuffixMeta?: Record<string, string>;
};

export type FilterRenderable = {
  fieldDataType: FilterFieldDataType;
  field: string;
  label: string;
  options?: SimpleViewDto[];
  element?: TemplateElementView;
  // FilterComponent: React.FC;
  FilterComponent: any; // TODO figure out types
  fieldSuffixMeta?: Record<string, string>;
};

type Props = {
  elements: FilterRenderable[];
  filterId: string | null;
  setSearchFilter: (value: React.SetStateAction<SearchFilterProps>) => void;
  searchFilter: SearchFilterProps;
  filterParams: Record<string, FilterParamType>;
  setFilterParams: (value: React.SetStateAction<Record<string, FilterParamType>>) => void;
  setFilterOpen: (value: React.SetStateAction<boolean>) => void;
  saveFilter: (filterFields: Record<string, FilterParamType>, matchType: MatchType, filterId?: string) => void;
};
const FilterPanel = (props: Props & WithSideDrawerAddedProps) => {
  const [filterParams, setFilterParams] = useState<Record<string, FilterParamType>>(props.filterParams);
  const [matchType, setMatchType] = useState<MatchType>(props.searchFilter.matchType ?? MatchType.ALL);

  const onFilterValueChanged = (
    fieldDataType: FilterFieldDataType,
    field: string,
    value?: FilterValueTypes,
    constrain?: ConstrainV2,
    fieldSuffixMeta?: Record<string, string>,
  ) => {
    setFilterParams((prev) => {
      const newVal = {
        ...prev,
        [field]: {
          fieldDataType,
          constrain,
          value,
          fieldSuffixMeta,
        },
      };
      return newVal;
    });
  };

  const getFilterParam = (field: string) => {
    return filterParams[field] ?? null;
  };

  const setNewFilterVals = (vals: Record<string, FilterParamType>) => {
    const filterFields: FilterFieldDto[] = FilterHelper.filterToSearchParam(vals);
    props.setSearchFilter((prev) => {
      return {
        ...prev,
        matchType,
        filterFields,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      };
    });
    props.setFilterParams(filterParams);
  };

  useEffect(() => {
    if (props.isSubmitting) {
      setNewFilterVals(filterParams);
      props.setSubmitting(false);
      props.setFilterOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSubmitting]);

  const saveFilter = (type: 'update' | 'create') => {
    if (type === 'update') {
      props.saveFilter(filterParams, matchType, props.filterId as string);
    } else {
      props.saveFilter(filterParams, matchType);
    }
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMatchType(event.target.checked ? MatchType.ANY : MatchType.ALL);
  };

  let noOfFiltersApplied = 0;
  for (const key of Object.keys(filterParams)) {
    const fField = filterParams[key];
    if (fField.value || fField.constrain) noOfFiltersApplied += 1;
  }

  return (
    <>
      <Grid container spacing={2} data-automation="filter-panel">
        <Grid item xs={12}>
          <Typography variant="body1" display="inline">
            All
          </Typography>
          <Switch
            id="matchType"
            data-automation="switch-matchType"
            checked={matchType === MatchType.ANY}
            onChange={handleMatchTypeChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant="body1" display="inline">
            Any
          </Typography>
        </Grid>
        {props.elements.map((element, index) => {
          const { FilterComponent, ...otherPrps } = element;
          return (
            <Grid item md={6} xs={12} key={`${index}`}>
              <FilterComponent
                {...(otherPrps as any)}
                value={getFilterParam(otherPrps.field)?.value as FilterValueTypes}
                constrain={getFilterParam(otherPrps.field)?.constrain}
                onFilterValueChanged={onFilterValueChanged}
              />
            </Grid>
          );
        })}
        {(noOfFiltersApplied > 0 || props.searchFilter.matchType !== matchType) && (
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <>
              {props.filterId == null && (
                <Button variant="contained" onClick={() => saveFilter('create')} sx={{ mr: 0.8 }} data-automation="filter-save-btn">
                  Save
                </Button>
              )}
              {props.filterId != null && (!isEqual(filterParams, props.filterParams) || props.searchFilter.matchType !== matchType) && (
                <>
                  <Button variant="contained" onClick={() => saveFilter('update')} sx={{ mr: 0.8 }} data-automation="filter-update-btn">
                    Update
                  </Button>
                  <Button variant="contained" onClick={() => saveFilter('create')} sx={{ mr: 0.8 }} data-automation="filter-save-as-btn">
                    Save as
                  </Button>
                </>
              )}
            </>
          </Grid>
        )}
      </Grid>

      {import.meta.env.DEV && <pre style={{ fontSize: '12px' }}>{JSON.stringify(props.filterParams, null, 2)}</pre>}
    </>
  );
};

export default FilterPanel;
