import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommonMin } from '../../common/dto/common.dto';
import { UserAccesByOrganisation } from '../../common/types/user-store.types';
import { RootState } from '../store';

interface AdminState {
  administerOrganisation?: CommonMin;
  impersonateUserId?: string;
  firstname?: string;
  lastname?: string;
  login: string;
  currentImpersonateUserOrganisation?: CommonMin;
  organisations: CommonMin[];
  access: UserAccesByOrganisation;
}

const initialState: AdminState = {
  impersonateUserId: undefined,
  organisations: [],
  access: {},
  login: '',
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setImpersonateUserData: (
      state,
      action: PayloadAction<{
        userId: string;
        firstname?: string;
        lastname?: string;
        login: string;
        organisations: CommonMin[];
        access: UserAccesByOrganisation;
      }>,
    ) => {
      state.impersonateUserId = action.payload.userId;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.login = action.payload.login;
      state.organisations = action.payload.organisations;
      state.access = action.payload.access;
      state.currentImpersonateUserOrganisation = action.payload.organisations.length ? action.payload.organisations[0] : undefined;
    },
    changeImpersonateUserOrganisation: (state, action: PayloadAction<{ organisation: CommonMin }>) => {
      state.currentImpersonateUserOrganisation = action.payload.organisation;
    },
    setAdministerOrganisation: (state, action: PayloadAction<{ organisation: CommonMin }>) => {
      state.administerOrganisation = action.payload.organisation;
    },
    clearAdminState: () => initialState,
  },
});

export const { setImpersonateUserData, changeImpersonateUserOrganisation, clearAdminState, setAdministerOrganisation } = adminSlice.actions;

export const selectAdminInfo = (state: RootState) => state.admin;

export default adminSlice.reducer;
