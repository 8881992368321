import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface UserSettingState {
  dataTableLimit: number;
  acceptCookiePolicy: boolean;
}

const initialState = { acceptCookiePolicy: false } as UserSettingState;

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    updateDataTableLimit: (state, action: PayloadAction<number>) => {
      state.dataTableLimit = action.payload;
    },
    setCookiePolicy: (state, action: PayloadAction<boolean>) => {
      state.acceptCookiePolicy = action.payload;
    },
  },
});

export const { updateDataTableLimit, setCookiePolicy } = userSettingsSlice.actions;

// export const selectUserSettings = (state: RootState) => state.userSettings;

export default userSettingsSlice.reducer;
