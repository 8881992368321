const ExceptionRuleIcon = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" xmlSpace="preserve" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 10v4M12 18h0M21.3 19.1c.7 1.3-.2 2.9-1.7 2.9H4.4c-1.5 0-2.5-1.6-1.7-2.9l7.6-13.9c.8-1.4 2.7-1.4 3.5 0l7.5 13.9z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ExceptionRuleIcon;
