import { Alert, Button, Grid, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';

import { CreatedUserWithUsernameResponseDto, UserProfileUpdateDto } from '../../common/dto/user.dto';
import { WithFormikAddedProps } from '../../hoc/WithFormik';
import { SubmitHandle, WithSideDrawerAddedProps } from '../../hoc/WithSideDrawer';
import FormSectionBox from '../common/FormSectionBox';
import { FlexRow } from '../styled/Flex';

interface FormProps {
  data: CreatedUserWithUsernameResponseDto | null;
}

type Props = FormProps & WithFormikAddedProps<UserProfileUpdateDto> & WithSideDrawerAddedProps;

const ViewUserPassword: React.ForwardRefRenderFunction<SubmitHandle, Props> = (props, ref) => {
  if (!props.data) return <>No data available</>;

  const copyToClipboard = () => {
    copy(`Url: ${props.data?.webAppUrl}\nLogin: ${props.data?.login}\nPassword: ${props.data?.password}`);
  };

  return (
    <>
      <Alert severity="success" sx={{ mb: 2 }}>
        User password has been successfully reset
      </Alert>
      <FormSectionBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FlexRow>
              <Typography variant="body1" sx={{ mr: 1 }}>
                Url:
              </Typography>
              <Typography variant="body1" data-automation="created-url">
                {props.data.webAppUrl}
              </Typography>
            </FlexRow>
          </Grid>
          <Grid item xs={12}>
            <FlexRow>
              <Typography variant="body1" sx={{ mr: 1 }}>
                Login:
              </Typography>
              <Typography variant="body1" data-automation="created-login">
                {props.data.login}
              </Typography>
            </FlexRow>
          </Grid>
          <Grid item xs={12}>
            <FlexRow>
              <Typography variant="body1" sx={{ mr: 1 }}>
                Password:
              </Typography>
              <Typography variant="body1" data-automation="created-password">
                {props.data.password}
              </Typography>
            </FlexRow>
          </Grid>
        </Grid>
      </FormSectionBox>

      <Button variant="outlined" sx={{ mt: 2 }} onClick={() => copyToClipboard()}>
        Copy to clipboard
      </Button>
    </>
  );
};

export default React.forwardRef(ViewUserPassword);
