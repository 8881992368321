import styled from '@emotion/styled';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserAccess } from '../common/constants/access';
import { CommonMin } from '../common/dto/common.dto';
import CrudDropdownFieldNew from '../components/elements/crud-elements/CrudDropdownFieldNew';
import { FlexColumn, FlexRow } from '../components/styled/Flex';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  changeOrganisation,
  checkOrgAccess,
  selectUserInfo,
  userCurrentOrganisationsSelector,
  userOrganisationsSelector,
} from '../redux/slices/userInfo.slice';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0 }} data-automation="user-avatar">
        <LoggedInUserAvatar />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserMenuList handleClose={handleClose} />
      </Popover>
    </>
  );
};

const MenuNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

type UserMenuListProps = {
  handleClose: () => void;
};
function UserMenuList({ handleClose }: UserMenuListProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const hasEditAccess = useAppSelector(checkOrgAccess(UserAccess.ORGANISATION_MANAGE));

  const organisations = useAppSelector(userOrganisationsSelector);
  const userCurrentOrganisation = useAppSelector(userCurrentOrganisationsSelector);

  const sortedOrganisations = useMemo(() => {
    return [...organisations].sort((o1, o2) => (o1.name > o2.name ? 1 : -1));
  }, [organisations]);

  const onOrganisationChange = useCallback(
    (organisation: { id: string; name: string }) => {
      console.log('orgId', organisation);
      dispatch(changeOrganisation({ organisation }));
      setTimeout(() => {
        navigate(0);
      }, 200);
      handleClose();
    },
    [dispatch, handleClose, navigate],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Paper elevation={0} sx={{ minWidth: 300, maxWidth: 400 }} data-automation="user-account-popper">
        <MenuNav component="nav" disablePadding>
          <ListItem sx={{ py: 3 }}>
            <FlexRow gap={2}>
              <LoggedInUserAvatar />
              <FlexColumn justifyContent="center">
                <Typography variant="body1">{`${userInfo.firstname} ${userInfo.lastname}`}</Typography>
                <Typography variant="body2">{userInfo.login}</Typography>
              </FlexColumn>
            </FlexRow>
          </ListItem>
          <Divider />
          <ListItem sx={{ py: 2 }}>
            <FlexColumn sx={{ width: '100%' }}>
              <Box sx={{ pb: 1 }}>
                <Typography variant="body1">Organisation:</Typography>
              </Box>
              <OrganisationSelect onSelected={onOrganisationChange} organisations={sortedOrganisations} currentOrganisation={userCurrentOrganisation} />
            </FlexColumn>
          </ListItem>
          <Divider />
          <Box
            sx={{
              // bgcolor: 'rgba(71, 98, 130, 0.2)',
              py: 2,
            }}
          >
            <ListItemButton
              sx={{ py: 0, minHeight: 50 }}
              onClick={() => {
                navigate('/app/my-account');
                handleClose();
              }}
              data-automation="my-account"
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="My Account" primaryTypographyProps={{ variant: 'body1', fontWeight: 'medium' }} />
            </ListItemButton>
            {hasEditAccess && (
              <ListItemButton
                sx={{ py: 0, minHeight: 50 }}
                onClick={() => {
                  navigate('/app/admin');
                  handleClose();
                }}
                data-automation="admin"
              >
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" primaryTypographyProps={{ variant: 'body1', fontWeight: 'medium' }} />
              </ListItemButton>
            )}
            <ListItemButton sx={{ py: 0, minHeight: 50 }}>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="Help" primaryTypographyProps={{ variant: 'body1', fontWeight: 'medium' }} />
            </ListItemButton>
            <ListItemButton
              sx={{ py: 0, minHeight: 50 }}
              onClick={() => {
                navigate('/auth/logout');
                handleClose();
              }}
              data-automation="user-logout"
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'body1', fontWeight: 'medium' }} />
            </ListItemButton>
          </Box>
        </MenuNav>
      </Paper>
    </Box>
  );
}

type OrganisationSelectProps = {
  organisations: CommonMin[];
  currentOrganisation?: CommonMin;
  onSelected: (organisation: { id: string; name: string }) => void;
};
export const OrganisationSelect = (props: OrganisationSelectProps) => {
  const handleOrganisationChange = (e: SelectChangeEvent<string>) => {
    const org = props.organisations.find((o) => o.id === e.target.value);
    const organisation = {
      id: e.target.value,
      name: org ? org.name : '',
    };
    props.onSelected(organisation);
  };

  return (
    <CrudDropdownFieldNew
      size="small"
      field="organisation"
      label=""
      placeholder="Select Organisation"
      value={props.currentOrganisation ? props.currentOrganisation.id : ''}
      error={null}
      handleChange={handleOrganisationChange}
      isLoading={false}
      options={props.organisations}
      handleBlur={() => {}}
      hideLabel
      hideEmptyOption
      emptyMessage="No Organisations to select"
    />
  );
};

export default UserMenu;

const LoggedInUserAvatar = () => {
  const [avatarChar, setAvatarChar] = useState<string | null>(null);
  const userInfo = useAppSelector(selectUserInfo);

  useEffect(() => {
    if (userInfo.firstname && userInfo.lastname) {
      const parts = [];
      if (userInfo.firstname && userInfo.firstname.trim().length) parts.push(userInfo.firstname);
      if (userInfo.lastname && userInfo.lastname.trim().length) parts.push(userInfo.lastname);
      const chars = parts.map((i) => i.charAt(0).toUpperCase()).join('');
      setAvatarChar(chars);
    }
  }, [userInfo]);
  return (
    <Avatar sx={{ bgcolor: '#f1f2f5', color: '#3186f3', fontSize: '1.5rem', width: 38, height: 38 }} aria-controls="menu-appbar" aria-haspopup="true">
      {avatarChar}
    </Avatar>
  );
};
