import { Alert } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import React, { ComponentType, useCallback, useEffect, useRef, useState } from 'react';

import { ApiValidationErrorMessage, CommonHelperService } from '../common/services/helper.service';
import { SubmitHandle, WithSideDrawerAddedProps } from './WithSideDrawer';

export interface WithFormikAddedProps<A> {
  values: FormikValues;
  errors: FormikErrors<A>;
  touched: FormikTouched<A>;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<A>> | Promise<void>;
  validateForm: (values?: A) => Promise<FormikErrors<A>>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  handleApiErrors: (e: any, overrideFields?: Record<string, string>) => void;
  resetForm: () => void;
}

interface WithFormikInputProps<A> {
  initialValues: A;
  validationSchema: any;
}

export const withFormik = <
  A extends FormikValues,
  T extends object,
  FT extends Omit<T & WithFormikInputProps<A> & WithSideDrawerAddedProps, keyof WithFormikAddedProps<A>>,
>(
  WrappedComponent: ComponentType<T>,
): React.ComponentType<FT> => {
  const ComponentWithFormik = ({ initialValues, validationSchema, isSubmitting, setSubmitting, setDisableSubmit, ...hocProps }: FT) => {
    // console.log('withFormik', initialValues, hocProps);

    const [errorMessage, setErrorMessage] = useState<string | null>();

    const childRef = useRef<SubmitHandle>();
    const callChildSubmit = useCallback(() => {
      childRef.current?.onSubmit();
    }, [childRef]);

    const formik = useFormik<A>({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      },
    });

    // trigger form validation + api submission
    useEffect(() => {
      if (isSubmitting) {
        formik.validateForm().then((errors) => {
          console.log('values', formik.values);
          console.log('errors', errors);

          const fieldsHavingError = Object.keys(errors);
          if (fieldsHavingError.length) {
            setSubmitting(false);
            touchFields(errors, fieldsHavingError, []);
            console.log('touched', formik.touched);
            // for (const fieldName of fieldsHavingError) {
            //   formik.setFieldTouched(fieldName, true);
            // }
          } else {
            setErrorMessage(null);
            callChildSubmit();
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const touchFields = (errors: any, fields: string[], path: string[]) => {
      for (const fieldName of fields) {
        const fullPathToField = [...path, fieldName].join('.');
        console.log('touchFields', fullPathToField, typeof errors[fieldName]);
        if (typeof errors[fieldName] === 'object') {
          touchFields(errors[fieldName], Object.keys(errors[fieldName]), [...path, fieldName]);
        } else {
          console.log('marking as touchjed', fullPathToField);
          formik.setFieldTouched(fullPathToField, true);
        }
      }
    };

    // handle api errors
    const handleApiErrors = (e: any, overrideFields?: Record<string, string>) => {
      if (e.status === 400) {
        const fieldErrors = CommonHelperService.formatApiErrorMessagesWithOverrides(e.data?.message, overrideFields);
        console.log('fieldErrors', fieldErrors);
        setApiErrorForFields(fieldErrors);
        setErrorMessage(`Please check below errors`);
        // const fieldsHavingError = Object.keys(fieldErrors);
        // touchFields(fieldErrors, fieldsHavingError, []);
      } else if (typeof e.data?.message === 'string') {
        setErrorMessage(e.data?.message);
      }
    };

    const setApiErrorForFields = (fieldErrors: ApiValidationErrorMessage) => {
      for (const [key, errors] of Object.entries(fieldErrors)) {
        formik.setFieldError(key, errors[0]);
        formik.setFieldTouched(key, true, false);
      }
    };

    const onSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      setSubmitting(true);
    };

    return (
      <>
        <form name="formik" onSubmit={(e) => onSubmit(e)}>
          <input type="submit" style={{ display: 'none' }} />
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 1 }} data-automation="error-alert">
              {errorMessage}
            </Alert>
          )}
          <WrappedComponent
            ref={childRef}
            {...(hocProps as T)}
            setDisableSubmit={setDisableSubmit}
            setSubmitting={setSubmitting}
            handleApiErrors={handleApiErrors}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            touched={formik.touched}
            errors={formik.errors}
            setFieldValue={formik.setFieldValue}
            validateForm={formik.validateForm}
            setFieldTouched={formik.setFieldTouched}
            resetForm={formik.resetForm}
          />
        </form>
      </>
    );
  };

  // displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithFormik.displayName = `withFormik(${displayName})`;

  return ComponentWithFormik;
};
