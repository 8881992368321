import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Divider, Drawer, IconButton, Link as MuiLink, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';

import packageJson from '../../package.json';
import { mainMenuList } from '../common/constants/main-menu';
import { NavLink } from '../common/dto/common.dto';
import {
  AssetIcon,
  AuthorityIcon,
  DashboardIcon,
  ExceptionReportIcon,
  ExceptionRuleIcon,
  LocationIcon,
  ReportIcon,
  ScheduleIcon,
  TemplateIcon,
  UserIcon,
} from '../components/icons/menu';
import { FlexColumn } from '../components/styled/Flex';
import useIsLowDensity from '../hook/useIsLowDensity';
import { useAppDispatch } from '../redux/hooks';
import { FilterSettingsType, resetFilter } from '../redux/slices/filterSettings.slice';

type Props = {
  open: boolean;
  showMenuName: boolean;
  setShowMenuName: (value: SetStateAction<boolean>) => void;
  onClose: () => void;
};

interface LargeScreenProps extends NavLink {
  showMenuName: boolean;
  setShowMenuName: (value: SetStateAction<boolean>) => void;
}

const SideMenu = ({ open, showMenuName, setShowMenuName, onClose }: Props) => {
  const ldpi = useIsLowDensity();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });

  // useEffect(
  //   () => {
  //     if (!router.isReady) {
  //       return;
  //     }

  //     if (open) {
  //       onClose?.();
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [navigate.asPath]
  // );

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          //   background: theme.palette.brandBlueBg,
        }}
      >
        <Box
          sx={{
            px: 2,
            paddingTop: lgUp ? 3 : 2.5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          {showMenuName ? (
            <img src="/assets/media/logos/startsafe_logo_hor_new.png" style={{ width: lgUp ? (ldpi ? 160 : 160) : 140 }} />
          ) : (
            <img src="/assets/media/logos/startsafe_logo.png" style={{ width: 55, height: 55 }} />
          )}
        </Box>
        <Box sx={{ flexGrow: 1, py: ldpi ? 2 : 1.5 }}>
          {mainMenuList.map((item) =>
            lgUp ? (
              <NavItemLargeScreen
                showMenuName={showMenuName}
                setShowMenuName={setShowMenuName}
                key={item.title}
                icon={item.icon}
                href={item.href}
                title={item.title}
              />
            ) : (
              <NavItemSmallScreen key={item.title} icon={item.icon} href={item.href} title={item.title} onClose={onClose} />
            ),
          )}
        </Box>
        {showMenuName && (
          <>
            <Divider sx={{ marginX: 2, borderColor: '#2978dc' }} />
            <FlexColumn
              gap={1}
              sx={{
                px: 2,
                py: 3,
                alignItems: 'center',
              }}
            >
              <MuiLink href="mailto:support@startsafe.tech" sx={{ color: 'white' }}>
                Contact support
              </MuiLink>
              <Typography variant="caption" color="white">
                version: {packageJson.version}
              </Typography>
            </FlexColumn>
          </>
        )}
      </Box>
    </>
  );

  if (lgUp) {
    return <Box sx={{ height: '100%', width: showMenuName ? '220px' : '75px', position: 'fixed', left: 0, top: 0 }}>{content}</Box>;
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      disableScrollLock
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.brandBlueBg,
          width: 300,
          borderRadius: 0,
        },
      }}
      sx={{ zIndex: theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideMenu;

const NavItemLargeScreen = ({ href, icon, title, showMenuName, setShowMenuName }: LargeScreenProps) => {
  const dispath = useAppDispatch();
  const ldpi = useIsLowDensity();
  const theme = useTheme();
  const router = useLocation();
  const active = href ? router.pathname.includes(href) : false; // checking includes to show active for all sub pages

  const onNavigate = (route: string) => {
    switch (route) {
      case '/app/reports':
        dispath(resetFilter({ type: FilterSettingsType.REPORT }));
        break;
      case '/app/users':
        dispath(resetFilter({ type: FilterSettingsType.USER }));
        break;
      case '/app/authorities':
        dispath(resetFilter({ type: FilterSettingsType.AUTHORITY }));
        break;
      case '/app/locations':
        dispath(resetFilter({ type: FilterSettingsType.LOCATION }));
        break;
      case '/app/templates':
        dispath(resetFilter({ type: FilterSettingsType.TEMPLATE }));
        break;
      case '/app/assets':
        dispath(resetFilter({ type: FilterSettingsType.ASSET }));
        break;
      case '/app/schedules':
        dispath(resetFilter({ type: FilterSettingsType.SCHEDULE }));
        break;
      case '/app/exception-rules':
        dispath(resetFilter({ type: FilterSettingsType.EXCEPTION_RULES }));
        break;
      case '/app/exception-reports':
        dispath(resetFilter({ type: FilterSettingsType.EXCEPTION_REPORTS }));
        break;
      default:
        break;
    }
  };

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        paddingLeft: 2,
        position: 'relative',
        '&:before': active
          ? {
              content: '""',
              backgroundColor: 'white',
              width: '28px',
              height: '28px',
              position: 'absolute',
              right: '0',
              top: '-28px',
              zIndex: 2,
            }
          : {},
        '&:after': active
          ? {
              content: '""',
              backgroundColor: 'white',
              width: '28px',
              height: '28px',
              position: 'absolute',
              right: '0',
              bottom: '-28px',
              zIndex: 2,
            }
          : {},
      }}
    >
      <Link to={href} onClick={() => onNavigate(href)} style={{ width: '100%', textDecoration: 'none', color: 'white' }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            position: 'relative',
            backgroundColor: active ? 'white' : 'transparent',
            borderTopLeftRadius: 28,
            borderBottomLeftRadius: 28,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            color: active ? theme.palette.text.primary : 'white',
            fontWeight: active ? 'bold' : 'regular',
            justifyContent: 'flex-start',
            paddingLeft: ldpi ? (showMenuName ? 4 : 4) : 2.5,
            paddingRight: 1,
            py: ldpi ? 3 : 2,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400',
            },
            '&:hover': {
              color: active ? theme.palette.text.primary : theme.palette.text.primary,
              backgroundColor: active ? 'white' : 'transparent',
            },
            '&:before': active
              ? {
                  content: '""',
                  backgroundColor: theme.palette.brandBlueBg,
                  width: '28px',
                  height: '28px',
                  position: 'absolute',
                  right: '0',
                  top: '-28px',
                  zIndex: 3,
                  borderBottomRightRadius: 28,
                }
              : {},
            '&:after': active
              ? {
                  content: '""',
                  backgroundColor: theme.palette.brandBlueBg,
                  width: '28px',
                  height: '28px',
                  position: 'absolute',
                  right: '0',
                  bottom: '-28px',
                  zIndex: 3,
                  borderTopRightRadius: 28,
                }
              : {},
          }}
        >
          <MenuIcons name={icon} active={active} />
          {showMenuName && (
            <Box sx={{ flexGrow: 1, marginLeft: ldpi ? 2.2 : 1.8 }}>
              <Typography variant="body1">{title}</Typography>
            </Box>
          )}
          {active ? (
            <IconButton
              sx={{ ml: 1, p: 0.2 }}
              onClick={() => {
                setShowMenuName(!showMenuName);
              }}
            >
              {showMenuName ? (
                <KeyboardArrowLeftIcon fontSize="medium" sx={{ color: theme.palette.brandBlueBg, fontSize: 22 }} />
              ) : (
                <KeyboardArrowRightIcon fontSize="medium" sx={{ color: theme.palette.brandBlueBg, fontSize: 22 }} />
              )}
            </IconButton>
          ) : null}
        </Box>
      </Link>
    </ListItem>
  );
};

type NavProps = NavLink & {
  onClose: () => void;
};
const NavItemSmallScreen = ({ href, icon, title, onClose }: NavProps) => {
  const theme = useTheme();
  const router = useLocation();
  const active = href ? router.pathname === href : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
        position: 'relative',
      }}
    >
      <Link
        to={href}
        onClick={onClose}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: 'white',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            position: 'relative',
            backgroundColor: active ? 'white' : 'transparent',
            borderRadius: 2,
            color: active ? theme.palette.text.primary : 'white',
            fontWeight: active ? 'bold' : 'normal',
            justifyContent: 'flex-start',
            px: 3,
            py: '1.4rem',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: active ? theme.palette.text.primary : 'white',
            },
            '&:hover': {
              color: active ? theme.palette.text.primary : theme.palette.text.primary,
              backgroundColor: active ? 'white' : 'transparent',
            },
          }}
        >
          <MenuIcons name={icon} active={active} />
          <Box sx={{ flexGrow: 1, marginLeft: 1.5 }}>
            <Typography variant="body1">{title}</Typography>
          </Box>
        </Box>
      </Link>
    </ListItem>
  );
};

const MenuIcons = ({ name, active }: { name: string; active: boolean }) => {
  const color = active ? '#3186F3' : '#FFF';
  switch (name) {
    case 'dashboards':
      return <DashboardIcon color={color} />;
    case 'users':
      return <UserIcon color={color} />;
    case 'authorities':
      return <AuthorityIcon color={color} />;
    case 'locations':
      return <LocationIcon color={color} />;
    case 'templates':
      return <TemplateIcon color={color} />;
    case 'assets':
      return <AssetIcon color={color} />;
    case 'reports':
      return <ReportIcon color={color} />;
    case 'exception-reports':
      return <ExceptionReportIcon color={color} />;
    case 'schedules':
      return <ScheduleIcon color={color} />;
    case 'exception-rules':
      return <ExceptionRuleIcon color={color} />;
    default:
      return <></>;
  }
};
