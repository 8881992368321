import { BaseViewDto } from './base.dto';
import { AssetMin, CommonMin, UserMin } from './common.dto';
import { OperatorV2 } from './filter.dto';
import { DateRangeType, ElementDurationTypeEnum } from './template.dto';

export enum MatchType {
  ALL = 'all',
  ANY = 'any',
}

export enum ExceptionGroupMethodEnum {
  INDIVIDUAL = 'individual',
  GROUP = 'group',
}

export interface ExceptionRules {
  questionId: string;
  constrain: OperatorV2;
  value: string | number | boolean;
  dateRangeType?: DateRangeType;
  durationType?: ElementDurationTypeEnum;
}

export interface ExceptionRuleCreateDto {
  locationId: string;
  templateId: string;
  description: string;
  assetIds: string[];
  matchType: MatchType;
  groupMethod?: ExceptionGroupMethodEnum;
  rules: ExceptionRules[];
  generateException: boolean;
  assignedUserIds: string[];
  sendNotification: boolean;
  notifyUserIds: string[];
}

export interface ExceptionRuleUpdateDto extends ExceptionRuleCreateDto {
  id: string;
}

export interface ExceptionRuleViewDto extends BaseViewDto {
  organisation: CommonMin;
  location: CommonMin;
  template: CommonMin;
  description: string;
  assets: AssetMin[];
  matchType: MatchType;
  groupMethod?: ExceptionGroupMethodEnum;
  rules: ExceptionRules[];
  generateException: boolean;
  assignedUsers: UserMin[];
  sendNotification: boolean;
  notifyUsers: UserMin[];
  noofRules?: number;
  createdAt: Date;
  updatedAt: Date;
}
