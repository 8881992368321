import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ChangeEvent, useState } from 'react';

interface FieldProps {
  field: string;
  label: string;
  placeholder: string;
  value?: string | number | null;
  touched?: boolean | null;
  error?: string | null;
  disabled?: boolean;
  type?: string;
  handleChange: (e: string | ChangeEvent) => void;
  handleBlur: (e: string | ChangeEvent) => void;
}

const CrudStringField = (props: FieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputLabel sx={{ paddingBottom: 1 }} data-automation={`${props.field}-title`}>
        {props.label}
      </InputLabel>
      <FormControl fullWidth>
        <OutlinedInput
          id={props.field}
          name={props.field}
          placeholder={props.placeholder}
          type={showPassword ? 'text' : props.type}
          fullWidth
          autoComplete="off"
          value={props.value}
          // error={props.error ? true : false}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          disabled={props.disabled}
          sx={{
            height: '5rem',
            '.MuiInputBase-input': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
          }}
          endAdornment={
            <>
              {props.type === 'password' && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                </InputAdornment>
              )}
            </>
          }
        />
      </FormControl>
      {props.touched && props.error ? (
        <FormHelperText error data-automation={`error-${props.field}`}>
          {props.error}
        </FormHelperText>
      ) : null}
    </>
  );
};

export default CrudStringField;
