import { OptionViewDto } from '../../components/elements/crud-elements/CrudDropdownFieldNew';
import { BaseViewDto } from './base.dto';
import { CommonMin, FileCreateAndUpdateDto, FileViewDto, SimpleViewDto } from './common.dto';
import { OperatorV2 } from './filter.dto';
import { OrganisationViewDto } from './organisation.dto';

export enum TemplateStatus {
  PUBLISH = 'publish',
  DRAFT = 'draft',
}

export enum ReportImageSize {
  small = 'small',
  large = 'large',
}

export enum ValueDataType {
  string = 'string',
  issue = 'issue',
  options = 'options',
  numeric = 'numeric',
  datetime = 'datetime',
}

export enum CalculationType {
  addition = 'addition',
  multiplication = 'multiplication',
  average = 'average',
  min = 'min',
  max = 'max',
}

// TODO move to template builder dto place
export interface QuestionList {
  title: string;
  rangeTitle?: string;
  elementId: string;
  valueDataType: ValueDataType;
  options?: OptionViewDto[];
  config?: Config;
  dateRangeType?: DateRangeType;
  questionDateRangeType?: DateRangeType;
  durationType?: ElementDurationTypeEnum;
  questionDurationType?: ElementDurationTypeEnum;
  hideForceRequired?: boolean;
  fieldType: FieldType;
}

export enum CalculationGroupActionEnum {
  addition = 'addition',
  subtraction = 'subtraction',
  multiplication = 'multiplication',
  division = 'division',
  total = 'total',
}

export enum FieldType {
  page = 'page',
  label = 'label',
  singleLine = 'singleLine',
  multiLine = 'multiLine',
  numeric = 'numeric',
  checkbox = 'checkbox',
  radio = 'radio',
  dropdown = 'dropdown',
  datetime = 'datetime',
  issue = 'issue',
  fileUpload = 'fileUpload',
  imageUpload = 'imageUpload',
  signature = 'signature',
  oilLevel = 'oilLevel',
  fuel = 'fuel',
  temperature = 'temperature',
  calculation = 'calculation',
  duration = 'duration',
  customPicker = 'customPicker',
}

interface ValidationLogic {
  isRequired: boolean;
  rules?: ValidationLogicRule[];
}

// FIXME: rename to ScoringLogicRule
export interface ScoringRule {
  dateRangeType?: DateRangeType;
  durationType?: ElementDurationTypeEnum;
  code?: OperatorV2;
  value?: string | number | boolean;
  score: number;
}

// FIXME: Rename to ScoringLogic
export interface Scoring {
  rules: ScoringRule[];
}

export enum ValidationRuleType {
  question = 'question',
  value = 'value',
}

export enum DateRangeType {
  FROM = 'from',
  TO = 'to',
}

export interface ValidationLogicRule {
  code: OperatorV2 | null;
  value?: string | number | boolean;
  elementId?: string;
  ruleType: ValidationRuleType;
  dateRangeType?: DateRangeType;
  questionDateRangeType?: DateRangeType;
  durationType?: ElementDurationTypeEnum;
  questionDurationType?: ElementDurationTypeEnum;
}
export enum MatchTypeEnum {
  ANY = 'Any',
  ALL = 'All',
}

export enum ConditionalLogicVisibility {
  SHOW = 'Show',
  HIDE = 'Hide',
}

interface ConditionalLogic {
  // TODO check with api and remove
  isHidden: boolean;
  matchType: MatchTypeEnum;
  visibility: ConditionalLogicVisibility;
  rules: ConditionalLogicRule[];
}

export interface ConditionalLogicRule {
  code?: OperatorV2;
  value: string | number | boolean;
  elementId?: string;
  questionDateRangeType?: DateRangeType;
  questionDurationType?: ElementDurationTypeEnum;
  // TODO check and remove
  // valueTo?: number;
}

export interface ForceRequiredLogicRule {
  dateRangeType?: DateRangeType;
  durationType?: ElementDurationTypeEnum;
  code: OperatorV2 | null;
  value: string | number | boolean;
  // elementId?: string;
}

export interface ForceRequiredLogicOtherElements {
  rules: ForceRequiredLogicRule[];
  elementIds: string[];
  matchType: MatchTypeEnum;
}

export interface ForceRequiredLogicCommentsPictures {
  isRequired: boolean;
  matchType?: MatchTypeEnum;
  rules?: ForceRequiredLogicRule[];
}

interface ForceRequiredLogic {
  otherElements?: ForceRequiredLogicOtherElements[];
  comments?: ForceRequiredLogicCommentsPictures;
  pictures?: ForceRequiredLogicCommentsPictures;
}

// TODO update name as option is reserverd and not being imported automatically
export interface SelectOption {
  value: string;
  title: string;
}

interface ConfigOther {
  enabled: boolean;
  required: boolean;
}

interface ConfigComment {
  enabled: boolean;
}

interface ConfigPicture {
  enabled: boolean;
  multiple: boolean;
  comment: boolean;
}

interface ConfigFileOption {
  multiple: boolean;
  required: boolean;
  comment: boolean;
}

export enum IssueTypeEnum {
  yesNo = 'yesNo',
  passFail = 'passFail',
}

export interface CalculationGroup {
  type: CalculationType;
  elementIds: string[];
  action: CalculationGroupActionEnum;
}

export enum DurationElementDisplayFormatEnum {
  FORMAT_1 = '{DAY} days, {HOUR} hour, {MINUTE} minute, {SECOND} second',
  FORMAT_2 = '{DAY}d, {HOUR}h, {MINUTE}m, {SECOND}s',
  FORMAT_3 = '{DAY}:{HOUR}:{MINUTE}:{SECOND}',
  FORMAT_4 = '{DAY}-{HOUR}-{MINUTE}-{SECOND}',
  FORMAT_5 = '{DAY}-{HOUR}:{MINUTE}:{SECOND}',
}

export enum ElementDurationTypeEnum {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
}

export enum UiPickerTypeEnum {
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
}

export enum UiDatePickerFormatEnum {
  DATE_1 = 'DD/MM/YYYY',
  DATE_2 = 'MM/DD/YYYY',
  MONTH_1 = 'DD/MM/YYYY HH:mm',
  MONTH_2 = 'DD/MM/YYYY hh:mm a',
  MONTH_3 = 'MM/DD/YYYY HH:mm',
  MONTH_4 = 'MM/DD/YYYY hh:mm a',
  TIME_1 = 'HH:mm',
  TIME_2 = 'hh:mm a',
}

export interface ConfigDuration {
  enableDay: boolean;
  enableHour: boolean;
  enableMinute: boolean;
  enableSecond: boolean;
  displayFormat?: DurationElementDisplayFormatEnum;
}

export interface Config {
  placeholder?: string;
  isNegative?: boolean;
  isDecimal?: boolean;
  allowMultiSelect?: boolean;
  options?: SelectOption[];
  issues?: string[];
  issueRequired?: boolean;
  questions?: string[];
  issueTitle?: string;
  date?: boolean;
  time?: boolean;
  range?: boolean;
  other?: ConfigOther;
  comment: ConfigComment;
  picture: ConfigPicture;
  fileOption?: ConfigFileOption;
  showElementWhen?: boolean;
  showNegativeOn?: boolean;
  startText?: string;
  issueElementType?: IssueTypeEnum;
  endText?: string;
  startValue?: number;
  endValue?: number;
  step?: number;
  hideOnPDFReport?: boolean;
  calculationGroup?: CalculationGroup[];
  duration?: ConfigDuration;
  uiPickerType?: UiPickerTypeEnum;
  uiDatePickerFormat?: UiDatePickerFormatEnum;
}

export interface TemplateElement extends TemplateElementView {
  id?: string;
  chosen?: boolean;
  selected?: boolean;
  metaTbLeftPanelUpdatedAt?: number;
}

export interface TemplatePageCreate extends Omit<TemplatePage, 'headerImages'> {
  headerImages: FileCreateAndUpdateDto[];
}

export interface TemplatePage extends Omit<TemplatePageView, 'elements'> {
  id?: string;
  chosen?: boolean;
  selected?: boolean;
  elements: TemplateElement[];
  metaTbLeftPanelUpdatedAt?: number;
}

export interface TemplatePageView {
  elementId: string;
  title: string;
  elements: TemplateElementView[];
  headerImages: FileViewDto[];
}

export interface TemplateElementView {
  elementId: string;
  title: string;
  description: string;
  fieldType: FieldType;
  valueDataType: ValueDataType;
  validationLogic?: ValidationLogic;
  conditionalLogic: ConditionalLogic;
  forceRequiredLogic?: ForceRequiredLogic;
  scoringLogic?: Scoring;
  config: Config;
}

export interface TemplateViewDto extends BaseViewDto {
  name: string;
  description: string;
  organisation: OrganisationViewDto;
  pages: TemplatePageView[];
  locations: CommonMin[];
  headerImages: FileViewDto[];
  versions: string[];
  currentVersion: string;
  reportImageSize: ReportImageSize;
  status: TemplateStatus;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  isScoringPresent: boolean;
  allowedNoOfElements: number;
  isDeleted?: boolean;
}

export interface TemplateCreateInfo {
  name: string;
  description: string;
  headerImages: FileCreateAndUpdateDto[];
  reportImageSize: ReportImageSize;
  locationIds: string[];
}

export interface TemplateDuplicateDto {
  sourceTemplateId: string;
  newTemplateName: string;
}

export interface TemplateUpdateInfo extends TemplateCreateInfo {
  id: string;
  confirmUpdate?: boolean;
}

export interface TemplateUpsertDto {
  id: string;
  pages: TemplatePageCreate[];
  status: TemplateStatus;
  confirmRemoveExceptionRule?: boolean;
}

export interface SimpleTemplateViewDto extends SimpleViewDto {
  locationIds: string[];
  status: TemplateStatus;
  isDeleted: boolean;
  currentVersion: string;
  versions: string[];
}

export enum IssueAnswerEnum {
  PASS = 'pass',
  FAIL = 'fail',
  YES = 'yes',
  NO = 'no',
}
