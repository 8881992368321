import { SetStateAction, useEffect, useState } from 'react';

import { SimpleViewDto } from '../../../common/dto/common.dto';
import { FilterValueTypes } from '../../../hoc/WithFilter';
import SearchableAutocompleteProvider from '../value-providers/SearchableAutocompleteProvider';

type StaticSearchFieldProps = {
  value?: FilterValueTypes;
  onValueChange: (vals: boolean) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
};

const optioneYesValue: SimpleViewDto = { id: 'yes', name: 'Yes' };
const optioneNoValue: SimpleViewDto = { id: 'no', name: 'No' };

const getYesNoSelectValue = (value: FilterValueTypes | undefined) => {
  if (value == null) {
    return undefined;
  }
  if (value) {
    return optioneYesValue;
  }
  return optioneNoValue;
};

const YesNoSelect = (props: StaticSearchFieldProps) => {
  const yesNoOptions: SimpleViewDto[] = [optioneYesValue, optioneNoValue];
  const [cValue, setCValue] = useState<SimpleViewDto | undefined>(getYesNoSelectValue(props.value));
  const { setError, setShowError } = props;
  useEffect(() => {
    if (!cValue) {
      setError(`Select atleast 1 option`);
    } else {
      setShowError(false);
      setError(null);
      setYesNoSelectValue(cValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  const setYesNoSelectValue = (val: SimpleViewDto) => {
    if (val.id === 'yes') {
      props.onValueChange(true);
    } else {
      props.onValueChange(false);
    }
  };

  return (
    <SearchableAutocompleteProvider
      loading={false}
      values={cValue}
      options={yesNoOptions}
      onValueChange={(vals) => setCValue(vals as SimpleViewDto)}
      handleClose={props.handleClose}
    />
  );
};

export default YesNoSelect;
