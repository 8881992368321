import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';

import { ConstrainListV2 } from '../../../common/constants/filter';
import { ConstrainV2, FilterFieldDataType, OperatorV2 } from '../../../common/dto/filter.dto';
import WithFilter, { WithFilterAddedProps } from '../../../hoc/WithFilter';
import ConstrainOptionSelection from '../ConstrainSelection';
import YesNoSelect from '../template-element/YesNoSelect';
import NumberValueProvider from '../value-providers/NumberValueProvider';
import StringValueProvider from '../value-providers/StringValueProvider';

const constrainOptions = ConstrainListV2.filter((c) => c.applicableDataTypes.includes(FilterFieldDataType.string) && c.id !== OperatorV2.isNotEmpty);
interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  tempFutureProp?: boolean;
}
const FilterString = (props: Props & WithFilterAddedProps) => {
  // can be string or number
  const [value, setValue] = useState<string | number | boolean | undefined>(props.currentValue as string | number);
  const [constrain, setConstrain] = useState<ConstrainV2>(
    props.currentConstrain ?? {
      id: OperatorV2.strContains,
      title: 'Contains',
      applicableDataTypes: [FilterFieldDataType.string],
    },
  );

  const { setError, setFilterValue, setShowError, handleClose } = props;

  useEffect(() => {
    if (value == null) {
      setError('Value cannot be empty');
    } else if (typeof value === 'string' && value.trim().length === 0 && ![OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(constrain.id)) {
      setError('Value cannot be empty');
    } else if (typeof value === 'number' && Number.isNaN(value) && ![OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(constrain.id)) {
      setError('Value must be valid number');
    } else {
      setShowError(false);
      setError(null);
      if ([OperatorV2.strLengthMin, OperatorV2.strLengthMax].includes(constrain.id)) setFilterValue(Number(value), constrain);
      else setFilterValue(value, constrain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, constrain, setError, setShowError]);

  const onChangeConstrain = () => {
    setValue(undefined);
  };

  return (
    <>
      <ConstrainOptionSelection
        constrain={constrain}
        setConstrain={setConstrain}
        options={constrainOptions}
        setShowValueSection={props.setShowValueSection}
        onChangeConstrain={onChangeConstrain}
      />
      <Divider />
      {props.showValueSection ? (
        [OperatorV2.strLengthMin, OperatorV2.strLengthMax].includes(constrain.id) ? (
          <NumberValueProvider value={value as number} onValueChange={(val) => setValue(val)} />
        ) : [OperatorV2.strIsEmail, OperatorV2.isEmpty, OperatorV2.isNotEmpty].includes(constrain.id) ? (
          <YesNoSelect
            value={value}
            onValueChange={(vals) => setValue(vals as boolean)}
            setError={setError}
            setShowError={setShowError}
            handleClose={handleClose}
          />
        ) : (
          <StringValueProvider value={value as string} onValueChange={(val) => setValue(val)} />
        )
      ) : null}
    </>
  );
};

export default WithFilter(FilterString);
