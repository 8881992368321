import * as Sentry from '@sentry/react';

// export const sentrySetUserContext = (payloadInfo: UserInfoState) => {
//   log.info('Setting user context', { payloadInfo });
//   Sentry.setUser({
//     id: payloadInfo.id,
//     name: `${payloadInfo.firstname} ${payloadInfo.lastname}`,
//     firstname: payloadInfo.firstname,
//     lastname: payloadInfo.lastname,
//     tId: payloadInfo.tId,
//   });
// };

export const sentrySetOrganisationContext = (currentOrganisation: { id: string; name: string }) => {
  Sentry.setTag('organisation', currentOrganisation.name);
  Sentry.setContext('Organisation Information', {
    id: currentOrganisation.id,
    name: currentOrganisation.name,
  });
};

export const sentrySetExtra = (key: string, extra: Record<string, any>) => {
  Sentry.setExtra(`debug-data-${key}`, JSON.stringify(extra));
};

// export const sentrySetExtraSimple = (key: string, extra: string | number) => {
//   Sentry.setExtra(`debug-data-${key}`, extra);
// };

type SentrySeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
export const sentryAddBreadcrumb = (
  logLevel: SentrySeverityLevel,
  type: 'debug' | 'info' | 'error' | 'navigation' | 'user',
  category: 'log' | 'render' | 'interceptor' | 'navigation' | 'interaction',
  message: string,
  args?: Record<string, any>,
) => {
  const parsedArgs: Record<string, any> = {};
  if (args) {
    for (const key of Object.keys(args)) {
      parsedArgs[key] = JSON.stringify(args[key]);
    }
  }

  Sentry.addBreadcrumb({
    level: logLevel,
    category,
    message,
    type,
    data: parsedArgs ? { ...parsedArgs } : undefined,
  });
};
