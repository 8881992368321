import { Box } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';

import { FilterDateRangeValue } from '../../../hoc/WithFilter';

type Props = {
  value: FilterDateRangeValue;
  onValueChange: (val: FilterDateRangeValue) => void;
};

const DateRangeProvider = (props: Props) => {
  const [selectedDateRange, setSelectedDateRange] = useState<Range[]>([
    {
      startDate: props.value.from ? moment(props.value.from).toDate() : undefined,
      endDate: props.value.to ? moment(props.value.to).toDate() : undefined,
      key: 'selection',
    },
  ]);

  const handleSelect = (date: RangeKeyDict) => {
    setSelectedDateRange([date.selection]);
    if (date.selection && date.selection.startDate && date.selection.endDate) {
      props.onValueChange({
        from: moment(date.selection.startDate).startOf('day').toDate(),
        to: moment(date.selection.endDate).endOf('day').toDate(),
      });
    }
  };

  return (
    <Box sx={{ px: 0, py: 0 }}>
      {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
      <DateRangePicker className="date-range-picker" ranges={selectedDateRange} onChange={handleSelect} data-automation="12344" />
    </Box>
  );
};

export default DateRangeProvider;
