import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { NotificationViewDto } from '../../common/dto/notification.dto';
import { RootState } from '../store';

interface NotificationState {
  showNotification: boolean;
  notifications: NotificationViewDto[];
}

const initialState: NotificationState = {
  showNotification: false,
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    manageNotification: (state, action: PayloadAction<boolean>) => {
      state.showNotification = action.payload;
    },
    setNotifications: (state, action: PayloadAction<NotificationViewDto[]>) => {
      state.notifications = action.payload;
    },
  },
});

export const { manageNotification, setNotifications } = notificationSlice.actions;

const stateSelf = (state: RootState) => state.notification;
export const notificationsSelector = createSelector(stateSelf, (state) => state.notifications);

export default notificationSlice.reducer;
