import { Box, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';

import { DateRange } from '../../../../common/dto/formdata.dto';
import { FlexRow } from '../../../styled/Flex';
import { DateMode } from '../FormDataDateTimeField';
import FormDataErrorField from '../FormDataErrorField';

const getDateFieldValue = (value: string | DateRange, name: string, type: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_name, range] = name.split('.');

  if (value) {
    let date: string | undefined;
    if (range) {
      const rangeValue = value as DateRange;
      if (range === 'from') {
        date = rangeValue.from;
      } else {
        date = rangeValue.to;
      }
    } else {
      date = value as string;
    }
    if (date) {
      return moment(date);
    }
  }
  return undefined;
};

// const getMinDate = (elementValue: string | DateRange, minName: string, type: DateMode, range: boolean | undefined, index: number): Moment | undefined => {
//   let value: Moment | undefined;
//   if (range && index === 1) {
//     value = getDateFieldValue(elementValue, minName, type);
//   }
//   return value;
// };

// const getMaxDate = (elementValue: string | DateRange, maxName: string, type: DateMode, range: boolean | undefined, index: number): Moment | undefined => {
//   let value: Moment | undefined;
//   if (range && index === 0) {
//     value = getDateFieldValue(elementValue, maxName, type);
//   }
//   return value;
// };

export const getValue = (elementValue: string | DateRange, name: string, type: string): Moment | undefined => {
  const momentValue: Moment | undefined = getDateFieldValue(elementValue, name, type);
  return momentValue;
};

export interface CustomDateProps {
  names: string[];
  placeholder: string | undefined;
  elementValue: string | DateRange;
  type: DateMode;
  onChange: (date: Moment | null, name: string) => void;
  // range: boolean | undefined;
  format: string;
  disabled: boolean;
  error?: string[] | null;
  touched?: boolean | null;
  timezone?: string;
  // errors?: FormDataFieldCommonProps['errors'];
}
const CustomDateTimePicker = (props: CustomDateProps) => {
  const theme = useTheme();
  const isAmPm = useMemo(() => {
    const lastLetter = props.format.slice(-1);
    if (['a', 'A'].includes(lastLetter)) {
      return true;
    }
    return false;
  }, [props.format]);

  return (
    <Box>
      <FlexRow columnGap={2}>
        {props.names.map((name, index) => (
          <Box key={name}>
            <DateTimePicker
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              ampm={isAmPm}
              desktopModeMediaQuery={theme.breakpoints.up('md')}
              value={getValue(props.elementValue, name, props.type)}
              onChange={(date: Moment | null) => props.onChange(date, name)}
              format={props.format}
              // minDateTime={getMinDate(props.elementValue, props.names[0], props.type, props.range, index)} // TODO plugin not support min/max date for undefined value
              // maxDateTime={getMaxDate(props.elementValue, props.names[1], props.type, props.range, index)}
              disabled={props.disabled}
              timezone={props.timezone}
              slotProps={{
                textField: {
                  id: name,
                  name,
                  placeholder: props.placeholder,
                  onBeforeInput: (e) => {
                    if (e?.preventDefault) {
                      e?.preventDefault();
                      e?.stopPropagation();
                    }
                  },
                },
              }}
            />
          </Box>
        ))}
      </FlexRow>
      <FormDataErrorField error={props.error ?? undefined} touched={props.touched ?? undefined} />
    </Box>
  );
};

export default CustomDateTimePicker;
