import { Box, Stack, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Stack>
      <Typography variant="h1" color="mya">
        404 not found
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">Page not found</Typography>
      </Box>
    </Stack>
  );
};

export default NotFound;
