import { Box, LinearProgress, List, ListItem, ListItemIcon, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';

import { DeleteAlertMessage, DeleteParamsDto } from '../../../common/dto/common.dto';
import { CreatedUserWithUsernameResponseDto } from '../../../common/dto/user.dto';
import { withFormik } from '../../../hoc/WithFormik';
import withSideDrawer from '../../../hoc/WithSideDrawer';
import { useDeleteAssetMutation } from '../../../services/asset.service';
import { useDeleteAuthorityMutation } from '../../../services/authority.service';
import { useDeleteDashboardMutation } from '../../../services/dashboard.service';
import { useDeleteExceptionRuleMutation } from '../../../services/exception-rules.service';
import { useDeleteLocationMutation } from '../../../services/location.service';
import { ReportDeleteParamsDto, useDeleteFormDataMutation } from '../../../services/report-update.service';
import { useDeleteScheduleMutation } from '../../../services/schedules-rules.service';
import { useDeleteTemplateMutation } from '../../../services/template.service';
import { useDeleteUserMutation, useReinviteUserMutation, useResetUserMFAMutation, useResetUserPasswordMutation } from '../../../services/user.service';
import { createLogger } from '../../../utils/logger';
import ViewUserPassword from '../../modal-components/ViewUserPassword';
import ReassignUser from './ReassignUser';

const logger = createLogger('ConfirmDialog');

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export enum DeleteActionType {
  Asset = 'asset',
  User = 'user',
  UserResetPassword = 'reset-password',
  UserResetMFA = 'reset-mfa',
  Authority = 'authority',
  Template = 'template',
  Location = 'location',
  Report = 'report',
  ExceptionRules = 'exception-rule',
  Schedule = 'schedule',
  Dashboard = 'dashboard',
  ReinviteUser = 'reinvite-user',
}

interface ConfirmDialogProps {
  title: string;
  message: string;
  isOpen: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onConfirm: () => void;
  onClose: () => void;
  actionType?: DeleteActionType;
  actionId?: string | string[] | null;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const theme = useTheme();
  const [apiAlertMsg, setApiAlertMsg] = useState<DeleteAlertMessage[] | string | null>(null);
  const [isConfirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [isLodaing, setLoading] = useState<boolean>(false);
  const [hideDelete, setHideDelete] = useState<boolean>(false);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();

  const [deleteAsset] = useDeleteAssetMutation();
  const [deleteAuthority] = useDeleteAuthorityMutation();
  const [deleteTemplate] = useDeleteTemplateMutation();
  const [deleteLocation] = useDeleteLocationMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [resetUserPassword] = useResetUserPasswordMutation();
  const [resetUserMFA] = useResetUserMFAMutation();
  const [deleteExceptionRule] = useDeleteExceptionRuleMutation();
  const [deleteSchedule] = useDeleteScheduleMutation();
  const [deleteFormData] = useDeleteFormDataMutation();
  const [deleteDashboard] = useDeleteDashboardMutation();
  const [reinviteUser] = useReinviteUserMutation();

  const ViewUserPasswordWithFormik = withFormik(ViewUserPassword);
  const ViewUserPasswordWithSideDrawer = withSideDrawer(ViewUserPasswordWithFormik);
  const [isViewPasswordDrawerOpen, setViewPasswordDrawerOpen] = useState(false);
  const [viewPasswordData, setViewPasswordData] = useState<CreatedUserWithUsernameResponseDto | null>(null);

  const onDelete = (isConfirm?: boolean) => {
    setLoading(true);
    switch (props.actionType) {
      case DeleteActionType.Asset:
        confirmAsset(isConfirm);
        break;
      case DeleteActionType.Authority:
        confirmAuthority(isConfirm);
        break;
      case DeleteActionType.Template:
        confirmTemplate(isConfirm);
        break;
      case DeleteActionType.Location:
        confirmLocation(isConfirm);
        break;
      case DeleteActionType.User:
        confirmUser(isConfirm);
        break;
      case DeleteActionType.UserResetPassword:
        confirmUserResetPassword(isConfirm);
        break;
      case DeleteActionType.UserResetMFA:
        confirmUserResetMFA(isConfirm);
        break;
      case DeleteActionType.ExceptionRules:
        confirmExceptionRules(isConfirm);
        break;
      case DeleteActionType.Schedule:
        confirmSchedule(isConfirm);
        break;
      case DeleteActionType.Report:
        confirmReport(isConfirm);
        break;
      case DeleteActionType.Dashboard:
        confirmDashboard();
        break;
      case DeleteActionType.ReinviteUser:
        confirmReinviteUser();
        break;

      default:
        onConfirm();
        break;
    }
  };

  const confirmDashboard = () => {
    if (props.actionId) {
      deleteDashboard(props.actionId as string)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          if (err.data && err.data.status === 403) {
            setHideDelete(true);
          }
          setApiAlertMsg(err.data.message);
          setLoading(false);
        });
    }
  };

  const confirmReport = (isConfirm?: boolean) => {
    if (props.actionId) {
      const params: ReportDeleteParamsDto = {
        selectedIds: props.actionId as string[],
      };
      deleteFormData(params)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmSchedule = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteSchedule(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmExceptionRules = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteExceptionRule(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmUser = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true, reassignUserId: userId };
      }
      deleteUser(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            setShowUsers(true);
            if (!isConfirmDelete) setConfirmDelete(true);
          } else if (err.status === 406) {
            setHideDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmUserResetPassword = (isConfirm?: boolean) => {
    if (props.actionId) {
      resetUserPassword(props.actionId as string)
        .unwrap()
        .then((res: CreatedUserWithUsernameResponseDto) => {
          setViewPasswordData(res);
          setViewPasswordDrawerOpen(true);
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          setLoading(false);
        });
    }
  };

  const confirmReinviteUser = (isConfirm?: boolean) => {
    if (props.actionId) {
      reinviteUser(props.actionId as string)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          setLoading(false);
        });
    }
  };

  const confirmUserResetMFA = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true, reassignUserId: userId };
      }
      resetUserMFA(props.actionId as string)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          setLoading(false);
        });
    }
  };

  const confirmLocation = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteLocation(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            setHideDelete(true);
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmTemplate = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteTemplate(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.status === 403) {
            setHideDelete(true);
          } else if (err.data && err.data.error === 'DeleteNotAllowed') {
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmAuthority = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteAuthority(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.error === 'DeleteNotAllowed') {
            setHideDelete(true);
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const confirmAsset = (isConfirm?: boolean) => {
    if (props.actionId) {
      const deleteParams: DeleteParamsDto = {
        id: props.actionId as string,
      };
      if (isConfirm) {
        deleteParams.params = { confirmDelete: true };
      }
      deleteAsset(deleteParams)
        .unwrap()
        .then(() => {
          onConfirm();
        })
        .catch((err) => {
          setApiAlertMsg(err.data.message);
          if (err.data && err.data.status === 403) {
            setHideDelete(true);
          } else if (err.data && err.data.error === 'DeleteNotAllowed') {
            if (!isConfirmDelete) setConfirmDelete(true);
          }
          setLoading(false);
        });
    }
  };

  const resetState = () => {
    setApiAlertMsg(null);
    setConfirmDelete(false);
    setLoading(false);
    setHideDelete(false);
    setShowUsers(false);
    setUserId(undefined);
  };

  const onConfirm = () => {
    resetState();
    props.onConfirm();
  };

  const onCancel = () => {
    resetState();
    props.onClose();
  };

  return (
    <div>
      <Dialog
        data-automation={`confirm-dialog-${props.title.toLowerCase().replaceAll(' ', '-')}`}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            [theme.breakpoints.up('md')]: {
              minWidth: '400px',
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        }}
      >
        <DialogTitle data-automation="dialog-title">{props.title}</DialogTitle>
        <DialogContent sx={{ px: 3, py: 1 }}>
          {apiAlertMsg === null ? (
            <DialogContentText id="alert-dialog-slide-description"> {props.message} </DialogContentText>
          ) : (
            <>
              {typeof apiAlertMsg === 'string' ? (
                <DialogContentText id="alert-dialog-slide-description" color="error">
                  {apiAlertMsg}
                </DialogContentText>
              ) : (
                <List>
                  {apiAlertMsg.map((error, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: 20 }}>
                        <Box sx={{ borderRadius: 50, width: 10, height: 10, backgroundColor: '#3186f3' }} />
                      </ListItemIcon>
                      <Typography variant="body2" color="error">
                        {error.msg}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
          {showUsers && <ReassignUser actionId={props.actionId as string | undefined} userId={userId} setUserId={setUserId} />}
          {isLodaing && <LinearProgress />}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {hideDelete === false && (
            <>
              {showUsers ? (
                <Button disabled={!userId} onClick={() => onDelete(isConfirmDelete)} variant="contained" size="small">
                  Reset User
                </Button>
              ) : (
                <Button
                  disabled={isLodaing}
                  onClick={() => onDelete(isConfirmDelete)}
                  variant="contained"
                  size="small"
                  color="error"
                  data-automation="dialog-delete-btn"
                >
                  {isConfirmDelete === false ? (props.confirmButtonText ? props.confirmButtonText : 'Confirm') : 'confirm Delete'}
                </Button>
              )}
            </>
          )}

          <Button onClick={onCancel} variant="outlined" size="small">
            {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <ViewUserPasswordWithSideDrawer
        initialValues={{}}
        validationSchema={{}}
        data={viewPasswordData}
        title="Updated User password" // drawer
        isOpen={isViewPasswordDrawerOpen} // drawer
        disableFooter
        close={() => {
          setViewPasswordDrawerOpen(false);
        }}
      />
    </div>
  );
};

export default ConfirmDialog;
