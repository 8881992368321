import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { previewImageSelector, removePreviewImage } from '../../redux/slices/imagePreview.slice';
import { LoaderVertical } from './Loader';

const PreviewImage = () => {
  const dispatch = useAppDispatch();
  const { imageUrls, index } = useAppSelector(previewImageSelector);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [image, setImage] = useState<undefined | string>();

  const setImageUrl = useCallback(
    (imgIndex: number) => {
      const imageUrl = imageUrls.length > 0 ? imageUrls[imgIndex] ?? undefined : undefined;
      setImage(imageUrl);
    },
    [imageUrls],
  );

  useEffect(() => {
    const imgIndex = index != null ? index : 0;
    setPreviewIndex(imgIndex);
    setImageUrl(imgIndex);
  }, [imageUrls, index, setImageUrl]);

  const closePreview = () => {
    dispatch(removePreviewImage());
  };
  const showPrev = () => {
    const imgIndex = previewIndex - 1;
    setPreviewIndex(imgIndex);
    setImageUrl(imgIndex);
  };
  const showNext = () => {
    const imgIndex = previewIndex + 1;
    setPreviewIndex(imgIndex);
    setImageUrl(imgIndex);
  };

  const totalImage = imageUrls.length;
  return (
    <Dialog
      maxWidth={false}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          borderRadius: '10px',
          position: 'static',
        },
      }}
      open={imageUrls.length > 0 && image !== undefined}
      onClose={closePreview}
    >
      <DialogContent sx={{ p: 0 }}>
        {image ? (
          <Box sx={{ display: 'flex', backgroundColor: '#fff' }}>
            <img crossOrigin="anonymous" style={{ maxWidth: '100%', maxHeight: '100%' }} src={image} />
          </Box>
        ) : (
          <Box sx={{ backgroundColor: '#FFF' }}>
            <LoaderVertical />
          </Box>
        )}
        <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={() => closePreview()} sx={{ background: '#c2d1d9', '&:hover': { background: '#c2d1d9' } }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {previewIndex > 0 && (
          <Box sx={{ position: 'absolute', left: 10, top: '50%' }}>
            <IconButton onClick={() => showPrev()} sx={{ background: '#c2d1d9', '&:hover': { background: '#c2d1d9' } }}>
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
        )}
        {totalImage > previewIndex + 1 && (
          <Box sx={{ position: 'absolute', right: 10, top: '50%' }}>
            <IconButton onClick={() => showNext()} sx={{ background: '#c2d1d9', '&:hover': { background: '#c2d1d9' } }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PreviewImage;
