import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, ButtonGroup, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import { MatchType } from '../../common/dto/exception-rule.dto';
import { FilterFields, FilterTypes, FilterViewDto } from '../../common/dto/filter.dto';
import Yup from '../../common/types/yup-instance.types';
import { withFormik } from '../../hoc/WithFormik';
import withSideDrawer from '../../hoc/WithSideDrawer';
import { SearchFilterProps } from '../../hook/useSearch';
import FilterForm from '../filter/FilterForm';
import FilterPanel, { FilterParamType, FilterRenderable } from '../filter/FilterPanel';

const FilterPanelWithSideDrawer = withSideDrawer(FilterPanel);

const FilterFormWithFormik = withFormik(FilterForm);
const FilterFormWithSideDrawer = withSideDrawer(FilterFormWithFormik);

interface PageHeaderProps {
  templateId?: string;
  filterTitle?: string;
  filterType: FilterTypes;
  filterElements: FilterRenderable[];
  filter: FilterViewDto | null;
  // onSubmit: (filters: FilterField[]) => void;
  setSearchFilter: (value: React.SetStateAction<SearchFilterProps>) => void;
  searchFilter: SearchFilterProps;
  filterParams: Record<string, FilterParamType>;
  setFilterParams: (value: React.SetStateAction<Record<string, FilterParamType>>) => void;
  updateFilter: (id: string) => void;
  resetFilter: (filter: FilterViewDto | undefined) => void;
}

const FilterBarNew = (props: PageHeaderProps) => {
  const initValue: FilterViewDto = {
    name: '',
    type: props.filterType,
    matchType: MatchType.ALL,
    filterFields: [],
  };
  // const [filterParams, setFilterParams] = useState<Record<string, FilterParamType>>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false);

  const [initialValues, setInitialValues] = useState<FilterViewDto>(initValue);
  const [saveMode, setSaveMode] = useState<'update' | 'create'>('create');

  const formSchema = Yup.object({
    name: Yup.string().required('Please enter name'),
  });

  let noOfFiltersApplied = 0;
  for (const key of Object.keys(props.filterParams)) {
    const fField = props.filterParams[key];
    if (fField.value || fField.constrain) noOfFiltersApplied += 1;
  }

  const filterText = noOfFiltersApplied > 0 ? (noOfFiltersApplied === 1 ? `1 Filter applied` : `${noOfFiltersApplied} Filters applied`) : '';

  const saveFilter = (fields: Record<string, FilterParamType>, matchType: MatchType, filterId?: string) => {
    setIsFilterOpen(false);
    setIsFilterFormOpen(true);
    setSaveMode(filterId ? 'update' : 'create');
    const fieldValues: FilterFields[] = [];
    Object.keys(fields).forEach((fieldKey) => {
      fieldValues.push({
        field: fieldKey,
        data: fields[fieldKey],
      });
    });
    setInitialValues((prev) => {
      return {
        ...prev,
        matchType,
        templateId: props.templateId,
        name: filterId ? (props.filter ? props.filter.name : '') : '',
        filterFields: fieldValues,
      };
    });
  };

  const completeAddEditOperation = (reload: boolean, id?: string) => {
    setIsFilterFormOpen(false);
    if (reload && id) {
      props.updateFilter(id);
    } else {
      setIsFilterOpen(true);
    }
  };

  const clearFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    props.resetFilter(undefined);
    props.setFilterParams({});
    props.setSearchFilter((prev) => {
      return {
        ...prev,
        matchType: MatchType.ALL,
        filterFields: [],
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      };
    });
  };

  const filterId = (props.filter ? props.filter.id : null) as string | null;

  return (
    <>
      <Box>
        <StyledFilterBtnGroup variant="contained" aria-label="filter button" hasFilters={noOfFiltersApplied > 0}>
          <StyledFilterBtn onClick={() => setIsFilterOpen(true)} hasFilters={noOfFiltersApplied > 0} data-automation="filter-btn">
            <FilterAltOutlinedIcon sx={{ fontSize: 18 }} /> <Typography variant="body2">{filterText}</Typography>
          </StyledFilterBtn>
          {noOfFiltersApplied > 0 && (
            <Tooltip title="Clear filter">
              <IconButton onClick={clearFilter} sx={{ pr: 2.5, ml: 0.5 }}>
                <ClearIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
        </StyledFilterBtnGroup>
      </Box>

      <FilterPanelWithSideDrawer
        filterId={filterId}
        filterParams={props.filterParams}
        setFilterParams={props.setFilterParams}
        elements={props.filterElements}
        setSearchFilter={props.setSearchFilter}
        searchFilter={props.searchFilter}
        title={props.filterTitle ?? 'Search'}
        isOpen={isFilterOpen}
        close={() => {
          setIsFilterOpen(false);
        }}
        setFilterOpen={setIsFilterOpen}
        submitLabel="Apply"
        saveFilter={saveFilter}
      />

      <FilterFormWithSideDrawer
        filterId={saveMode === 'update' ? filterId : null}
        // filterFields={filterFields}
        // filterType={props.filterType}
        initialValues={initialValues} // for the actuall form
        validationSchema={formSchema} // for the actuall form
        complete={completeAddEditOperation} // form
        title={filterId ? 'Edit Filter' : 'Create Filter'} // drawer
        isOpen={isFilterFormOpen} // drawer
        close={() => completeAddEditOperation(false)} // drawer
        submitLabel={filterId ? 'Update' : 'Create'} // drawer
      />
    </>
  );
};

export default FilterBarNew;

const StyledFilterBtnGroup = styled(ButtonGroup, { shouldForwardProp: (prop) => prop !== 'hasFilters' })<{ hasFilters: boolean }>(({ theme, hasFilters }) => ({
  backgroundColor: hasFilters ? '#efefef' : 'white',
  borderRadius: hasFilters ? '10px' : '100%',
  // paddingLeft: hasFilters ? theme.spacing(1.5) : '5px',
  // paddingRight: hasFilters ? theme.spacing(1.5) : '5px',
  minHeight: 40,
  boxShadow: 'none',
}));

const StyledFilterBtn = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'hasFilters' })<{ hasFilters: boolean }>(({ theme, hasFilters }) => ({
  borderRadius: 0,
  // backgroundColor: hasFilters ? '#efefef' : 'white',
  // borderRadius: hasFilters ? '10px' : '100%',
  paddingLeft: theme.spacing(2.5),
  paddingRight: hasFilters ? theme.spacing(0) : theme.spacing(2.5),
}));
