import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import jwt_decode from 'jwt-decode';
import { redirect } from 'react-router-dom';

import { TokenResponseDto } from '../common/dto/auth.dto';
import { AppJwtPayload } from '../common/dto/common.dto';
import { clearTokens, updateTokens } from '../redux/slices/accessToken.slice';
import { clearUserInfo, refreshOrganisationsAndAccess, updateProfileDetails, updateStepsPending } from '../redux/slices/userInfo.slice';
import { RootState } from '../redux/store';
import { getBrowserName } from '../utils/browser.utils';
import { createLogger } from '../utils/logger';
import { currentVersion, versionAsNumber } from './utils';

const logger = createLogger('service');

const API_UAA_URI = import.meta.env.VITE_UAA_SERVICE;
const mutex = new Mutex();
const MESSAGES = ['Access token expired', 'Token is not valid', 'Token valid, but refresh required due to change'];

export const baseQueryWithoutAccess = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('app-platform', 'Web');
      headers.set('app-platform-client', getBrowserName());
      headers.set('x-version-name', currentVersion);
      headers.set('x-version-code', versionAsNumber.toString());
      return headers;
    },
  });

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = (getState() as RootState).tokens;
      const { impersonateUserId, currentImpersonateUserOrganisation, administerOrganisation } = (getState() as RootState).admin;
      const org = (getState() as RootState).userInfo.currentOrganisation;
      if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
      if (administerOrganisation?.id) {
        headers.set('app-administering', 'true');
        headers.set('app-organisation-id', administerOrganisation.id);
      } else if (impersonateUserId) {
        headers.set('app-impersonate-user-id', impersonateUserId);
        headers.set('app-organisation-id', currentImpersonateUserOrganisation ? currentImpersonateUserOrganisation.id : '');
      } else {
        headers.set('app-organisation-id', org ? org.id : '');
      }

      headers.set('app-platform', 'Web');
      headers.set('app-platform-client', getBrowserName());
      headers.set('x-version-name', currentVersion);
      headers.set('x-version-code', versionAsNumber.toString());

      return headers;
    },
  });

const customFetchBase =
  (baseUrl: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    logger.interceptor('Fetching', { args, api });
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(baseUrl)(args, api, extraOptions);
    if (result.error?.status === 401 && MESSAGES.includes((result.error?.data as any)?.message)) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const { refreshToken } = (api.getState() as RootState).tokens;

          const refreshResult = await baseQueryWithoutAccess(API_UAA_URI)(
            { url: 'auth/refresh-token', body: { refresh_token: refreshToken }, method: 'POST' },
            api,
            extraOptions,
          );

          if (refreshResult.data) {
            const decoded = jwt_decode<AppJwtPayload>((refreshResult.data as TokenResponseDto).access_token);

            api.dispatch(
              updateTokens({
                tId: decoded.tId,
                accessToken: (refreshResult.data as TokenResponseDto).access_token,
                refreshToken: (refreshResult.data as TokenResponseDto).refresh_token,
              }),
            );
            api.dispatch(
              updateProfileDetails({
                firstname: decoded.firstname,
                lastname: decoded.lastname,
                timezone: decoded.timezone,
              }),
            );
            api.dispatch(
              refreshOrganisationsAndAccess({
                organisations: (refreshResult.data as TokenResponseDto).organisations,
                allOrgAccess: (refreshResult.data as TokenResponseDto).access,
              }),
            );

            const refreshResultData = refreshResult.data as TokenResponseDto;
            api.dispatch(updateStepsPending(refreshResultData.stepsPending));

            // Retry the initial query
            result = await baseQuery(baseUrl)(args, api, extraOptions);
          } else {
            api.dispatch(clearTokens());
            api.dispatch(clearUserInfo());
            // window.location.href = '/auth/login';
            redirect('/auth/login');
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(baseUrl)(args, api, extraOptions);
      }
    } else if (result.error?.status === 401 && (result.error?.data as any)?.message === 'MFA verify required') {
      logger.interceptor('Redirecting to mfa-verify', result.error);
      // window.location.href = '/auth/mfa-verify';
      redirect('/auth/mfa-verify');
    } else if (result.error?.status === 401) {
      logger.interceptor('Redirecting to login', result.error);
      api.dispatch(clearTokens());
      api.dispatch(clearUserInfo());
      // window.location.href = '/auth/login';
      redirect('/auth/login');
    } else if (result.error) {
      logger.interceptor(`${result.error?.status} error`, result.error);
      if (result.error?.status != null && typeof result.error.status === 'number' && ![400, 404].includes(result.error.status)) {
        logger.alert(`Network req failed with statusCode: ${result.error?.status}`, { error: result.error });
      }
    }

    return result;
  };

export default customFetchBase;
