import { NavLink } from '../dto/common.dto';

export const mainMenuList: NavLink[] = [
  {
    href: '/app/dashboard',
    icon: 'dashboards',
    title: 'Dashboard',
  },
  {
    href: '/app/users',
    icon: 'users',
    title: 'Users',
  },
  {
    href: '/app/authorities',
    icon: 'authorities',
    title: 'Authorities',
  },
  {
    href: '/app/locations',
    icon: 'locations',
    title: 'Locations',
  },
  {
    href: '/app/templates',
    icon: 'templates',
    title: 'Templates',
  },
  {
    href: '/app/assets',
    icon: 'assets',
    title: 'Assets',
  },
  {
    href: '/app/reports',
    icon: 'reports',
    title: 'Reports',
  },
  {
    href: '/app/exception-reports',
    icon: 'exception-reports',
    title: 'Exception Reports',
  },
  {
    href: '/app/schedules',
    icon: 'schedules',
    title: 'Schedules',
  },
  {
    href: '/app/exception-rules',
    icon: 'exception-rules',
    title: 'Exception Rules',
  },
];
