import { FilterSortDirection } from './filter.dto';
import { LocationViewDto } from './location.dto';

export interface StatusDto {
  status: boolean;
  message: string;
}

export interface DeleteParamsDto {
  id: string;
  params?: {
    confirmDelete: boolean;
    reassignUserId?: string;
  };
}

export interface DeleteAlertMessage {
  msg: string;
  data: string[];
}

export interface DeleteStatusDto {
  status: boolean;
  data: {
    error: string;
    message: DeleteAlertMessage[] | string;
    statusCode: number;
  };
}

export interface DiscardScheduleSubmissionDto {
  scheduleId: string;
  assetId: string;
  templateId: string;
  locationId: string;
  scheduleExpectedAt?: Date;
  discardedMessage?: string;
}

export type NavLink = {
  href: string;
  icon: string;
  title: string;
};

export class PaginatedResponse<T> {
  page: number;
  limit: number;
  totalRecords: number;
  results: T[];

  constructor(page: number, limit: number) {
    this.page = page;
    this.limit = limit;
    this.results = [];
    this.totalRecords = 0;
  }
}

export class AllDataResponseDto<T> {
  results: T[];

  constructor() {
    this.results = [];
  }
}

export interface SimpleViewDto {
  id: string;
  name: string;
}

export interface SimpleViewWithDeletedDto extends SimpleViewDto {
  isDeleted: boolean;
}

export interface CommonMin {
  id: string;
  name: string;
}

export interface AssetMin extends CommonMin {
  type: string;
  uniqueCode: string;
  isActive: boolean;
  isDeleted: boolean;
}

export interface TemplateMin extends CommonMin {
  isDeleted: boolean;
}

export interface UserMin {
  id: string;
  login: string;
  firstname?: string;
  lastname?: string;
}

export interface NestedLocationViewDto
  extends Pick<LocationViewDto, 'id' | 'name' | 'description' | 'root' | 'parent' | 'noOfUsers' | 'noOfAssets' | 'noOfTemplates'> {
  nestedChildren: NestedLocationViewDto[];
  level: number;
}

export interface AppJwtPayload {
  id: string;
  tId: string;
  firstname?: string;
  lastname?: string;
  login: string;
  timezone: string;
}

// for websocket

export enum DownloadFileType {
  EXCEL = 'excel',
  CSV = 'csv',
  PDF = 'pdf',
}

export interface DownloadReportRequestDto {
  downloadFileType: DownloadFileType;
  selectedIds: string[];
}

export interface DeleteResponseDto {
  status: boolean;
}

export interface DataTablePaginationSortChange {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: FilterSortDirection;
}

export interface FileViewDto {
  key: string;
  name: string;
  type: string;
  id: string;
  requestId: string;
  comment?: string;
}

export interface FileCreateAndUpdateDto {
  key: string;
  name: string;
  type: string;
  comment?: string;
}

export interface FileUploadDto {
  name: string;
  type: string;
  base64Data: string;
}

export interface VersionDto {
  webVersion: string;
}
