import logdna from '@logdna/browser';
import * as Sentry from '@sentry/react';

import { IS_E2E_MODE, IS_LOCAL_MODE, IS_PROD_MODE } from '../config/mode';
import { stripSensitive } from './sensitive.utils';
import { sentryAddBreadcrumb } from './sentry';

// if (IS_LOCAL_MODE && !import.meta.env.VITE_HOST) {
//   throw new Error('HOST needs to be present');
// }

logdna.init(import.meta.env.VITE_MEZMO_KEY, {
  // hostname: IS_LOCAL_MODE ? import.meta.env.VITE_HOST : window.location.hostname,
  hostname: IS_LOCAL_MODE ? 'local' : window.location.hostname,
  app: `ui-${import.meta.env.VITE_APP_ENVIRONMENT}`,
  console: false,
  enableStacktrace: false,
  // flushInterval: 10 * 1000,
  disabled: IS_E2E_MODE,
});

const DEBUG_MODE = false; // making this true will only show debug logs from log.debug...
// use these values to switch on/off logs based on requirement
const SHOW_TRACE = false;
const SHOW_DEBUG = false;
const SHOW_INFO = false;
const SHOW_RENDER = false;
const SHOW_INTERCEPTOR = false;
const SHOW_INTERACTION = false;
const SHOW_NAVIGATION = false;

export function createLogger(name: string): Logger {
  return new Logger(name);
}

class Logger {
  private name: string;
  constructor(name: string) {
    this.name = name.replace(/\./g, '-').replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase());
  }

  private consoleLog(
    level: 'render' | 'debug' | 'info' | 'error' | 'alert' | 'warning' | 'trace' | 'interceptor' | 'navigation' | 'interaction',
    message: string,
    props: any,
  ) {
    if (!IS_PROD_MODE) {
      // dont log in prod builds

      let allowConsoleLog = false;
      let logToUse = console.log;
      switch (level) {
        case 'error':
        case 'alert':
          allowConsoleLog = true;
          logToUse = console.error;
          break;
        case 'warning':
          allowConsoleLog = true;
          logToUse = console.warn;
          break;
        case 'trace':
          allowConsoleLog = !DEBUG_MODE && SHOW_TRACE;
          break;
        case 'debug':
          allowConsoleLog = DEBUG_MODE || SHOW_DEBUG;
          break;
        case 'info':
          allowConsoleLog = !DEBUG_MODE && SHOW_INFO;
          break;
        case 'interceptor':
          allowConsoleLog = !DEBUG_MODE && SHOW_INTERCEPTOR;
          break;
        case 'navigation':
          allowConsoleLog = !DEBUG_MODE && SHOW_NAVIGATION;
          break;
        case 'render':
          allowConsoleLog = !DEBUG_MODE && SHOW_RENDER;
          break;
        case 'interaction':
          allowConsoleLog = !DEBUG_MODE && SHOW_INTERACTION;
          break;
        default:
          break;
      }

      if (allowConsoleLog) {
        if (props) {
          // eslint-disable-next-line no-console
          logToUse(message, props);
        } else {
          // eslint-disable-next-line no-console
          logToUse(message);
        }
      }
    }
  }

  private getMessage(msg?: string) {
    return `${this.name}:${msg ? ` ${msg}` : ''}`;
  }

  public debug(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('debug', message, props);
  }

  public render(props?: Record<string, unknown>) {
    const message = this.getMessage();
    this.consoleLog('render', message, props);
    logRender(message, props);
  }

  public info(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('info', message, props);
    logInfo(message, props);
  }

  public warn(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('warning', message, props);
    logWarning(message, props);
  }

  public alert(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('alert', message, props);
    logAlert(message, props);
  }

  // TODO make error required
  public error(msg: string, error: unknown, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('error', message, error);
    logError(message, error, props);
  }

  public interceptor(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('interceptor', message, props);
    logInterceptor(message, props);
  }

  public interaction(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('interaction', message, props);
    logInteraction(message, props);
  }

  public navigation(msg: string, props?: Record<string, unknown>) {
    const message = this.getMessage(msg);
    this.consoleLog('navigation', message, props);
    logNavigation(message, props);
  }
}

function logInterceptor(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.info(message, { logType: 'interceptor', ...args });
  sentryAddBreadcrumb('info', 'debug', 'interceptor', message, args);
}

function logInteraction(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.info(message, { logType: 'interaction', ...args });
  sentryAddBreadcrumb('info', 'user', 'interaction', message, args);
}

function logNavigation(message: string, args?: Record<string, unknown>) {
  // if (!IS_E2E_MODE) logdna.info(message, { logType: 'navigation', ...args });
  sentryAddBreadcrumb('info', 'navigation', 'navigation', message, args);
}

function logRender(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.info(message, { logType: 'render', ...args });
  sentryAddBreadcrumb('info', 'debug', 'render', message, args);
}

function logInfo(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  sentryAddBreadcrumb('info', 'info', 'log', message, args);
}

function logWarning(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.warn(message, args);
  sentryAddBreadcrumb('warning', 'error', 'log', message, args);
}

function logAlert(message: string, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.error(message, args);
  sentryAddBreadcrumb('error', 'error', 'log', message);
  Sentry.withScope((scope) => {
    scope.setLevel('error');
    if (args) {
      scope.setExtra('error-data', args);
    }
    Sentry.captureMessage(message);
  });
}

function logError(message: string, error?: unknown, args?: Record<string, unknown>) {
  stripSensitive(args);
  // if (!IS_E2E_MODE) logdna.error(message, args);
  sentryAddBreadcrumb('fatal', 'error', 'log', message);
  Sentry.withScope((scope) => {
    scope.setLevel('fatal');
    if (args) {
      scope.setExtra('error-data', args);
    }
    if (error) {
      Sentry.captureException(error);
    }
  });
}
