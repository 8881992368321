import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';

import { FilterDateRangeValue } from '../../../hoc/WithFilter';
import DateRangeProvider from '../value-providers/DateRangeProvider';

type DateRangeFieldProps = {
  value?: FilterDateRangeValue;
  onValueChange: (val: FilterDateRangeValue) => void;
  setError: React.Dispatch<SetStateAction<string | null>>;
  setShowError: React.Dispatch<SetStateAction<boolean>>;
};

const DateRangeField = (props: DateRangeFieldProps) => {
  const [cValue, setCValue] = useState<FilterDateRangeValue>(
    props.value ?? {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    },
  );
  const { setError, setShowError } = props;

  useEffect(() => {
    if (cValue && cValue.from && cValue.to && moment(cValue.from).isValid() && moment(cValue.to).isValid()) {
      setShowError(false);
      setError(null);
      props.onValueChange(cValue);
    } else {
      setError('Value is required');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cValue]);

  return (
    <DateRangeProvider
      value={cValue}
      onValueChange={(val) => {
        setCValue(val);
      }}
    />
  );
};

export default DateRangeField;
