import { FormHelperText } from '@mui/material';
import React from 'react';

interface Props {
  touched?: boolean;
  error?: string | string[];
}
const FormDataErrorField = (props: Props) => {
  if (!props.touched || !props.error) {
    return <></>;
  }
  if (props.error && typeof props.error === 'string') {
    return (
      <FormHelperText error data-automation="field-error">
        {props.error}
      </FormHelperText>
    );
  }
  if (props.error && Array.isArray(props.error)) {
    return (
      <>
        {props.touched &&
          props.error &&
          props.error.map((e, eind) => (
            <FormHelperText error key={eind} data-automation="field-error">
              {e}
            </FormHelperText>
          ))}
      </>
    );
  }
  return <></>;
};
export default FormDataErrorField;
