import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnSizingState } from '@tanstack/react-table';

import { ListTypeEnum } from '../../components/data-table/data-table';

type ColumnResizeProps = {
  type: ListTypeEnum;
  resizeValue: { [type: string]: number };
  templateId?: string;
};

interface DataTableSettingsState {
  columnSizing: {
    asset: ColumnSizingState;
    template: ColumnSizingState;
    authority: ColumnSizingState;
    exception_rule: ColumnSizingState;
    schedule: ColumnSizingState;
    schedule_status: ColumnSizingState;
    user: ColumnSizingState;
    report: Record<string, ColumnSizingState>;
  };
}

const initialState: DataTableSettingsState = {
  columnSizing: {
    asset: {},
    template: {},
    authority: {},
    exception_rule: {},
    schedule: {},
    schedule_status: {},
    user: {},
    report: {},
  },
};

const dataTableSettings = createSlice({
  name: 'dataTableSettings',
  initialState,
  reducers: {
    updateColumnSize: (state, action: PayloadAction<ColumnResizeProps>) => {
      if (action.payload.type === ListTypeEnum.REPORT && action.payload.templateId) {
        state.columnSizing[action.payload.type][action.payload.templateId] = action.payload.resizeValue;
      } else {
        state.columnSizing[action.payload.type as Exclude<ListTypeEnum, ListTypeEnum.REPORT>] = action.payload.resizeValue;
      }
    },
  },
});

export const { updateColumnSize } = dataTableSettings.actions;

export default dataTableSettings.reducer;
