/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { AssetStatusEnum, SimpleAssetViewDto } from '../common/dto/asset.dto';
import { PaginatedResponse, SimpleViewDto } from '../common/dto/common.dto';
import { WidgetConfigDto } from '../common/dto/dashboard.dto';
import { MatchType } from '../common/dto/exception-rule.dto';
import { FeatureAccessLevel, FeatureViewDto, SimpleFeatureViewDto } from '../common/dto/feature.dto';
import { FilterFieldDataType, FilterFieldDto, FilterSortDirection, OperatorV2 } from '../common/dto/filter.dto';
import { UserInLocationViewDto } from '../common/dto/location.dto';
import { SimpleTemplateViewDto, TemplateStatus } from '../common/dto/template.dto';
import { AlertMessageType } from '../common/types';
import { UserHelper } from '../helper/user.helper';
import { useAssetDropdownListMutation, useSearchAssetMutation } from '../services/asset.service';
import { useSearchAuthorityMutation } from '../services/authority.service';
import { useLazyGetWidgetsQuery } from '../services/dashboard.service';
import { useSearchFeaturesMutation } from '../services/feature.service';
import { useLazyGetUsersInLocationQuery, useSearchLocationMutation } from '../services/location.service';
import { useSearchDropDownTemplatesMutation, useSearchTemplatesMutation } from '../services/template.service';
import { useSearchUserMutation } from '../services/user.service';

type TemplateProps = {
  showDeleted?: boolean;
  isActive?: boolean;
};

type AssetProps = {
  isActive?: boolean;
};

const useGetAll = <T extends object>() => {
  const [data, setData] = useState<T[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AlertMessageType | null>();

  return {
    isLoading,
    setIsLoading,
    data,
    setData,
    error,
    setError,
  };
};

export const useGetAllFeature = (accessLevel: FeatureAccessLevel) => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleFeatureViewDto>();
  const [searchFeature] = useSearchFeaturesMutation();

  useEffect(() => {
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      searchFeature({
        matchType: MatchType.ALL,
        pagination: { disablePagination: true, page: 1, limit: 100 }, // TODO page and limit should be null
        sort: { field: 'name', direction: FilterSortDirection.asc },
      })
        .unwrap()
        .then((response: PaginatedResponse<FeatureViewDto>) => {
          setData(
            response.results
              .filter((i) => i.accessLevel === accessLevel)
              .map((i) => {
                return { id: i.id, name: i.name, accessLevel: i.accessLevel };
              }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          let msg = 'Unable to retrieve data from api';
          if (e.data && typeof e.data.message === 'string') {
            msg = e.data.message;
          }
          setError({ severity: 'error', msg });
          setIsLoading(false);
        });
    };

    getData();
  }, []);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllAuthority = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleViewDto>();
  const [searchAuthority] = useSearchAuthorityMutation();

  useEffect(() => {
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      searchAuthority({
        matchType: MatchType.ALL,
        pagination: { disablePagination: true, page: 1, limit: 100 }, // TODO page and limit should be null
        sort: { field: 'name', direction: FilterSortDirection.asc },
      })
        .unwrap()
        .then((response) => {
          setData(
            response.results.map((i) => {
              return { id: i.id, name: i.name };
            }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          let msg = 'Unable to retrieve data from api';
          if (e.data && typeof e.data.message === 'string') {
            msg = e.data.message;
          }
          setError({ severity: 'error', msg });
          setIsLoading(false);
        });
    };

    getData();
  }, []);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllUser = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleViewDto>();
  const [searchUser] = useSearchUserMutation();

  useEffect(() => {
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      searchUser({
        matchType: MatchType.ALL,
        pagination: { disablePagination: true, page: 1, limit: 100 }, // TODO page and limit should be null
        sort: { field: 'name', direction: FilterSortDirection.asc },
      })
        .unwrap()
        .then((response) => {
          setData(
            response.results.map((i) => {
              return {
                id: i.id,
                name: UserHelper.getName(i.login, i.firstname, i.lastname),
              };
            }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          let msg = 'Unable to retrieve data from api';
          if (e.data && typeof e.data.message === 'string') {
            msg = e.data.message;
          }
          setError({ severity: 'error', msg });
          setIsLoading(false);
        });
    };

    getData();
  }, []);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllLocation = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleViewDto>();
  const [searchLocation] = useSearchLocationMutation();

  useEffect(() => {
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      searchLocation({
        matchType: MatchType.ALL,
        pagination: { disablePagination: true, page: 1, limit: 100 },
        sort: { field: 'name', direction: FilterSortDirection.asc },
      })
        .unwrap()
        .then((response) => {
          setData(
            response.results.map((i) => {
              return { id: i.id, name: i.name };
            }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status === 403 && e.data.message) {
            setError({ severity: 'error', msg: e.data.message });
          } else {
            setError({ severity: 'error', msg: `Unable to retrieve data from api` });
          }
        });
    };
    if (!data && !isLoading && !error) getData();
  }, [data, isLoading, error]);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllLocationForTemplateAndAsset = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleViewDto>();
  const [searchLocation] = useSearchLocationMutation();

  const getData = (templateId: string, assetId: string) => {
    const fields: FilterFieldDto[] = [
      {
        field: 'templatesInLocation._id',
        operator: OperatorV2.optionsContains,
        value: templateId,
        dataType: FilterFieldDataType.options,
      },
      {
        field: 'assetsInLocation._id',
        operator: OperatorV2.optionsContains,
        value: assetId,
        dataType: FilterFieldDataType.options,
      },
    ];

    setError(null);
    setData(undefined);
    setIsLoading(true);
    searchLocation({
      fields,
      matchType: MatchType.ALL,
      pagination: { disablePagination: true, page: 1, limit: 100 },
      sort: { field: 'name', direction: FilterSortDirection.asc },
    })
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            return { id: i.id, name: i.name };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.status === 403 && e.data.message) {
          setError({ severity: 'error', msg: e.data.message });
        } else {
          setError({ severity: 'error', msg: `Unable to retrieve data from api` });
        }
      });
  };

  const setEmpty = () => {
    setData([]);
    setIsLoading(false);
  };

  return {
    isLoading,
    data,
    error,
    setEmpty,
    getData,
  };
};

export const useGetAllTemplate = (props: TemplateProps) => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleTemplateViewDto>();
  const [searchTemplate] = useSearchTemplatesMutation();

  useEffect(() => {
    const fields: FilterFieldDto[] = [];
    if (props.isActive) {
      fields.push({
        field: 'status',
        dataType: FilterFieldDataType.string,
        operator: OperatorV2.equals,
        value: TemplateStatus.PUBLISH,
      });
    }
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      searchTemplate({
        fields,
        matchType: MatchType.ALL,
        pagination: { disablePagination: true, page: 1, limit: 100 },
        sort: { field: 'name', direction: FilterSortDirection.asc },
        showDeleted: props.showDeleted,
      })
        .unwrap()
        .then((response) => {
          setData(
            response.results.map((i) => {
              const locationIds = i.locations ? i.locations.map((l) => l.id) : [];
              return {
                id: i.id,
                name: i.name,
                locationIds,
                currentVersion: i.currentVersion,
                versions: i.versions,
                status: i.status,
                isDeleted: i.isDeleted ?? false,
              };
            }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.status === 403 && e.data.message) {
            setError({ severity: 'error', msg: e.data.message });
          } else {
            setError({ severity: 'error', msg: `Unable to retrieve data from api` });
          }
        });
    };

    getData();
  }, []);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllTemplateInLocationLazy = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleTemplateViewDto>();
  const [searchDropDownTemplates] = useSearchDropDownTemplatesMutation();

  const getData = (locIds: string[]) => {
    setError(null);
    setData(undefined);
    setIsLoading(true);
    searchDropDownTemplates({
      locationIds: locIds,
    })
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            const locationIds = i.locations ? i.locations.map((l) => l.id) : [];
            return {
              id: i.id,
              name: i.name,
              locationIds,
              currentVersion: i.currentVersion,
              versions: i.versions,
              status: i.status,
              isDeleted: i.isDeleted ?? false,
            };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        let msg = 'Unable to retrieve data from api';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setError({ severity: 'error', msg });
        setIsLoading(false);
      });
  };

  const setEmpty = () => {
    setData([]);
    setIsLoading(false);
  };

  return {
    isLoading,
    data,
    error,
    setEmpty,
    getData,
  };
};

// TODO remove replaced with useGetAllAssetForTemplateLazy
export const useGetAllAsset = () => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleViewDto>();
  const [assetDropdownList] = useAssetDropdownListMutation();

  useEffect(() => {
    const getData = () => {
      setError(null);
      setData(undefined);
      setIsLoading(true);
      assetDropdownList()
        .unwrap()
        .then((response) => {
          setData(
            response.results.map((i) => {
              return { id: i.id, name: i.uniqueCode };
            }),
          );
          setIsLoading(false);
        })
        .catch((e) => {
          let msg = 'Unable to retrieve data from api';
          if (e.data && typeof e.data.message === 'string') {
            msg = e.data.message;
          }
          setError({ severity: 'error', msg });
          setIsLoading(false);
        });
    };

    getData();
  }, []);

  return {
    isLoading,
    data,
    error,
  };
};

export const useGetAllUserInLocation = (locationId: string) => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<UserInLocationViewDto>();
  const [getUsersInLocation] = useLazyGetUsersInLocationQuery();

  const getData = () => {
    setError(null);
    setData(undefined);
    setIsLoading(true);
    getUsersInLocation(locationId)
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            return { user: i.user, authorities: i.authorities };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        let msg = 'Unable to retrieve data from api';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setError({ severity: 'error', msg });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!data) getData();
  }, [data]);

  return {
    isLoading,
    data,
    error,
    getData,
  };
};

export const useGetAllWidget = (dashboardId: string) => {
  const [id, setId] = useState<string | null>();
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<WidgetConfigDto>();
  const [getAllWidget] = useLazyGetWidgetsQuery();

  const getData = () => {
    setError(null);
    setData(undefined);
    setIsLoading(true);
    getAllWidget(dashboardId)
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            return { ...i };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        let msg = 'Unable to retrieve data from api';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setError({ severity: 'error', msg });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!data) getData();
  }, [data]);

  useEffect(() => {
    if (id == null || id !== dashboardId) {
      setData(undefined);
      setId(id);
    }
  }, [dashboardId]);

  return {
    isLoading,
    data,
    error,
    getData,
  };
};

export const useGetAllAssetForTemplateLazy = (props: AssetProps) => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleAssetViewDto>();
  const [searchAsset] = useSearchAssetMutation();

  const getData = (templateId: string) => {
    const fields: FilterFieldDto[] = [
      {
        field: 'templates._id',
        operator: OperatorV2.optionsContains,
        value: templateId,
        dataType: FilterFieldDataType.options,
      },
    ];
    if (props.isActive) {
      fields.push({
        field: 'status',
        operator: OperatorV2.equals,
        value: AssetStatusEnum.active,
        dataType: FilterFieldDataType.string,
      });
    }
    setError(null);
    setData(undefined);
    setIsLoading(true);
    searchAsset({
      matchType: MatchType.ALL,
      fields,
      pagination: { disablePagination: true, page: 1, limit: 100 },
      sort: { field: 'name', direction: FilterSortDirection.asc },
    })
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            return { id: i.id, name: i.uniqueCode, locations: i.locations, status: i.status };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        let msg = 'Unable to retrieve data from api';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setError({ severity: 'error', msg });
        setIsLoading(false);
      });
  };

  const setEmpty = () => {
    setData([]);
    setIsLoading(false);
  };

  return {
    isLoading,
    data,
    error,
    setEmpty,
    getData,
  };
};

export const useGetAllAssetForTemplateAndLocationLazy = (props: AssetProps) => {
  const { isLoading, setIsLoading, data, setData, error, setError } = useGetAll<SimpleAssetViewDto>();
  const [searchAsset] = useSearchAssetMutation();

  const getData = (templateId: string | null, locationId: string | null) => {
    setError(null);
    setData(undefined);
    setIsLoading(true);
    const fields: FilterFieldDto[] = [];
    if (templateId) {
      fields.push({
        // type: FilterFieldType.STRING,
        field: 'templates._id',
        operator: OperatorV2.optionsContains,
        value: templateId,
        dataType: FilterFieldDataType.options,
      });
    }
    if (locationId) {
      fields.push({
        // type: FilterFieldType.STRING,
        field: 'locations._id',
        operator: OperatorV2.optionsContainAndChildren,
        value: locationId,
        dataType: FilterFieldDataType.options,
      });
    }
    if (props.isActive) {
      fields.push({
        field: 'status',
        operator: OperatorV2.equals,
        value: AssetStatusEnum.active,
        dataType: FilterFieldDataType.string,
      });
    }
    searchAsset({
      matchType: MatchType.ALL,
      fields,
      pagination: { disablePagination: true, page: 1, limit: 100 },
      sort: { field: 'name', direction: FilterSortDirection.asc },
    })
      .unwrap()
      .then((response) => {
        setData(
          response.results.map((i) => {
            return { id: i.id, name: i.uniqueCode, locations: i.locations, status: i.status };
          }),
        );
        setIsLoading(false);
      })
      .catch((e) => {
        let msg = 'Unable to retrieve data from api';
        if (e.data && typeof e.data.message === 'string') {
          msg = e.data.message;
        }
        setError({ severity: 'error', msg });
        setIsLoading(false);
      });
  };

  const setEmpty = () => {
    setData([]);
    setIsLoading(false);
  };

  return {
    isLoading,
    data,
    error,
    setEmpty,
    getData,
  };
};
