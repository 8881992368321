import 'overlayscrollbars/overlayscrollbars.css';

import {
  Alert,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import {
  ColumnDef,
  ColumnSizingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { motion } from 'framer-motion';
// import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import { BaseViewDto } from '../../common/dto/base.dto';
import { PaginatedResponse } from '../../common/dto/common.dto';
import { FilterSortDirection } from '../../common/dto/filter.dto';
import { SearchFilterProps } from '../../hook/useSearch';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateColumnSize } from '../../redux/slices/dataTableSettings.slice';
import { RootState } from '../../redux/store';
import { LoaderHorizontal } from '../common/Loader';
import { FlexRow } from '../styled/Flex';

export enum ListTypeEnum {
  ASSET = 'asset',
  TEMPLATE = 'template',
  AUTHORITY = 'authority',
  EXCEPTION_RULE = 'exception_rule',
  REPORT = 'report',
  SCHEDULE = 'schedule',
  SCHEDULE_STATUS = 'schedule_status',
  USER = 'user',
}

interface DataTableProps<T> {
  loading: boolean;
  data: PaginatedResponse<T> | undefined;
  setSearchFilter: (value: React.SetStateAction<SearchFilterProps>) => void;
  columns: ColumnDef<T>[];
  hideActions: boolean;
  rowSelection?: RowSelectionState;
  setRowSelection?: React.Dispatch<SetStateAction<RowSelectionState>>;
  scoring?: boolean;
  listType: ListTypeEnum;
  templateId?: string;
}

export enum DataTableActionType {
  VIEW = 'view',
  EDIT = 'edit',
  EDIT_PROFILE = 'edit-profile',
  RESET_PASSWORD = 'reset-password',
  RESET_MFA = 'reset-mfa',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  RESEND = 'resend',
  DOWNLOAD = 'download',
  EXPAND = 'expand',
  CONFIGURE = 'configure',
  SUBMIT_DATA = 'submit-data',
  REINVITE_USER = 'reinvite-user',
}

const DataTable = <T extends BaseViewDto>(props: DataTableProps<T>) => {
  const { scoring, listType } = props;
  const dispatch = useAppDispatch();
  const listColumnSizing = useAppSelector((state: RootState) => state.dataTableSettings.columnSizing);
  let columnSize = {};
  if (props.listType === ListTypeEnum.REPORT && props.templateId) {
    columnSize = listColumnSizing.report[props.templateId] ?? {};
  } else {
    columnSize = listColumnSizing[listType] ?? {};
  }
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState({
    actions: props.hideActions ? false : true,
  });
  const [tableTopHeight, setTableTopHeight] = useState(150);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(columnSize);

  const tableContainerRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    if (listType === ListTypeEnum.REPORT && props.templateId) {
      setColumnSizing(columnSize);
    }
    return () => {
      if (listType) {
        dispatch(updateColumnSize({ type: listType, resizeValue: table.getState().columnSizing ?? {}, templateId: props.templateId }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listType, props.templateId]);

  useEffect(() => {
    setColumnVisibility((prev) => {
      return {
        ...prev,
        score: scoring ? scoring : false,
      };
    });
  }, [scoring]);

  const table = useReactTable({
    columns: props.columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    data: props.data ? props.data.results : [],
    onColumnSizingChange: setColumnSizing,
    state: {
      sorting,
      columnVisibility,
      rowSelection: props.rowSelection,
      columnSizing,
    },
    defaultColumn: {
      size: 140,
    },
    onRowSelectionChange: props.setRowSelection,
    onSortingChange: setSorting,
    // onSortingChange: (prev) => {
    //   console.log('srtng change', prev);
    // },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (originalRow, index, parent) => {
      return originalRow.id;
    },
    manualSorting: true,
  });
  // #endregion

  // when sorting changes, make api call
  useEffect(() => {
    if (sorting.length > 0) {
      const sortColumn = sorting[0];
      props.setSearchFilter((prev) => {
        const newVals = {
          ...prev,
        };
        newVals.pagination.sortOrder = sortColumn.desc ? FilterSortDirection.desc : FilterSortDirection.asc;
        newVals.pagination.sortBy = sortColumn.id;
        newVals.pagination.page = 1;
        return newVals;
      });
    } else {
      props.setSearchFilter((prev) => {
        const newVals = {
          ...prev,
        };
        let createdAtKey = 'createdAt';
        props.columns.forEach((c) => {
          if (c.id?.includes('createdAt')) {
            createdAtKey = c.id;
          }
        });
        newVals.pagination.sortOrder = newVals.pagination.sortOrder ?? FilterSortDirection.desc;
        newVals.pagination.sortBy = newVals.pagination.sortBy ?? createdAtKey;
        newVals.pagination.page = newVals.pagination.page ?? 1;
        return newVals;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (tableContainerRef.current) {
      let topHeight = tableContainerRef.current.getBoundingClientRect().top;
      topHeight = Math.floor(topHeight);
      if (topHeight !== tableTopHeight) setTableTopHeight(topHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  if (props.loading && !props.data) return <LoaderHorizontal />;

  return (
    <>
      <motion.div initial={{ opacity: 0.65, scale: 1, y: 10 }} animate={{ opacity: 1, scale: 1, y: 0 }} transition={{ duration: 0.2, ease: 'easeInOut' }}>
        <Box data-div="table-box">
          {/* using px on paper for height works!! */}
          <Paper sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
            {/* <OverlayScrollbarsComponent
              defer
              style={{ borderRadius: '10px' }}
              options={{
                overflow: {
                  x: 'scroll',
                  y: 'scroll',
                },
              }}
            > */}
            {/* <TableContainer
            component={Paper}
            data-div="table-contaier"
            sx={{
              height: '100%',
              '&.MuiPaper-root': {
                // background: '#F5F7F9',
                padding: 1,
                overflowX: 'visible',
                // borderRadius: '15px',
              },
            }}
          > */}
            <TableContainer
              sx={{
                maxHeight: `calc(100vh - ${tableTopHeight ?? 10}px - 80px)`,
              }}
            >
              <Table
                stickyHeader
                sx={
                  {
                    // width: '100%',
                    // width: table.getCenterTotalSize(),
                  }
                }
                ref={tableContainerRef}
                aria-labelledby="tableTitle"
                data-automation="data-table"
              >
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
                    <TableRow key={`headerGroup-${headerGroupIndex}`} data-automation="table-header">
                      {headerGroup.headers.map((header, headerIndex) => (
                        <TableCell
                          key={`${header.id}+${headerIndex}`}
                          sx={[
                            {
                              minWidth: header.getSize(),
                              whiteSpace: 'nowrap',
                            },
                            header.column.getIsResizing()
                              ? {
                                  'div:after': {
                                    width: '1px',
                                    content: "''",
                                    display: 'inline-block',
                                    background: '#000',
                                    height: '100%',
                                  },
                                }
                              : { ':hover > div:after': { width: '1px', content: "''", display: 'inline-block', background: '#d1d1d1', height: '100%' } },
                          ]}
                          data-automation={header.column.id}
                        >
                          {header.column.id !== 'select' ? (
                            <TableSortLabel
                              disabled={!header.column.getCanSort()}
                              active={header.column.getIsSorted() ? true : false}
                              direction={header.column.getIsSorted() !== false ? (header.column.getIsSorted() as 'asc' | 'desc') : 'desc'}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </TableSortLabel>
                          ) : (
                            flexRender(header.column.columnDef.header, header.getContext())
                          )}
                          <Box
                            sx={[
                              {
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                height: '100%',
                                width: '10px',
                                cursor: 'col-resize',
                                userSelect: 'none',
                                touchAction: 'none',
                                align: 'center',
                              },
                            ]}
                            {...{
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                {props.data?.results.length === 0 || props.loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={props.columns.length} sx={{ background: 'white', p: 0 }} className="no-padding">
                        {props.loading ? (
                          <Box p={2}>
                            <LoaderHorizontal />
                          </Box>
                        ) : (
                          <Alert severity="warning" sx={{ alignItems: 'center', borderRadius: 0 }} data-automation="no-results">
                            No results
                          </Alert>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {table.getRowModel().rows.map((row, i) => {
                      return (
                        <TableRow key={`row-${i}`} sx={[{ backgroundColor: row.original.warnings && row.original.warnings.length ? '#fff3cd' : '#FFF' }]}>
                          {row.getVisibleCells().map((cell, cellIndex) => {
                            return (
                              <TableCell key={`cell-${cellIndex}`} data-automation={`cell-${cell.column.id}`} data-automation-row-no={i + 1}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {/* </TableContainer> */}
            {/* </OverlayScrollbarsComponent> */}
          </Paper>
        </Box>
        {props.data ? (
          <TablePagination
            currentPage={props.data.page}
            totalRecords={props.data.totalRecords}
            limit={props.data.limit}
            setSearchFilter={props.setSearchFilter}
          />
        ) : null}
      </motion.div>
    </>
  );
};

export default DataTable;

export const debugCols = <T extends BaseViewDto>(): ColumnDef<T>[] => {
  if (!['prod', 'uat'].includes(import.meta.env.VITE_APP_ENVIRONMENT)) {
    return [
      {
        header: 'id',
        accessorKey: 'id',
      } as ColumnDef<T>,
    ];
  }
  return [];
};

type TablePaginationProps = {
  currentPage: number;
  totalRecords: number;
  limit: number;
  setSearchFilter: (value: React.SetStateAction<SearchFilterProps>) => void;
};
export const TablePagination = (props: TablePaginationProps) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
    noSsr: false,
  });

  const handleChangePage = (newPage: number) => {
    props.setSearchFilter((prev) => {
      const newVals = {
        ...prev,
      };
      newVals.pagination.page = newPage;
      return newVals;
    });
  };

  const handlePerPage = (event: SelectChangeEvent<number>, child: ReactNode) => {
    props.setSearchFilter((prev) => {
      const newVals = {
        ...prev,
      };
      newVals.pagination.page = 1;
      newVals.pagination.limit = event.target.value as number;
      return newVals;
    });
  };

  const totalPages = Math.ceil(props.totalRecords / props.limit);

  return (
    <Box
      display="flex"
      flexDirection={smDown ? 'column-reverse' : 'row'}
      gap={2}
      alignItems="baseline"
      justifyContent="flex-end"
      data-automation="table-pagination"
    >
      <FlexRow columnGap={0.5}>
        <Typography variant="caption">Total: </Typography>
        <Typography variant="caption">{props.totalRecords}</Typography>
      </FlexRow>
      <Select
        id="page-size"
        name="page-size"
        value={props.limit}
        size="small"
        variant="outlined"
        onChange={handlePerPage}
        sx={{
          '& .MuiSelect-select': {
            padding: '5px 10px',
            fontSize: theme.typography.caption.fontSize,
          },
        }}
      >
        {perPageOptions.map((p, pIndex) => (
          <MenuItem key={pIndex} value={p}>
            <Typography variant="body1">{p}</Typography>
          </MenuItem>
        ))}
      </Select>
      <Pagination
        data-automation="table-page-no"
        count={totalPages}
        page={props.currentPage}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        sx={{ mt: 2 }}
        onChange={(e, page) => handleChangePage(page)}
      />
    </Box>
  );
};

const perPageOptions: number[] = [10, 20, 30, 40, 50, 100];
if (import.meta.env.VITE_APP_ENVIRONMENT !== 'prod') {
  perPageOptions.unshift(2);
}
