import { SimpleViewDto } from '../../../common/dto/common.dto';
import { ConstrainV2, FilterFieldDataType } from '../../../common/dto/filter.dto';
import { FilterValueTypes } from '../../../hoc/WithFilter';
import { useGetAllUser } from '../../../hook/useDataForSelect';
import FilterOptionsMultiple, { optionsContain } from '../simple-element/FilterOptionsMultiple';

type Props = {
  fieldDataType: FilterFieldDataType;
  field: string;
  label: string;
  value: SimpleViewDto[];
  constrain?: ConstrainV2;
  fieldSuffixMeta?: Record<string, string>;
  onFilterValueChanged: (
    fieldDataType: FilterFieldDataType,
    field: string,
    value?: FilterValueTypes,
    constrain?: ConstrainV2,
    fieldSuffixMeta?: Record<string, string>,
  ) => void;
};
const FilterUser = (props: Props) => {
  const { data: dataUser, isLoading: isLoadingUser, error: errorUser } = useGetAllUser();
  return (
    <FilterOptionsMultiple
      fieldDataType={props.fieldDataType}
      field={props.field}
      label={props.label}
      isLoading={isLoadingUser}
      options={dataUser ?? []}
      value={props.value}
      constrain={props.constrain}
      fieldSuffixMeta={props.fieldSuffixMeta}
      onFilterValueChanged={props.onFilterValueChanged}
      fixedConstrain={optionsContain}
      hideConstrains
    />
  );
};

export default FilterUser;
