import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';
import { getUserActionableStepsPending, selectUserInfo } from '../redux/slices/userInfo.slice';
import { createLogger } from '../utils/logger';
import { BeforeAuthLayoutView } from './BeforeAuthLayoutView';

const logger = createLogger('service');

const AuthLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userInfo = useAppSelector(selectUserInfo);
  const stepsPending = useAppSelector(getUserActionableStepsPending);

  useEffect(() => {
    if (stepsPending.length) {
      const step = stepsPending[0];
      switch (step) {
        case 'mfa-setup':
          navigate('/auth/mfa-setup');
          break;
        case 'mfa-verify':
          navigate('/auth/mfa-verify');
          break;
        case 'profile-setup':
          navigate('/auth/complete-profile');
          break;
        case 'accept-invite-to-organisation':
          navigate('/auth/accept-invite');
          break;
        default:
          logger.info('invalid step', { step });
          break;
      }
    }
  }, [stepsPending, navigate]);

  if (
    !['/auth/complete-profile', '/auth/accept-invite', '/auth/mfa-setup', '/auth/mfa-verify', '/auth/logout'].includes(pathname) &&
    !pathname.includes('/auth/complete-account-setup/') &&
    userInfo.id &&
    stepsPending.length === 0
  )
    return <Navigate to="/app/dashboard" />;

  return <BeforeAuthLayoutView />;
};

export default AuthLayout;
